import { app as services } from "../app.module";
import { IUrlResolver } from "./urlResolver";
import { IApiService } from "./apiServiceProvider";
import { IDeferred, ILogService, IPromise, IQService } from "angular";
import { AppLauncher } from "@capacitor/app-launcher";

var serviceId = "assetService";
services.factory(serviceId, assetService);

export interface IAssetService {
    mobileOpenFile(url: string, requireDownloadToken: boolean): IPromise<void>;
    openFile(url: string): IPromise<void>;
}

function assetService($log: ILogService, $q: IQService, $window, apiService: IApiService, urlResolver: IUrlResolver): IAssetService {
    "ngInject";
    return {
        openFile: openFile,
        mobileOpenFile: mobileOpenFile
    };

    function mobileOpenFile(url: string, requireDownloadToken: boolean) {
        const deferred = $q.defer<void>();

        if (requireDownloadToken) {
            apiService.getDownloadToken().then(
                function successCallback(response) {
                    const data = response.data;
                    const newUrl = urlResolver.resolve(url + (url.indexOf("?") >= 0 ? "&" : "?") + "DownloadToken=" + encodeURIComponent(data.token));

                    $log.debug(`assetService - Mobile Downloading ${newUrl}`);

                    appLauncherOpenUrl(newUrl, deferred);
                },
                function errorCallback(response) {
                    $log.error("assetService - Error getting download file token", response.data);
                    deferred.reject();
                }
            );
        } else {
            appLauncherOpenUrl(url, deferred);
        }

        return deferred.promise;
    }

    function appLauncherOpenUrl(newUrl: string, deferred: IDeferred<void>) {
        $q.when(AppLauncher.openUrl({ url: newUrl }))
            .then(() => {
                deferred.resolve();
            })
            .catch(response => {
                $log.error("assetService - Error opening url", response);
                deferred.reject();
            });
    }

    function openFile(url: string) {
        const newUrl = urlResolver.resolve(url);
        $log.debug(`assetService - Downloading ${newUrl}`);
        $window.open(urlResolver.resolve(url), "_self");

        return $q.resolve();
    }
}

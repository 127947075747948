import angular from "angular";
import { app as services } from "../app.module";

const serviceId = "focusService";
services.factory(serviceId, ["$document", "$timeout", focusService]);

function focusService($document, $timeout) {
    "ngInject";
    return {
        setFocusTo: setFocusTo
    };

    function setFocusTo(elementId) {
        $timeout(function () {
            const successDiv = angular.element(document.getElementById(elementId));
            $document.scrollToElement(successDiv, 0, 0);
        }, 100);
    }
}

import { app } from "../../../app.module";
import { QLearnService } from "../../../services/qLearnService";
import { QLearnLinkModel } from "@/app/models/api";

require("../parentCentricSummary.less");
require("./qlearnSummaryWidget.less");
import templateUrl from "./qlearnSummaryWidget.html";
app.directive("qLearnSummaryWidget", () => ({
    templateUrl,
    controller: QLearnSummaryWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

class QLearnSummaryWidget {
    public link: QLearnLinkModel;

    constructor(qLearnService: QLearnService) {
        "ngInject";
        qLearnService.getQLearnLink().then(response => (this.link = response));
    }
}

import { app } from "../../app.module";
import { IDataContext } from "../../services/dataContext";
import "../../consent/consentRequestSummary/consentRequestSummary";
import "../../consent/consentRequestList/consentRequestList";
import { ConsentResponseState } from "../../models/enums";
import { ConsentRequest } from "../../consent/consentRequestSummary/consentRequestSummary";
import moment from "moment";
import { ConsentService } from "@/app/services/consentService";
import templateUrl from "./combinedConsentRequests.html";
import { IDeviceService } from "@/app/services/deviceServiceProvider";
import { FEATURES } from "../../configuration";

const controllerId = "combinedConsentRequests";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: CombinedConsentRequestsComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

interface studentDetails {
    name: string;
    id: string;
    ticked: boolean;
}

export class CombinedConsentRequestsComponent {
    allRequests: ConsentRequest[];
    pendingRequests: ConsentRequest[];
    pastRequests: ConsentRequest[];
    consentFeatureTurnedOffForAllStudents: boolean;
    students: studentDetails[];
    filter: {
        selectedStudents: studentDetails[];
    };
    consentLoadedSuccessfully: boolean;
    consentLoading: boolean;
    isMobileApp: boolean;

    constructor(private dataContext: IDataContext, private $location: ng.ILocationService, private consentService: ConsentService, deviceService: IDeviceService) {
        "ngInject";
        this.isMobileApp = deviceService.isMobileApp;
        this.dataContext.getData().then(user => {
            this.consentFeatureTurnedOffForAllStudents = !user.students.some(student => student.isFeatureAvailable(FEATURES.CONSENT_REQUESTS));

            this.students = user.students
                .filter(s => s.isFeatureAvailable(FEATURES.CONSENT_REQUESTS))
                .map(s => ({
                    name: s.fullName,
                    id: s.oneSchoolId,
                    ticked: true
                }));
            this.filter = { selectedStudents: [...this.students] };

            this.consentLoading = true;
            this.consentService
                .getConsentRequestSummary(user)
                .then(summary => {
                    this.consentLoadedSuccessfully = summary.loadedSuccessfully;
                    this.allRequests = summary.consentRequests
                        .map(
                            r =>
                                ({
                                    id: r.id,
                                    title: r.title,
                                    state: r.state,
                                    withdrawnDate: r.withdrawnDate,
                                    dueDate: r.dueDate,
                                    responseDate: r.responseDate,
                                    schoolName: user.schools.filter(s => s.id === r.schoolCode)[0].name,
                                    studentFullName: user.students.find(s => s.oneSchoolId === r.eqId).fullName,
                                    studentId: r.eqId,
                                    schoolCode: user.schools.filter(s => s.id === r.schoolCode)[0].id
                                } as ConsentRequest)
                        )
                        .sort(sortRequests);
                    this.filterRequests();
                })
                .catch(e => {
                    this.consentLoadedSuccessfully = false;
                })
                .finally(() => {
                    this.consentLoading = false;
                });
        });
    }

    filterRequests() {
        this.pendingRequests = this.allRequests.filter(r => r.state === ConsentResponseState.Pending).filter(r => this.filter.selectedStudents.some(s => s.id === r.studentId));
        this.pastRequests = this.allRequests.filter(r => r.state !== ConsentResponseState.Pending).filter(r => this.filter.selectedStudents.some(s => s.id === r.studentId));
    }

    viewConsentRequest(request: ConsentRequest) {
        const path = `/consent-request/${request.studentId}/${request.id}/${request.schoolCode}`;
        this.$location.path(path);
    }
}

const sortRequests = (a: ConsentRequest, b: ConsentRequest): number => {
    const aDate = getDate(a);
    const bDate = getDate(b);
    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
};

const getDate = (request: ConsentRequest) => {
    if (request.withdrawnDate !== null && request.withdrawnDate !== undefined && request.withdrawnDate !== "") return moment(request.withdrawnDate);

    if (request.responseDate !== null && request.responseDate !== undefined && request.responseDate !== "") return moment(request.responseDate);

    return moment(request.dueDate);
};

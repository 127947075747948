import { app } from "../app.module";
import { AuthorisationService } from "../services/authorisationService";
import { ILoginService } from "../services/loginService";
import { IPreferencesService } from "../services/preferencesService";
import { Dialog } from "@capacitor/dialog";

const controllerId = "biometrics";
import templateUrl from "./biometrics.html";
import angular from "angular";

app.component(`${controllerId}Component`, {
    templateUrl,
    controller: biometricsController,
    controllerAs: "vm"
});

function biometricsController($q: angular.IQService, $log: angular.ILogService, preferencesService: IPreferencesService, loginService: ILoginService, authorisationService: AuthorisationService) {
    "ngInject";
    var vm = this;

    vm.isBiometricsRegistered = false;
    vm.isBiometricAvailable = false;
    vm.availableBiometryTypeDescription = "";
    vm.submit = submit;

    initialise();

    function initialise() {
        vm.busy = true;

        loginService
            .checkIfBiometricAvailable()
            .then(result => {
                if (result.isAvailable) {
                    $log.debug("biometrics - Biometrics is available.");
                    vm.isBiometricAvailable = true;
                    vm.availableBiometryTypeDescription = result.description;

                    preferencesService
                        .getIsCredentialSaved()
                        .then(result => {
                            vm.isBiometricsRegistered = result;
                        })
                        .finally(() => {
                            vm.busy = false;
                        });
                } else {
                    $log.debug("biometrics - Biometrics is unavailable.");
                    vm.isBiometricAvailable = false;
                    vm.busy = false;
                }
            })
            .catch(error => {
                vm.busy = false;
                $log.error("biometrics - Unexpected error checking Biometrics", angular.toJson(error));
            });
    }

    function submit() {
        if (vm.busy) return;

        var deferred = $q.defer();

        if (vm.isBiometricsRegistered) {
            vm.busy = true;
            $log.debug("biometrics - Removing Biometrics");
            removeBiometrics(deferred);
        } else {
            $log.debug("biometrics - Registering Biometrics");
            vm.busy = true;
            $q.when(
                Dialog.confirm({
                    title: "Confirmation",
                    message: "Please confirm that you wish to be logged out to register for biometrics"
                })
            )
                .then(confirmResult => {
                    if (confirmResult.value) {
                        registerBiometrics(deferred);
                    } else {
                        vm.busy = false;
                        deferred.resolve();
                    }
                })
                .catch(() => {
                    vm.busy = false;
                    deferred.resolve();
                });
        }

        return deferred.promise;
    }

    function registerBiometrics(deferred) {
        preferencesService.setIsCredentialPrompted(false).then(() => {
            $log.debug("biometrics - Logging out the user");
            authorisationService.logout().finally(() => {
                vm.busy = false;
                deferred.resolve();
            });
        });
    }

    function removeBiometrics(deferred) {
        preferencesService
            .setIsCredentialSaved(false)
            .then(() => {
                $log.debug("biometrics - Removing the biometrics");
                loginService
                    .deleteBiometricCredential()
                    .then(result => {
                        if (result) {
                            vm.isBiometricsRegistered = false;
                        }
                    })
                    .finally(() => {
                        vm.busy = false;
                        deferred.resolve();
                    });
            })
            .catch(() => {
                vm.busy = false;
                deferred.resolve();
            });
    }
}

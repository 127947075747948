import { app as services } from "../app.module";
import { NewDelegateViewerWriteModel, DelegateViewerViewModel, UpdateDelegateViewerWriteModel, UpdateDelegateViewerStatusWriteModel } from "../models/api";
import { IApiService } from "./apiServiceProvider";

var serviceId = "delegateViewer";

export class DelegateViewerService {
    constructor(private apiService: IApiService) {
        "ngInject";
    }

    sendInvite(viewer: NewDelegateViewerWriteModel) {
        return this.apiService.delegatedViewerInvite<DelegateViewerViewModel>(viewer).then(r => r.data);
    }

    updateViewer(viewer: DelegateViewerViewModel, studentId: string) {
        var updateObject: UpdateDelegateViewerWriteModel = {
            studentId: studentId,
            delegateViewer: viewer
        };

        return this.apiService.delegatedViewerUpdate<DelegateViewerViewModel[]>(updateObject).then(r => r.data);
    }

    resendInvite(viewer: DelegateViewerViewModel) {
        return this.apiService.delegatedViewerResendInvite(viewer).then(r => r.data);
    }

    updateStatus(token: string, studentId: string, status: string) {
        var updateObject: UpdateDelegateViewerStatusWriteModel = {
            studentId: studentId,
            token: token,
            status: status
        };

        return this.apiService.delegatedViewerChangeStatus<DelegateViewerViewModel[]>(updateObject).then(r => r.data);
    }

    approve(studentId, delegatedViewer) {
        return this.updateStatus(delegatedViewer.token, studentId, "Active");
    }
}

services.service(serviceId, DelegateViewerService);

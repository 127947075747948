import { IPromise } from "angular";
import { app as services } from "../app.module";
import { ConnectionStatus, Network } from "@capacitor/network";
import { IApiService } from "./apiServiceProvider";

const serviceId = "networkService";
services.factory(serviceId, networkService);

export interface INetworkService {
    getNetworkStatus(): Promise<ConnectionStatus>;
    isOnline(): Promise<boolean>;
    onNetworkStatusChanged(callback: (status: ConnectionStatus) => void): void;
    removeOnNetworkStatusChanged(): void;
    getIsMaintenance(): IPromise<boolean>;
}

function networkService(apiService: IApiService, $q: ng.IQService): INetworkService {
    "ngInject";

    return {
        getNetworkStatus,
        isOnline,
        onNetworkStatusChanged,
        removeOnNetworkStatusChanged,
        getIsMaintenance
    };

    function getIsMaintenance(): IPromise<boolean> {
        if (isOnline()) {
            return apiService.getIsMaintenance().then(response => {
                const isMaintenance = (response?.data as string)?.indexOf("QParents is currently in offline mode for planned maintenance") > -1;
                return $q.resolve(isMaintenance);
            });
        } else {
            return $q.resolve(false);
        }
    }

    async function isOnline() {
        const networkStatus = await getNetworkStatus();

        return networkStatus.connected;
    }

    async function getNetworkStatus() {
        return await Network.getStatus();
    }

    function onNetworkStatusChanged(callback: (status: ConnectionStatus) => void): void {
        Network.addListener("networkStatusChange", status => {
            callback(status);
        });
    }

    async function removeOnNetworkStatusChanged() {
        await Network.removeAllListeners();
    }
}

import { app as services } from "../app.module";

var serviceId = "blobService";

export class BlobService {
    createBlobFromDataUrl(dataUrl, type) {
        return fetch(dataUrl)
            .then(b => b.arrayBuffer())
            .then(buff => {
                const byteArray = new Uint8Array(buff);
                return new Blob([byteArray], { type: type });
            });
    }

    getPhotoContentUrl(blob: Blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        return new Promise<string>((resolve, reject) => {
            reader.onloadend = () => {
                let result;
                if (typeof reader.result === "string") {
                    result = reader.result;
                } else if (reader.result instanceof ArrayBuffer) {
                    const uint8Array = new Uint8Array(reader.result);
                    result = String.fromCharCode.apply(null, uint8Array);
                }
                result = result.replace(/ /g, "%20");
                resolve(result);
            };
            reader.onerror = () => {
                reject(reader.error);
            };
        });
    }
}

services.service(serviceId, BlobService);

import "./combinedUpcomingEvents.less";

import moment from "moment";

import { FEATURES } from "../../configuration";
import { getDayOfWeekLabel } from "../../services/dayHelper";

import { IDataContext } from "../../services/dataContext";
import { app } from "../../app.module";
import { enumerateDaysBetweenDates } from "../../tools/dates";

import templateUrl from "./combinedUpcomingEvents.html";
import { IDeviceService } from "@/app/services/deviceServiceProvider";
import { formatDateTimeToIcsDate, formatDateToShortMonthDay } from "../../services/dateHelper";
import { getFormattedTimeRange } from "../../services/timeHelper";
import { EventViewModel } from "@/app/models/api";
import { ICalendarData, ICalendarService } from "../..//services/calendarService";

const controllerId = "combinedUpcomingEvents";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: CombinedUpcomingEventsComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

var eventTypePrefixes = {
    0: "E",
    1: "A",
    2: "X",
    3: "V"
};

interface StudentEventViewModel extends EventViewModel {
    studentId: string;
    studentName: string;
    schoolName: string;
    schoolCode: string;
}

interface eventType {
    name: string;
    displayName: string;
    ticked: boolean;
}

interface studentDetails {
    name: string;
    id: string;
    ticked: boolean;
}

interface schoolDetails {
    name: string;
    code: string;
    ticked: boolean;
}

export class CombinedUpcomingEventsComponent {
    combinedUpcomingEvents: StudentEventViewModel[];
    eventsByDay: { date: moment.Moment; agenda: StudentEventViewModel[] }[];
    userHasDelegatedStudents: boolean;
    isMobileApp: boolean;
    eventTypes: eventType[] = [
        { displayName: "Exams (E)", name: "Exams", ticked: true },
        { displayName: "Assessments (A)", name: "Assessments", ticked: true },
        { displayName: "Excursions (X)", name: "Excursions", ticked: true },
        { displayName: "School Events (V)", name: "School Events", ticked: true }
    ];
    students: studentDetails[];
    schools: schoolDetails[];
    upcomingEventsFeatureTurnedOffForAllStudents: boolean;
    studentsWithoutUpcomingEventsFeature: {
        name: string;
        schoolName: string;
    }[];
    filter: {
        startDate: Date;
        endDate: Date;
        selectedStudents: studentDetails[];
        selectedSchools: schoolDetails[];
        selectedEventTypes: eventType[];
    };

    constructor(
        private dataContext: IDataContext,
        private calendarService: ICalendarService,
        deviceService: IDeviceService
    ) {
        "ngInject";
        this.combinedUpcomingEvents = [];
        this.studentsWithoutUpcomingEventsFeature = [];
        this.students = [];
        this.schools = [];
        this.isMobileApp = deviceService.isMobileApp;

        this.dataContext.getData().then(user => {
            this.userHasDelegatedStudents = user.delegatedStudents.length > 0;
            this.upcomingEventsFeatureTurnedOffForAllStudents = !user.students.some(student => student.features.some(feature => feature.toString() === FEATURES.UPCOMING_EVENTS.toString()));
            user.students.forEach(student => {
                student.enrolments.forEach(enrolment => {
                    if (!enrolment.features.some(feature => feature.toString() === FEATURES.UPCOMING_EVENTS)) {
                        this.studentsWithoutUpcomingEventsFeature.push({ name: student.fullName, schoolName: enrolment.school });
                    } else {
                        this.students.findIndex(s => s.id === student.oneSchoolId) === -1 ? this.students.push({ name: student.fullName, id: student.oneSchoolId, ticked: true }) : null;

                        this.schools.findIndex(s => s.code === enrolment.schoolCode) === -1 ? this.schools.push({ name: enrolment.school, code: enrolment.schoolCode, ticked: true }) : null;

                        this.filter = {
                            selectedStudents: this.students,
                            selectedSchools: this.schools,
                            selectedEventTypes: this.eventTypes,
                            startDate: null,
                            endDate: null
                        };

                        enrolment.upcomingEvents.forEach(upcomingEvent =>
                            this.combinedUpcomingEvents.push({
                                studentId: student.oneSchoolId,
                                studentName: student.fullName,
                                schoolCode: enrolment.schoolCode,
                                schoolName: enrolment.school,
                                displayName: upcomingEvent.displayName,
                                detail: upcomingEvent.detail,
                                eventType: upcomingEvent.eventType,
                                startDate: moment(upcomingEvent.startDate).toDate(),
                                startTime: upcomingEvent.startTime,
                                endDate: moment(upcomingEvent.endDate).toDate(),
                                endTime: upcomingEvent.endTime
                            })
                        );
                    }
                });
            });

            this.filterEvents();
        });

        this.filterEvents();
    }

    getDayLabel(day) {
        return getDayOfWeekLabel(day);
    }

    formatDate(date) {
        return formatDateToShortMonthDay(date);
    }

    getEventTypePrefix(eventType) {
        return eventTypePrefixes[eventType];
    }

    getEventType(eventType) {
        return this.eventTypes[eventType].name;
    }

    filterEvents() {
        var startDatesOfEvents = this.combinedUpcomingEvents.map(e => moment(e.startDate));
        var endDatesOfEvents = this.combinedUpcomingEvents.map(e => moment(e.endDate));
        var minStartDateOfEvent = moment.min(startDatesOfEvents);
        var startDateFilter = this.filter && this.filter.startDate && moment(this.filter.startDate).isValid() ? moment(this.filter.startDate).startOf("day") : minStartDateOfEvent;
        var maxEndDateOfEvent = moment.max(endDatesOfEvents);
        var endDateFilter = this.filter && this.filter.endDate && moment(this.filter.endDate).isValid() ? moment(this.filter.endDate).endOf("day") : maxEndDateOfEvent;

        const isBetween = (start: moment.Moment, end: moment.Moment, valueToCheck: Date): boolean => {
            if (!valueToCheck || !moment(valueToCheck).isValid()) {
                return true;
            }
            if (!start || (!start.isValid() && !end) || !end.isValid()) {
                return true;
            }
            if (!start || !start.isValid()) {
                return moment(valueToCheck).isBefore(end);
            } else if (!end || !end.isValid()) {
                return moment(valueToCheck).isAfter(start);
            }
            return moment(valueToCheck).isAfter(start) || moment(valueToCheck).isBefore(end);
        };

        var filteredEvents = this.combinedUpcomingEvents.filter(e => {
            var dateOverlapsWithDateFilter = isBetween(startDateFilter, endDateFilter, e.startDate) || isBetween(startDateFilter, endDateFilter, e.endDate);

            return (
                this.filter.selectedEventTypes.findIndex(selectedEvent => selectedEvent.name === this.getEventType(e.eventType)) > -1 &&
                this.filter.selectedSchools.findIndex(selectedSchool => selectedSchool.name === e.schoolName) > -1 &&
                this.filter.selectedStudents.findIndex(selectedStudent => selectedStudent.name === e.studentName) > -1 &&
                dateOverlapsWithDateFilter
            );
        });

        var allEventsDateRange = enumerateDaysBetweenDates(minStartDateOfEvent.toDate(), maxEndDateOfEvent.toDate());
        var dateRange = allEventsDateRange.filter(
            d =>
                this.filter &&
                startDateFilter &&
                startDateFilter.isValid() &&
                moment(d).isSameOrAfter(startDateFilter) &&
                endDateFilter &&
                endDateFilter.isValid() &&
                moment(d).isSameOrBefore(endDateFilter)
        );

        this.eventsByDay = dateRange
            .map(key => {
                var items = filteredEvents.filter(e => moment(key).isSameOrAfter(moment(e.startDate).startOf("day")) && moment(key).isSameOrBefore(moment(e.endDate).endOf("day")));
                return {
                    date: moment(key),
                    agenda: items.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))
                };
            })
            .filter(e => e.agenda.length > 0);
        this.eventsByDay = this.eventsByDay.sort((a, b) => moment(a.date).diff(moment(b.date)));
    }

    getTimeLabel(upcomingEvent: StudentEventViewModel) {
        return getFormattedTimeRange({ date: upcomingEvent.startDate, time: upcomingEvent.startTime }, { date: upcomingEvent.endDate, time: upcomingEvent.endTime });
    }

    loadImage(imagePath: string) {
        return require("../../../content/img/" + imagePath);
    }

    openEventInCalendar(event: StudentEventViewModel) {
        const icsEvent = this.createIcsEvent(event);

        const icsEventBlob = this.calendarService.createIcsFile([icsEvent]);

        if (icsEventBlob) {
            this.calendarService.openIcsFile(icsEventBlob);
        }
    }

    createIcsEvent(event: StudentEventViewModel): ICalendarData {
        var studentId = event.studentId;
        var centreCode = event.schoolCode;

        return {
            id: `${studentId}||${centreCode}||${event.eventType}||${formatDateTimeToIcsDate(event.startDate, event.startTime)}||${formatDateTimeToIcsDate(event.endDate, event.endTime)}`,
            startDate: event.startDate,
            startTime: event.startTime,
            endDate: event.endDate,
            endTime: event.endTime,
            summary: `${studentId} - ${event.displayName}`,
            description: event.detail
        };
    }

    loadCalendarIcon() {
        return require("../../../content/icons/calendar-outline.svg");
    }
}

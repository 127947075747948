import { ILogService, IQService } from "angular";
import { app as services } from "../app.module";
import { IApiService } from "./apiServiceProvider";
import { BlobService } from "./blobService";
import { IDeviceService } from "./deviceServiceProvider";

var serviceId = "photoService";

export class PhotoService {
    constructor(
        private $log: ILogService,
        private $q: IQService,
        private apiService: IApiService,
        private deviceService: IDeviceService,
        private blobService: BlobService
    ) {
        "ngInject";
    }

    downloadPhoto(url: string) {
        const deferred = this.$q.defer<string>();
        this.$log.debug("photoService - Dowloading Photo");
        this.apiService
            .downloadPhoto(url)
            .then(response => {
                // --------------------------------------------------------------------------------
                // Blob value is encoded using base 64 string
                // See: https://github.com/ionic-team/capacitor/issues/6126
                // --------------------------------------------------------------------------------
                if (this.deviceService.isMobileApp) {
                    const photoContentUrl = `data:${response.headers["Content-Type"] ?? "image/png"};base64,${response.data}`;
                    deferred.resolve(photoContentUrl);
                } else {
                    this.$log.debug("photoService - Getting Photo Content", response);
                    const photoBlob = response.data as Blob;
                    this.blobService
                        .getPhotoContentUrl(photoBlob)
                        .then(url => {
                            const photoContentUrl = url;
                            deferred.resolve(photoContentUrl);
                        })
                        .catch(blobResponse => {
                            this.$log.error("photoService - Getting Photo Content Failed", blobResponse);
                            deferred.reject();
                        });
                }
            })
            .catch(response => {
                this.$log.error("photoService - Dowloading Photo Failed", response);
                deferred.reject();
            });

        return deferred.promise;
    }

    retrieveSchoolPhoto(studentId) {
        return this.apiService.studentUseSchoolPhoto<string>(studentId).then(function (response) {
            return response.data;
        });
    }

    promotePhoto(studentId, type) {
        return this.apiService.promoteStudentPhoto<string>(studentId, type).then(function (response) {
            return response.data;
        });
    }

    uploadPhoto(studentId: string, dataUrl: string): ng.IPromise<string> {
        var base64 = dataUrl.split(",")[1];
        var data = [];
        data.push(`${encodeURIComponent("image")}=${encodeURIComponent(base64)}`);
        const urlEncodedData = data.join("&").replace(/%20/g, "+");
        return this.apiService.uploadStudentPhoto<string>(studentId, urlEncodedData).then(function (response) {
            return response.data;
        });
    }
}

services.service(serviceId, PhotoService);

import {app} from '../app.module';
import INgModelController = angular.INgModelController;
app.directive('matchCaseInsensitive', function () {

    return {
        require: 'ngModel',
        restrict: 'A',
        scope: {
            matchCaseInsensitive: '='
        },
        link: function (scope:any, elem, attr, ngModel:INgModelController) {
            scope.$watch(function () {
                return !ngModel.$modelValue
                    || !scope.matchCaseInsensitive
                    || scope.matchCaseInsensitive.toLowerCase() === ngModel.$modelValue.toLowerCase();
            }, function (currentValue) {
                ngModel.$setValidity('matchCaseInsensitive', currentValue);
            });
        }
    };
});
import { app } from "../../app.module";
import { StudentContext, IContextLoader } from "../../services/contextLoader";
import { User } from "../../models/user";
import { Student } from "../../models/student";
import { Enrolment } from "../../models/enrolment";
import { AcademicResultViewModel } from "@/app/models/api";
import templateUrl from "./reportWidget.html";
import { getToday } from "../../services/dayHelper";
require("./reportWidget.less");

var controllerId = "reportWidget";
app.directive(controllerId, () => ({
    templateUrl,
    controller: ReportWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

class ReportWidget implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    newReports: number;
    latestReport: AcademicResultViewModel;

    constructor($routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader) {
        "ngInject";
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            var orderedReports = this.enrolment.academicResults;

            var today = getToday();
            var recentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14);

            this.newReports = orderedReports.filter(function (report) {
                return new Date(report.endDate) >= recentDate;
            }).length;

            this.latestReport = orderedReports[0];
        });
    }
}

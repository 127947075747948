import { app } from "../../app.module";
import { IDataContext } from "../../services/dataContext";
import { CombinedFinanceRecord } from "../../models/combinedFinanceRecord";
import { PaymentsFilter } from "../../models/paymentsFilter";
import { ADMIN_VIEW_COOKIES, FEATURES } from "../../configuration";
import moment from "moment";

import "./combinedPayments.less";
import templateUrl from "./combinedPayments.html";
import { IDeviceService } from "@/app/services/deviceServiceProvider";
const controllerId = "combinedPayments";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: CombinedPaymentsComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

export class CombinedPaymentsComponent {
    userHasDelegatedStudents: boolean;
    financeRecordsDue: CombinedFinanceRecord[];
    filteredFinanceRecordsDue: CombinedFinanceRecord[];
    financeRecordsPaid: CombinedFinanceRecord[];
    filteredFinanceRecordsPaid: CombinedFinanceRecord[];
    oneSchoolParentId: number;
    paymentsFeatureTurnedOffForAllStudents: boolean;
    isMobileApp: boolean;
    studentsWithoutPaymentFeature: {
        name: string;
        schoolName: string;
    }[];
    outstandingPaymentsFilter: PaymentsFilter;
    paymentHistoryFilter: PaymentsFilter;
    studentListForOutstandingPaymentsFilter: {
        studentName: string;
        studentId: string;
        ticked: boolean;
    }[];
    schoolListForOutstandingPaymentsFilter: {
        schoolCode: string;
        schoolName: string;
        ticked: boolean;
    }[];
    studentListForPaymentHistoryFilter: {
        studentName: string;
        studentId: string;
        ticked: boolean;
    }[];
    schoolListForPaymentHistoryFilter: {
        schoolCode: string;
        schoolName: string;
        ticked: boolean;
    }[];
    schoolUserId: string;

    constructor(private dataContext: IDataContext, deviceService: IDeviceService) {
        "ngInject";
        this.financeRecordsDue = [];
        this.filteredFinanceRecordsDue = [];
        this.financeRecordsPaid = [];
        this.filteredFinanceRecordsPaid = [];
        this.studentsWithoutPaymentFeature = [];
        this.schoolListForOutstandingPaymentsFilter = [];
        this.schoolListForPaymentHistoryFilter = [];
        this.studentListForOutstandingPaymentsFilter = [];
        this.studentListForPaymentHistoryFilter = [];
        this.isMobileApp = deviceService.isMobileApp;

        this.dataContext.getData().then(user => {
            this.userHasDelegatedStudents = user.delegatedStudents.length > 0;
            this.oneSchoolParentId = user.oneSchoolParentId;
            this.paymentsFeatureTurnedOffForAllStudents = !user.students.some(student => student.features.some(feature => feature.toString() === FEATURES.FINANCE.toString()));

            if (this.dataContext.isAdminView()) {
                this.schoolUserId = sessionStorage.getItem(ADMIN_VIEW_COOKIES.SCHOOL_USER_ID);
            }

            user.students.forEach(student => {
                student.enrolments.forEach(enrolment => {
                    if (!enrolment.features.some(feature => feature === FEATURES.FINANCE.toString())) {
                        this.studentsWithoutPaymentFeature.push({ name: student.fullName, schoolName: enrolment.school });
                    } else {
                        enrolment.financeRecordsDue.forEach(financeRecordDue => {
                            var combinedFinanceRecord: CombinedFinanceRecord = {
                                schoolName: enrolment.school,
                                bPointPaymentUrl: financeRecordDue.bPointPaymentUrl,
                                amountOwing: financeRecordDue.amountOwing,
                                originalAmount: financeRecordDue.originalAmount,
                                transactionDate: financeRecordDue.transactionDate,
                                status: financeRecordDue.status,
                                invoiceNumber: financeRecordDue.invoiceNumber,
                                paymentRef: financeRecordDue.paymentRef,
                                dueDate: financeRecordDue.dueDate,
                                description: financeRecordDue.description,
                                pdfExists: financeRecordDue.pdfExists,
                                customerReferenceNumber: financeRecordDue.customerReferenceNumber,
                                paymentsAreInProgress: financeRecordDue.paymentsAreInProgress,
                                isInvoice: financeRecordDue.isInvoice,
                                isPayment: financeRecordDue.isPayment,
                                pendingPayments: financeRecordDue.pendingPayments,
                                paymentReceiptNumber: financeRecordDue.paymentReceiptNumber,
                                typeDescription: financeRecordDue.typeDescription,
                                studentName: financeRecordDue.studentName,
                                isSelectedForPayment: financeRecordDue.isSelectedForPayment,
                                paymentAmount: financeRecordDue.paymentAmount,
                                paymentAuthToken: financeRecordDue.paymentAuthToken,
                                merchantNumber: financeRecordDue.merchantNumber,
                                billerCode: financeRecordDue.billerCode,
                                schoolCode: enrolment.schoolCode,
                                studentEqId: student.oneSchoolId
                            };
                            this.financeRecordsDue.push(combinedFinanceRecord);
                        });

                        enrolment.financeRecordsPaid.forEach(financeRecordPaid => {
                            var combinedFinanceRecord: CombinedFinanceRecord = {
                                schoolName: enrolment.school,
                                bPointPaymentUrl: financeRecordPaid.bPointPaymentUrl,
                                amountOwing: financeRecordPaid.amountOwing,
                                originalAmount: financeRecordPaid.originalAmount,
                                transactionDate: financeRecordPaid.transactionDate,
                                status: financeRecordPaid.status,
                                invoiceNumber: financeRecordPaid.invoiceNumber,
                                paymentRef: financeRecordPaid.paymentRef,
                                dueDate: financeRecordPaid.dueDate,
                                description: financeRecordPaid.description,
                                pdfExists: financeRecordPaid.pdfExists,
                                customerReferenceNumber: financeRecordPaid.customerReferenceNumber,
                                paymentsAreInProgress: financeRecordPaid.paymentsAreInProgress,
                                isInvoice: financeRecordPaid.isInvoice,
                                isPayment: financeRecordPaid.isPayment,
                                pendingPayments: financeRecordPaid.pendingPayments,
                                paymentReceiptNumber: financeRecordPaid.paymentReceiptNumber,
                                typeDescription: financeRecordPaid.typeDescription,
                                studentName: financeRecordPaid.studentName,
                                isSelectedForPayment: financeRecordPaid.isSelectedForPayment,
                                paymentAmount: financeRecordPaid.paymentAmount,
                                paymentAuthToken: financeRecordPaid.paymentAuthToken,
                                merchantNumber: financeRecordPaid.merchantNumber,
                                billerCode: financeRecordPaid.billerCode,
                                schoolCode: enrolment.schoolCode,
                                studentEqId: student.oneSchoolId
                            };
                            this.financeRecordsPaid.push(combinedFinanceRecord);
                        });

                        this.studentListForOutstandingPaymentsFilter.findIndex(s => s.studentId === student.oneSchoolId) === -1
                            ? this.studentListForOutstandingPaymentsFilter.push({ studentName: student.fullName, studentId: student.oneSchoolId, ticked: true })
                            : null;

                        this.schoolListForOutstandingPaymentsFilter.findIndex(s => s.schoolCode === enrolment.schoolCode) === -1
                            ? this.schoolListForOutstandingPaymentsFilter.push({ schoolName: enrolment.school, schoolCode: enrolment.schoolCode, ticked: true })
                            : null;

                        this.studentListForPaymentHistoryFilter.findIndex(s => s.studentId === student.oneSchoolId) === -1
                            ? this.studentListForPaymentHistoryFilter.push({ studentName: student.fullName, studentId: student.oneSchoolId, ticked: true })
                            : null;

                        this.schoolListForPaymentHistoryFilter.findIndex(s => s.schoolCode === enrolment.schoolCode) === -1
                            ? this.schoolListForPaymentHistoryFilter.push({ schoolName: enrolment.school, schoolCode: enrolment.schoolCode, ticked: true })
                            : null;

                        this.outstandingPaymentsFilter = {
                            selectedSchools: this.schoolListForOutstandingPaymentsFilter,
                            selectedStudents: this.studentListForOutstandingPaymentsFilter,
                            startDate: null,
                            endDate: null
                        };

                        this.paymentHistoryFilter = {
                            selectedSchools: this.schoolListForPaymentHistoryFilter,
                            selectedStudents: this.studentListForPaymentHistoryFilter,
                            startDate: null,
                            endDate: null
                        };
                    }
                });
            });
            this.filterOutstandingPayments();
            this.filterPaymentHistory();
        });
    }

    toggle(financeRecord: CombinedFinanceRecord & { expanded: boolean }) {
        financeRecord.expanded = financeRecord.expanded ? false : true;
    }

    filterOutstandingPayments() {
        this.filteredFinanceRecordsDue = this.filter(this.financeRecordsDue, this.outstandingPaymentsFilter);
        this.filteredFinanceRecordsDue = this.filteredFinanceRecordsDue.sort((a, b) => moment(a.dueDate).diff(moment(b.dueDate)));
    }

    filterPaymentHistory() {
        this.filteredFinanceRecordsPaid = this.filter(this.financeRecordsPaid, this.paymentHistoryFilter);
        this.filteredFinanceRecordsPaid = this.filteredFinanceRecordsPaid.sort((a, b) => moment(a.dueDate).diff(moment(b.dueDate)));
    }

    filter(payments: CombinedFinanceRecord[], filter: PaymentsFilter) {
        if (!filter) {
            return payments;
        } else {
            return payments.filter(e => {
                return (
                    (filter.selectedSchools.length == 0 || filter.selectedSchools.findIndex(selectedSchool => selectedSchool.schoolCode === e.schoolCode) > -1) &&
                    (filter.selectedStudents.length == 0 || filter.selectedStudents.findIndex(selectedStudent => selectedStudent.studentId === e.studentEqId) > -1) &&
                    (filter.startDate && moment(filter.startDate).isValid() ? moment(e.dueDate).isSameOrAfter(filter.startDate) : true) &&
                    (filter.endDate && moment(filter.endDate).isValid() ? moment(e.dueDate).isSameOrBefore(filter.endDate) : true)
                );
            });
        }
    }
}

import { app } from "../../../app.module";
import { ILandingPageContentWithImage } from "../../landing-page";
import templateUrl from "./functionality-section.html";
import "./functionality-section.less";

const functions: ILandingPageContentWithImage[] = [
    {
        title: 'Sign-in securely',
        description: 'All information is always kept private and only visible to those you choose.',
        iconSrc: 'signin.svg',
    },
    {
        title: 'Provide consent',
        description: 'Respond to requests for consent for things like excursions, photos, etc.',
        iconSrc: 'computer.svg',
    },
    {
        title: 'Make payments online',
        description: 'Make payments and view all your invoices in one place.',
        iconSrc: 'pay.svg',
    },
    {
        title: 'View academic reports',
        description: 'View and download all report cards.',
        iconSrc: 'reportcard.svg',
    },
    {
        title: 'Inform attendance',
        description: 'Let the school know about any absences from school.',
        iconSrc: 'sick.svg',
    },
    {
        title: 'See upcoming events',
        description: 'Never miss a date - see all upcoming school events.',
        iconSrc: 'events.svg',
    },
    {
        title: 'Check timetables',
        description: 'Know what learning is scheduled each day.',
        iconSrc: 'roster.svg',
    },
    {
        title: 'Access enrolment history',
        description: 'See historical enrolment details.',
        iconSrc: 'school.svg',
    },
    {
        title: 'Update details',
        description: 'Easily update parent and student details such as contact and medical information from wherever you are at any time.',
        iconSrc: 'child.svg',
    }
]

app.component("functionalitySectionComponent", {
    templateUrl: templateUrl,
    controller: functionalitySection,
    controllerAs: "vm"
});

function functionalitySection() {
    var vm = this;
    vm.functions = functions;

    vm.loadIcon = function(iconPath) {
        return require('../../../../content/icons/' + iconPath);
    };
}
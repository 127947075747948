import { app } from "../app.module";

import templateUrl from "./privacy.html";

app.directive("privacyComponent", () => ({
    templateUrl,
    scope: true,
    replace: true,
    restrict: "E"
}));

import {app} from '../app.module';
app .directive('focus',  ['$timeout', function ($timeout) {
            return {
                scope: {
                    trigger: '@focus'
                },
                link: function (scope, element) {
                    scope.$watch('trigger', function (value) {
                        if (value === "true") {
                            $timeout(function () {
                                try {
                                    element[0].focus();
                                } catch (e) {     
                                    // Fails on IE8. Can't check for existence of element[0].focus as it's also the focus attribute.
                                    // Swallow it so we don't log it to the server side.
                                }                                
                            });
                        }
                    });
                }
            };
        }]);
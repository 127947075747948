import { app as services } from "../app.module";
import { IPromise } from "angular";
import UAParser from "ua-parser-js";
import { IDeviceService } from "./deviceServiceProvider";
import { IApiService } from "./apiServiceProvider";
const serviceId = "platformService";

export class platformService {
    constructor(private $q: ng.IQService, private $location: any, private $log: ng.ILogService, private networkService: any, private apiService: IApiService, private deviceService: IDeviceService) {
        "ngInject";
    }

    public checkVersion(): IPromise<void> {
        var deferred = this.$q.defer<void>();
        if (!this.deviceService.isMobileApp) {
            deferred.resolve();
        }
        if (this.networkService.isOnline()) {
            this.networkService.getIsMaintenance().then(isMaintenance => {
                if (!isMaintenance) {
                    this.getClientVersion().then(clientVersion => {
                        this.performClientVersionCheck(clientVersion).then(() => {
                            deferred.resolve();
                        });
                    });
                } else {
                    deferred.reject();
                }
            });
        }
        return deferred.promise;
    }

    public getClientVersion(): IPromise<string> {
        var deferred = this.$q.defer<string>();
        if (!this.deviceService.isMobileApp) {
            deferred.resolve("Web");
        } else {
            this.deviceService.getAppInfo().then(appInfo => {
                deferred.resolve(appInfo.version);
            });
        }

        return deferred.promise;
    }

    private performClientVersionCheck = (clientVersion: string): ng.IPromise<void> => {
        var self = this;
        return this.apiService.getMinimumApiVersion<{ iOS: string; android: string }>().then(response => {
            let data = response.data;

            self.$log.debug("App version: " + clientVersion + "  - Minimum App version: " + data.android);
            const targetVersion = this.deviceService.isIOs() ? data.iOS : data.android;
            if (self.versionCompare(clientVersion, targetVersion, { zeroExtend: true }) < 0) {
                // As routing service works with max one route parameter for detecting anonymous paths
                // So we have to send this versions as a single parameter with a delimeter
                self.$location.path(`/update/${clientVersion}|${targetVersion}`);
            }
        });
    };

    private versionCompare(currentVersion: string, requiredVersion: string, options: { lexicographical?: boolean; zeroExtend: boolean }): number {
        let lexicographical = options && options.lexicographical;
        let zeroExtend = options && options.zeroExtend;

        if (requiredVersion == undefined) {
            // If no required version supplied, assume is valid
            return 1;
        }
        if (currentVersion == undefined) {
            // If no current version, assume is not valid
            return -1;
        }

        // Left as : any[] as it may replace values with numbers
        let v1parts: any[] = currentVersion.split(".");
        let v2parts: any[] = requiredVersion.split(".");

        function isValidPart(x): boolean {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }

        if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            return NaN;
        }

        if (zeroExtend) {
            while (v1parts.length < v2parts.length) v1parts.push("0");
            while (v2parts.length < v1parts.length) v2parts.push("0");
        }

        if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
        }

        for (var i = 0; i < v1parts.length; ++i) {
            if (v2parts.length == i) {
                return 1;
            }

            if (v1parts[i] == v2parts[i]) {
                continue;
            } else if (v1parts[i] > v2parts[i]) {
                return 1;
            } else {
                return -1;
            }
        }

        if (v1parts.length != v2parts.length) {
            return -1;
        }

        return 0;
    }

    public isSafariBrowserAndVersionIsLessThanOrEqualTo12(): boolean {
        if (this.deviceService.isMobileApp) {
            return false;
        }
        try {
            const userAgentResult = new UAParser().getResult();
            const bro = userAgentResult.browser;
            const isSafari = bro?.name?.includes("Safari");
            if (isSafari) {
                const majorVersion = Number(bro.version?.substring(0, bro.version.indexOf(".")));
                const isOuOfDate = !!majorVersion && majorVersion <= 12;
                return isOuOfDate;
            }
        } catch {}
        return false;
    }
}

services.service(serviceId, platformService);

import { IApiService } from "@/app/services/apiServiceProvider";
import { app } from "../../app.module";
import { SESSION_AO_CODE_KEY } from "../../configuration";

const serviceId = "accountOwnerVerifyInitialise";

app.factory(serviceId, accountOwnerVerifyInitialise);

function accountOwnerVerifyInitialise($q, $location, apiService: IApiService, localStorageService) {
    "ngInject";
    return function () {
        var deferred = $q.defer();
        var sessionId = localStorageService.get(SESSION_AO_CODE_KEY);
        if (!sessionId) {
            $location.path("/");
            deferred.reject();
        } else {
            apiService.getAccountOwnerVerification(sessionId).then(
                function successCallback(response) {
                    deferred.resolve(response.data);
                },
                function errorCallback() {
                    deferred.reject();
                }
            );
        }

        return deferred.promise;
    };
}

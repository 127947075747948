import { app } from "../app.module";
import { FEATURES } from "../configuration";
import { Enrolment } from "../models/enrolment";
import { EnrolmentStatus } from "../models/enums";
import { Student } from "../models/student";
import { User } from "../models/user";
import { AuthorisationService } from "../services/authorisationService";
import { IContextLoader, StudentContext } from "../services/contextLoader";

import templateUrl from "./attendance.html";

const controllerId = "attendance";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: AttendanceComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

class AttendanceComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    chartConfig: any;
    absenceTotal: number[];
    showAttendanceRecord: boolean;
    isFeatureAvailable: boolean;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        authorisationService: AuthorisationService,
        private $filter: ng.IFilterService
    ) {
        "ngInject";
        this.showAttendanceRecord = false;
        this.isFeatureAvailable = true;

        contextLoader.load(this, $routeParams, "student").then(() => {
            if (!authorisationService.hasFeatureAccess(this, FEATURES.ATTENDANCE)) {
                this.isFeatureAvailable = false;
                return;
            }

            this.absenceTotal = this.enrolment.absenceTotals.map(_ => _.daysAbsent);

            this.showAttendanceRecord = this.enrolment.attendancePercentageThisCalendarYear != null;

            // FutureStudentNoAbsence - Currently, Absence cannot be recorded for Future Student
            this.isFeatureAvailable = this.enrolment?.enrolmentStatus === EnrolmentStatus.Active;

            this.initializeChart();
        });
    }

    initializeChart() {
        var self = this;
        this.chartConfig = {
            options: {
                chart: {
                    type: "column",
                    margin: [50, 50, 70, 50]
                },
                tooltip: {
                    formatter: function () {
                        return self.chartTooltip(this);
                    },
                    useHTML: true,
                    shared: true
                },
                legend: {
                    enabled: false
                }
            },
            series: [
                {
                    name: "Absence",
                    data: this.absenceTotal
                }
            ],
            xAxis: {
                categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
            },
            yAxis: {
                title: {
                    text: ""
                },
                allowDecimals: false
            },
            title: {
                text: ""
            },
            loading: false
        };
    }

    getDayOfWeek(name) {
        switch (name) {
            case "Monday":
                return 1;
            case "Tuesday":
                return 2;
            case "Wednesday":
                return 3;
            case "Thursday":
                return 4;
            case "Friday":
                return 5;
            default:
                return 0;
        }
    }

    chartTooltip(point) {
        var dayOfWeek = this.getDayOfWeek(point.x),
            totalDays = 0,
            tooltip = $('<div class="chart-tooltip clearfix"><h6 class="header"></h6><ul class="days"></ul></div>');

        this.enrolment.absenceRecords.forEach(record => {
            // TODO: this probably should not be hard-coded
            const late = "Late (NP)";
            const early = "Early (NP)";

            // NOT SURE WHY but dayOfWeek is a string
            if (parseInt(record.dayOfWeek) != dayOfWeek) {
                return;
            }

            // when a student came late or left early, it does not count as an absence
            // when aggregating by days (they could have been late only 10 minutes)
            if (record.absencePeriod !== late && record.absencePeriod !== early) {
                // add date to the tooltip
                var day = $("<li/>").html(this.$filter("date")(record.absenceDate, "dd/MM/yyyy"));

                tooltip.find("ul.days").append(day);

                // increase absent days count
                totalDays += record.isFullDay ? 1 : 0.5;
            }
        });

        tooltip.find(".header").html(totalDays + (totalDays == 1 ? " day" : " days"));

        return tooltip.wrap("<div />").parent().html();
    }

    toggle(absenceRecord, expand) {
        this.enrolment.absenceRecords.forEach((x: any) => (x.expanded = false));
        absenceRecord.expanded = expand;
    }

    loadImage(imagePath: string) {
        return require("../../content/img/" + imagePath);
    }
}

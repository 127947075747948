import { ILogService, IQService } from "angular";
import { app } from "../app.module";
import { IApiService } from "../services/apiServiceProvider";
import { IDataContext } from "../services/dataContext";

const controllerId = "email";
import templateUrl from "./email.html";
import { ICommonService } from "../services/common";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: email,
    controllerAs: "vm",
    restrict: "E",
    replace: true
}));

function email(common: ICommonService, dataContext: IDataContext, $q: IQService, $log: ILogService, $timeout, apiService: IApiService, $scope) {
    "ngInject";
    var vm = this;
    var userToUpdate = null;
    vm.password = null;
    vm.email = null;
    vm.newEmail = null;
    vm.save = save;
    vm.error = false;
    vm.busy = false;

    common.activateController([getProfile()], controllerId).then(function () {});

    function getProfile() {
        return dataContext.getData().then(function (user) {
            userToUpdate = user;
            vm.email = dataContext.isAdminView() ? "Unavailable in QParents Administration View" : user.emailAddress;
            vm.submitted = false;
            vm.submittedEmail = null;
            setInitialFocus();
        });
    }

    function setInitialFocus() {
        $timeout(function () {
            $("#email").trigger("focus");
        }, 100);
    }

    function save() {
        if ($scope.editEmail.$invalid) return;
        if (vm.busy) return;

        vm.busy = true;
        vm.error = false;

        var deferred = $q.defer();
        var newEmail = vm.newEmail;
        var confirmEmail = vm.confirmEmail;
        var password = vm.password;

        apiService
            .changeEmail({
                email: newEmail,
                confirmEmail: confirmEmail,
                password: password
            })
            .then(response => {
                const data = response.data;
                if (data.success) {
                    userToUpdate.pendingEmailAddress = newEmail;
                    vm.submittedEmail = newEmail;
                    vm.submitted = true;
                } else {
                    vm.error = true;
                }
            })
            .catch(response => {
                vm.error = true;
                $log.error("Error changing the email", response);
            })
            .finally(() => {
                vm.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }
}

import {app} from '../app.module';
app.directive('currentLink', ['$location', function ($location) {

        return {
            restrict: 'A',
            link: function (scope, element, attrs) {

                scope.$watch('$location.path()', function () {
                    var classToToggle = attrs.currentLink;
                    var currentUrl = $location.path();

                    var linkUrl = element.find('a').attr('href');
                    if (linkUrl) {
                        // remove leading #
                        linkUrl = linkUrl.substring(1);
                    }

                    if (linkUrl === currentUrl) {
                        element.addClass(classToToggle);
                    }
                    else {
                        element.removeClass(classToToggle);
                    }
                });

            }
        };

    }]);
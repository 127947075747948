import moment, { Moment } from "moment";

export const isoDateFormat = "YYYY-MM-DD";
export const auDateFormat = "DD/MM/YYYY";

export function parseDate(date) {
    if (date == null) return null;
    let utcFormatRegex = new RegExp("\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d\\.\\d+([+-][0-2]\\d:[0-5]\\d|Z)");

    let parsedDate: moment.Moment;

    if (typeof date === "object") {
        parsedDate = moment(date);
    } else if (typeof date === "string") {
        let trimmedDate = date;

        while (trimmedDate[0] === '"') {
            trimmedDate = trimmedDate.substring(1);
        }

        while (trimmedDate[trimmedDate.length - 1] === '"') {
            trimmedDate = trimmedDate.substring(0, trimmedDate.length - 1);
        }

        if (utcFormatRegex.test(<string>trimmedDate)) {
            // date in long UTC format (e.g. 2019-11-20T14:00:00.00Z), we just need the date part
            parsedDate = moment.utc(trimmedDate);
        } else {
            // date in short ISO or AU format
            parsedDate = moment(trimmedDate, [isoDateFormat, auDateFormat]);
        }
    }

    if (parsedDate && parsedDate.isValid()) {
        return parsedDate;
    } else {
        return null;
    }
}

export function formatDateToShortMonthDay(date) {
    return moment(date).format("D MMM");
}

export function formatDateTimeMomentToIcsDate(dateTimeMoment: Moment) {
    return dateTimeMoment.utc(false).format("YYYYMMDDTHHmmss[Z]");
}

export function formatDateTimeToIcsDate(date: Date, time: string) {
    const timeMoment = moment(time, "hh:mm A");
    const dateMoment = moment(date);

    const dateTimeMoment = dateMoment.set({
        hour: timeMoment.hour(),
        minute: timeMoment.minute(),
        second: timeMoment.second()
    });

    return formatDateTimeMomentToIcsDate(dateTimeMoment);
}

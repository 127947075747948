import moment from "moment";
import { app } from "../../app.module";
import { StudentContext, IContextLoader } from "../../services/contextLoader";
import { User } from "../../models/user";
import { Student } from "../../models/student";
import { Enrolment } from "../../models/enrolment";
import templateUrl from "./behaviourWidget.html";
require("./behaviourWidget.less");
const controllerId = "behaviourWidget";
app.directive(controllerId, () => ({
    templateUrl,
    controller: BehaviourWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

interface IBehaviour {
    incidentId: number;
    incidentDate: string;
    incidentType: string;
    incidentCategories: string;
    schoolName: string;
}

class BehaviourWidget implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    behaviour: IBehaviour;
    behaviourCategories: string;
    totalEvents: number;
    studentName: string;
    newEvents: number;

    constructor($routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader) {
        "ngInject";
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            this.behaviour = this.enrolment.behaviours.sort((a, b) => moment(b.incidentDate).diff(moment(a.incidentDate)))[0];

            this.behaviourCategories = "";

            if (this.behaviour?.incidentCategories) {
                this.behaviourCategories = this.behaviour.incidentCategories.length > 30 ? this.behaviour.incidentCategories.substr(0, 17) + "..." : this.behaviour.incidentCategories;
            }

            this.totalEvents = this.enrolment.behaviours.length;
            this.studentName = this.student.firstName();

            let recentDate = this.getRecentDate();
            this.newEvents = this.enrolment.behaviours.filter(event => {
                return new Date(event.incidentDate) >= recentDate;
            }).length;
        });
    }

    getRecentDate() {
        let m = moment();
        m.add(moment.duration(-14, "days"));
        return m.toDate();
    }

    getBehaviourIconClass() {
        return this.totalEvents === 0 ? "fa-check-square" : this.behaviour && this.behaviour.incidentType === "Positive" ? "fa-check-square" : "fa-exclamation-triangle";
    }
}

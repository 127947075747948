import { IApiService } from "@/app/services/apiServiceProvider";
import { app } from "../../app.module";
import { IQService } from "angular";

const templateUrl = require("./forgotPassword.html");
const controllerId = "forgotPassword";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: forgotPassword,
    controllerAs: "vm",
    restrict: "E",
    replace: true,
    scope: true
}));

function forgotPassword(apiService: IApiService, $q: IQService, $scope) {
    "ngInject";
    var vm = this;
    vm.email = null;
    vm.save = save;
    vm.error = null;
    vm.emailSent = false;

    function save() {
        if ($scope.editEmail.$invalid) return;

        if (vm.busy) return;
        const deferred = $q.defer();

        vm.busy = true;

        var newEmail = vm.email;

        apiService
            .forgotPasswordRequest({
                email: newEmail
            })
            .then(function successCallback() {
                vm.emailSent = true;
            })
            .finally(function () {
                vm.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }
}

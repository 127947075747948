import { app } from "../../app.module";
import { FEATURES } from "../../configuration";
import { User } from "../../models/user";
import { IDataContext } from "../../services/dataContext";
import { IDeviceService } from "@/app/services/deviceServiceProvider";
import angular, { ILogService } from "angular";
import { AbsentStudentModel, ReportMultipleAbsencesWriteModel } from "@/app/models/api";
import templateUrl from "./reportMultipleAbsences.html";
import { IApiService } from "../../services/apiServiceProvider";
import { parseDate } from "../../services/dateHelper";
import { getToday, getTimeOptions } from "../../services/dayHelper";
import { EnrolmentStatus } from "../../models/enums";

require("./reportMultipleAbsences.less");

const controllerId = "reportMultipleAbsences";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: ReportMultipleAbsencesComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

const REASON_MAXLENGTH = 89;

interface IReportMultipleAbsencesScope extends angular.IScope {
    submitMultipleAbsencesForm: ng.IFormController;
}

export class ReportMultipleAbsencesComponent {
    absences: {
        id: number | null;
        students: {
            studentId: string;
            name: string;
            schoolName: string;
            centerCode: string;
        }[];
        selectedStudents: {
            studentId: string;
            name: string;
            schoolName: string;
            centerCode: string;
        }[];
        dateFrom: Date;
        allDay: boolean;
        timeFrom: string;
        dateTo: Date;
        timeTo: string;
        reason: string;
    }[];
    timeOptions: Date[];
    today: Date;
    user: User;
    absenceSubmitted: boolean;
    absenceFeatureTurnedOffForAllStudents: boolean;
    studentsWithoutAttendanceFeature: {
        name: string;
        schoolName: string;
    }[];
    isMobileApp: boolean;
    busy: boolean;
    error: boolean;

    constructor(
        deviceService: IDeviceService,
        private apiService: IApiService,
        private dataContext: IDataContext,
        private $scope: IReportMultipleAbsencesScope,
        private $timeout: ng.ITimeoutService,
        private $document: ng.IDocumentService,
        private $q: ng.IQService,
        private $log: ILogService
    ) {
        "ngInject";
        this.studentsWithoutAttendanceFeature = [];
        this.dataContext.getData().then(user => {
            this.user = user;
            this.absenceFeatureTurnedOffForAllStudents = !user.students.some(
                student =>
                    student.features.some(feature => feature.toString() === FEATURES.ATTENDANCE.toString()) &&
                    // FutureStudentNoAbsence - Currently, Absence cannot be recorded for Future Student
                    student.enrolments.some(enrolment => enrolment.enrolmentStatus === EnrolmentStatus.Active)
            );
            this.user.students.map(student => {
                student.enrolments.map(enrolment => {
                    enrolment.features.findIndex(feature => feature === FEATURES.ATTENDANCE.toString()) === -1 &&
                    // FutureStudentNoAbsence - Currently, Absence cannot be recorded for Future Student
                    enrolment.enrolmentStatus !== EnrolmentStatus.Active
                        ? this.studentsWithoutAttendanceFeature.push({ name: student.fullName, schoolName: enrolment.school })
                        : null;
                });
            });
            this.absences = [];
            this.addAbsence();
        });

        this.timeOptions = getTimeOptions();
        this.isMobileApp = deviceService.isMobileApp;
        this.today = getToday();
        $("#studentAndSchool").trigger("focus");
    }

    remove(index: number) {
        this.absences.splice(index, 1);
    }

    addAbsence() {
        var studentList = [];
        this.user.students.map(student => {
            student.enrolments.map(enrolment => {
                enrolment.features.findIndex(feature => feature === FEATURES.ATTENDANCE.toString()) > -1 &&
                // FutureStudentNoAbsence - Currently, Absence cannot be recorded for Future Student
                enrolment.enrolmentStatus === EnrolmentStatus.Active
                    ? studentList.push({
                          name: student.fullName,
                          schoolName: enrolment.school,
                          caption: `${student.fullName} - ${enrolment.school}`,
                          studentId: student.oneSchoolId,
                          centerCode: enrolment.schoolCode
                      })
                    : null;
            });
        });
        this.absences.push({
            students: studentList,
            selectedStudents: [],
            id: null,
            dateFrom: null,
            allDay: false,
            dateTo: null,
            timeFrom: "",
            timeTo: "",
            reason: ""
        });
    }

    reasonCharactersRemaining(reason, hasReachedMaxlength) {
        if (hasReachedMaxlength) {
            return -1;
        }
        var reasonLength = reason ? reason.length : 0;
        return REASON_MAXLENGTH - reasonLength;
    }

    onAllDayChecked(allDay: boolean, index: number) {
        if (allDay && this.absences[index].dateFrom && !this.absences[index].dateTo) {
            const date = parseDate(this.absences[index].dateFrom);

            if (date) {
                this.absences[index].dateTo = date.toDate();
            }
        }

        if (allDay && !this.absences[index].dateFrom && this.absences[index].dateTo) {
            const date = parseDate(this.absences[index].dateTo);

            if (date) {
                this.absences[index].dateFrom = date.toDate();
            }
        }
    }

    submitMultipleAbsences() {
        this.setFormFieldsDirty();

        if (this.busy || !this.$scope.submitMultipleAbsencesForm.$valid || this.absences.some(a => a.selectedStudents.length == 0)) return;

        const deferred = this.$q.defer();

        this.busy = true;
        this.absenceSubmitted = false;
        this.error = false;

        var futureAbsences = this.absences.map(
            absence =>
                ({
                    students: absence.selectedStudents.map(
                        student =>
                            ({
                                eqId: student.studentId,
                                schoolCode: student.centerCode
                            }) as AbsentStudentModel
                    ),
                    allDay: absence.allDay,
                    dateAbsentFrom: absence.dateFrom,
                    dateAbsentTo: absence.dateTo,
                    timeFrom: absence.allDay ? "" : absence.timeFrom,
                    timeTo: absence.allDay ? "" : absence.timeTo,
                    reason: absence.reason
                }) as ReportMultipleAbsencesWriteModel
        );

        this.apiService
            .reportMultipleAbsences(futureAbsences)
            .then(response => {
                this.absenceSubmitted = true;

                this.$timeout(() => {
                    var successDiv = angular.element(document.getElementById("success"));
                    (<any>this.$document).scrollToElement(successDiv, 0, 0);
                }, 100);
            })
            .catch(response => {
                this.error = true;
                this.$log.error("Error reporting absence for multiple student", response);
            })
            .finally(() => {
                this.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    setFormFieldsDirty() {
        for (var i = 0; i < this.absences.length; i++) {
            var dateToField = "dateTo" + i;
            var dateFromField = "dateFrom" + i;
            var timeFromField = "timeFrom" + i;
            var timeToField = "timeTo" + i;
            var reasonField = "reason" + i;

            this.$scope.submitMultipleAbsencesForm[dateToField].$setDirty();
            this.$scope.submitMultipleAbsencesForm[dateFromField].$setDirty();
            this.$scope.submitMultipleAbsencesForm[reasonField].$setDirty();

            if (this.$scope.submitMultipleAbsencesForm[timeFromField]) {
                this.$scope.submitMultipleAbsencesForm[timeFromField].$setDirty();
            }

            if (this.$scope.submitMultipleAbsencesForm[timeToField]) {
                this.$scope.submitMultipleAbsencesForm[timeToField].$setDirty();
            }
        }
    }

    public alignStartAndEndDate(absence: { dateFrom: Date | null; dateTo: Date | null }) {
        try {
            if (absence.dateFrom && absence.dateTo) {
                if (absence.dateFrom > absence.dateTo) {
                    absence.dateTo = absence.dateFrom;
                }
            }
        } catch (err) {}
    }
}

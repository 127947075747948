import { app } from "../app.module";
import { GOOGLE_PLAY_APP_PAGE, APPLE_STORE_APP_PAGE } from "../configuration";

import templateUrl from "./update.html";
import { IDeviceService } from "../services/deviceServiceProvider";
import { IApiService } from "../services/apiServiceProvider";

class updateAppController {
    currentVersion: string;
    targetVersion: string;
    appUrlInTheStore: string;

    constructor($routeParams: angular.route.IRouteParamsService, apiService: IApiService, deviceService: IDeviceService) {
        "ngInject";
        let versionParts = $routeParams.versionInfo.split("|");

        this.currentVersion = versionParts[0];
        this.targetVersion = versionParts[1];

        if (deviceService.isIOs()) {
            this.appUrlInTheStore = APPLE_STORE_APP_PAGE;
        }

        if (deviceService.isAndroid()) {
            this.appUrlInTheStore = GOOGLE_PLAY_APP_PAGE;
        }

        let versionInfo = { currentVersion: this.currentVersion, targetVersion: this.targetVersion };

        apiService.recordMobileAppNeedsUpgrade(versionInfo);
    }
}

app.directive("updateComponent", () => ({
    templateUrl,
    controller: updateAppController,
    controllerAs: "vm",
    scope: true,
    restrict: "E"
}));

import "./vendor";
import { app } from "./app.module";
import "./config.http";
import "./config.routes";
import "./config";
import "./configuration";
import "./services";
import "./models";
import "./layout/shell";
import "./directives";
import "./ie8Polyfills";
import { platformService } from "./services/platformService";

import "../content/site.less";
import { IDataContext } from "./services/dataContext";
import { IDeviceService } from "./services/deviceServiceProvider";
import { IApiService } from "./services/apiServiceProvider";
import { ILocationService, ILogService, route } from "angular";
import { ILocalStorageService } from "angular-local-storage";
import { redirectRoutePathStorageKey } from "./services/pushNotificationsService";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { guid } from "./services/common";
import { ICsrfTokenService } from "./services/csrfTokenService";
import { IRouteService } from "./services/routeService";
import { App } from "@capacitor/app";

// Handle routing errors and success events
app.run(
    (
        $rootScope,
        $route: route.IRouteService,
        $location: ILocationService,
        dataContext: IDataContext,
        $log: ILogService,
        $window,
        $injector,
        appInit,
        routeService: IRouteService,
        csrfTokenService: ICsrfTokenService,
        platformService: platformService,
        deviceService: IDeviceService,
        localStorageService: ILocalStorageService,
        apiService: IApiService
    ) => {
        // Include $route to kick start the router.
        // Include errorReporting to bootstrap error reporting
        if (!deviceService.isMobileApp) {
            dataContext.maybeSignInAdmin();
        }

        $rootScope.currentDate = Date.now();
        $rootScope.userSessionId = guid();

        csrfTokenService
            .ensureCsrfToken()
            .then(function () {
                dataContext
                    .verifyAuthenticated()
                    .then(() => {
                        $log.debug("Authenticated and initialized");
                    })
                    .catch(() => {
                        $log.debug("Not authenticated and initialized");

                        if (!routeService.isAnonymousRoute($location.path())) {
                            dataContext.flagSignedOut(false);
                        }
                    })
                    .finally(function () {
                        // we must resolve, otherwise we're stuck. The routeChangeStart handler will redirect if required.
                        appInit.resolve();

                        $route.reload();
                    });
            })
            .catch(() => {
                // we must resolve, otherwise we're stuck. The routeChangeStart handler will redirect if required.
                appInit.resolve();
                $rootScope.hideFullScreenLoadingPane();

                $log.debug("CRSF Token is invalid");
                $location.path("/error");
            });

        onRouteChangeStartEvent($rootScope, $log, $location, routeService, dataContext, apiService, localStorageService);

        $rootScope.$on("$routeChangeError", function (event, current, previous, rejection) {
            $log.debug("Navigation failed to " + $location.path);
            $log.error(rejection);
        });

        $rootScope.$on("$routeChangeSuccess", (event, current) => {
            $log.debug("Navigated to " + $location.path());

            if (!$location.hash()) {
                $window.scrollTo(0, 0);
            }

            $window.document.title = current && current.$$route && current.$$route.title ? current.$$route.title : "QParents";

            raiseGTagEvent($location, $log);
        });

        $rootScope.showFullScreenLoadingPane = message => {
            $rootScope.initialized = false;
            $rootScope.initializedMessage = message || null;
        };

        $rootScope.hideFullScreenLoadingPane = () => {
            $rootScope.initialized = true;
            $rootScope.initializedMessage = null;
        };

        const appStateChangeHandler = function ({ isActive }) {
            if (isActive) {
                $log.debug("App State Change to Active");
                mobileAppResume($rootScope, csrfTokenService, platformService, apiService, $log, dataContext);
            }
        };

        if (deviceService.isMobileApp) {
            App.addListener("appStateChange", appStateChangeHandler);
        }

        document.addEventListener(
            "gTagEvent",
            function (event) {
                gTagEventHandler($window, $log, $injector, deviceService, event);
            },
            false
        );
    }
);

function mobileAppResume($rootScope, csrfTokenService: ICsrfTokenService, platformService: platformService, apiService: IApiService, $log: ILogService, dataContext: IDataContext) {
    platformService.checkVersion();

    $rootScope.showFullScreenLoadingPane();

    // Refresh the NCSRF
    csrfTokenService
        .ensureCsrfToken()
        .then(() => {
            apiService
                .getAuthenticationCheck()
                .catch(function () {
                    $log.debug("authenticationCheck failed when resuming - redirecting to login");
                    dataContext.flagSignedOut(false);
                })
                .finally(() => {
                    $rootScope.hideFullScreenLoadingPane();
                });
        })
        .catch(function () {
            $log.debug("ensureCsrfToken failed when resuming - redirecting to login");
            $rootScope.hideFullScreenLoadingPane();
            dataContext.flagSignedOut(false);
        });
}

function onRouteChangeStartEvent(
    $rootScope: any,
    $log: ILogService,
    $location: ILocationService,
    routeService: IRouteService,
    dataContext: IDataContext,
    apiService: IApiService,
    localStorageService: ILocalStorageService
) {
    $rootScope.$on("$routeChangeStart", function ensureCanNavigate(event, next, current) {
        $log.debug("Trying to navigate to " + $location.path());

        if (routeService.isAnonymousRoute($location.path())) {
            return;
        }

        if (!dataContext.isAuthenticated()) {
            $log.debug("!isAuthenticated - redirecting to login");
            dataContext.flagSignedOut(false);
            return;
        }

        apiService
            .getAuthenticationCheck()
            .then(() => {
                // redirect if there is path specified in the local storage after succesful logged in
                const redirectRoutePath = localStorageService.get(redirectRoutePathStorageKey);

                if (redirectRoutePath) {
                    localStorageService.set(redirectRoutePathStorageKey, null);
                    $location.path(redirectRoutePath);
                }
            })
            .catch(() => {
                $log.debug("authenticationCheck - redirecting to login");
                dataContext.flagSignedOut(false);
            });
    });
}

function raiseGTagEvent($location: ILocationService, $log: any) {
    var gaPath = $location.path();
    gaPath = gaPath.replace(/\/\d{10}\w/g, ""); // Remove any EQ IDs from querystring when sent to GoogleAnalytics
    $log.debug("Sending Page View Event for " + gaPath);

    const event = new CustomEvent("gTagEvent", {
        bubbles: true,
        detail: {
            path: gaPath
        }
    });

    document.dispatchEvent(event);
}

function gTagEventHandler($window, $log, $injector, deviceService, event) {
    var detail = event.detail;

    if (deviceService.isMobileApp) {
        let SERVER_URL: string = "LOCAL";

        if ($injector.has("SERVER_URL")) {
            const regex = /https?:\/\/([^/:]+)(?::\d+)?/;
            const matchedURL = $injector.get("SERVER_URL").match(regex);
            SERVER_URL = matchedURL ? matchedURL[1] : SERVER_URL;
            $log.debug(`Analytics - Content Group ${SERVER_URL} `);
        }

        const params = {
            screen_class: detail.path ?? "/",
            screen_name: $window.document.title,
            content_group: SERVER_URL
        };

        $log.debug(`Analytics - Screen View to ${params.screen_class} (${params.screen_name})`);

        FirebaseAnalytics.logEvent({
            name: "screen_view",
            params: params
        });
    } else {
        const params = {
            page_location: detail.path ?? "/",
            page_title: $window.document.title
        };

        $log.debug(`Analytics - Page View to ${params.page_location} (${params.page_title})`);
        $window.gtag("event", "page_view", params);
    }
}

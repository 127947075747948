import moment from "moment";
import { app } from "../app.module";
import templateUrl from "./reports.html";
import "./reports.less";
import { FEATURES, ADMIN_VIEW_COOKIES } from "../configuration";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import { IDataContext } from "../services/dataContext";
import { AcademicResultViewModel } from "../models/api";

const controllerId = "reports";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: ReportsComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

class ReportsComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    academicResults: AcademicResultViewModel[];
    currentResult: AcademicResultViewModel;
    hasAcademicResults: boolean;
    hasArchiveReports: boolean;
    isLoading: boolean;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        authorisationService: AuthorisationService,
        private $timeout: ng.ITimeoutService,
        private dataContext: IDataContext
    ) {
        "ngInject";
        this.academicResults = [];
        this.currentResult = null;
        this.hasAcademicResults = false;
        this.hasArchiveReports = false;

        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasFeatureAccess(this, FEATURES.ACADEMIC_REPORTS)) {
                return;
            }

            this.academicResults = this.enrolment.academicResults.sort((a, b) => moment(b.endDate).diff(moment(a.endDate)));

            this.currentResult = this.academicResults[0];

            this.hasAcademicResults = !!this.academicResults.length;
            this.hasArchiveReports = !!this.student.academicYearArchives.length;
        });
    }

    fakeLoading() {
        this.isLoading = true;
        this.$timeout(() => (this.isLoading = false), 300);
    }

    getReportLink() {
        let url = "/api/academic/report" + `?EqId=${this.student.oneSchoolId}&ReportingPeriodId=${this.currentResult.reportingPeriodId}&SchoolCode=${this.currentResult.schoolCode}`;

        if (this.dataContext.isAdminView()) {
            let schoolUserId = sessionStorage.getItem(ADMIN_VIEW_COOKIES.SCHOOL_USER_ID);
            let ppaoId = sessionStorage.getItem(ADMIN_VIEW_COOKIES.PARENT_ID);
            url += `&AdminPPAOId=${ppaoId}&AdminSchoolUserId=${schoolUserId}`;
        }

        return url;
    }

    loadImage(imagePath: string) {
        return require("../../content/img/" + imagePath);
    }
}

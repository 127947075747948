import { app } from "../../app.module";
import { IContextLoader, StudentContext } from "../../services/contextLoader";
import { User } from "../../models/user";
import { Student } from "../../models/student";
import { Enrolment } from "../../models/enrolment";
import templateUrl from "./attendanceWidget.html";
require("./attendanceWidget.less");
const controllerId = "attendanceWidget";

app.directive(controllerId, () => ({
    templateUrl,
    controller: AttendanceWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

class AttendanceWidget implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    showAttendanceRecord: boolean = false;

    constructor($routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader) {
        "ngInject";
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            this.showAttendanceRecord = this.enrolment.attendancePercentageThisCalendarYear != null;
        });
    }
}

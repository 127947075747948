import { app } from "../../app.module";
import { IUrlResolver } from "@/app/services/urlResolver";
import { SESSION_DV_CODE_KEY, TERMS_AND_CONDITION_LABELS } from "../../configuration";

const controllerId = "delegatedViewerInvitation";
import templateUrl from "./invitation.html";
import { ILocationService, ISCEService } from "angular";
import { ILocalStorageService } from "angular-local-storage";
app.controller(controllerId, delegatedViewerInvitation);

app.component(`${controllerId}Component`, {
    templateUrl: templateUrl,
    bindings: {
        invitationContext: "="
    },
    controller: controllerId,
    controllerAs: "vm"
});

function delegatedViewerInvitation($sce: ISCEService, $location: ILocationService, localStorageService: ILocalStorageService, $route, urlResolver: IUrlResolver) {
    "ngInject";
    var vm = this;
    var invitationContext = $route.current.locals.invitationContext;

    var fullName = invitationContext.delegatedViewerFullName;

    vm.busy = false;

    vm.showInvalidCodeError = false;
    vm.showAcceptingOwnInvitationMessageError = false;
    vm.showTermsAndConditions = false;

    vm.pendingTermsAndConditions = [];
    vm.acceptedAllTermsAndConditions = false;
    vm.hasTermsAndConditions = false;

    vm.checkIfAllTermsAndConditionsAccepted = checkIfAllTermsAndConditionsAccepted;
    vm.navigateToNameVerification = navigateToNameVerification;

    initialise();

    function initialise() {
        clearSessionInvitationCode();

        var validCode = invitationContext.validCode;
        vm.showTermsAndConditions = validCode;
        vm.showAcceptingOwnInvitationMessageError = !validCode && invitationContext.acceptingOwnInvitation;
        vm.showInvalidCodeError = !validCode && !invitationContext.acceptingOwnInvitation;

        if (invitationContext.validCode) {
            showAnyPendingTermsAndConditions(invitationContext.pendingTermsAndConditionsKeys);
        }
    }

    function navigateToNameVerification() {
        if (vm.busy) return;
        vm.busy = true;

        localStorageService.set(SESSION_DV_CODE_KEY, {
            sessionId: invitationContext.sessionId,
            name: fullName
        });

        $location.path("/registration/delegated-viewer/verify");
    }

    function showAnyPendingTermsAndConditions(pendingTermsAndConditionsKeys) {
        vm.hasTermsAndConditions = pendingTermsAndConditionsKeys.length > 0;
        vm.acceptedAllTermsAndConditions = !vm.hasTermsAndConditions;
        vm.pendingTermsAndConditions = pendingTermsAndConditionsKeys.map(function (key) {
            return {
                url: $sce.trustAsResourceUrl(urlResolver.resolve("/api/termsandconditions/" + key)),
                checked: false,
                key: key,
                label: getCheckboxLabelForTermsAndCondition(key)
            };
        });
    }

    function getCheckboxLabelForTermsAndCondition(key) {
        var type = key.split("-")[0].toUpperCase();
        return TERMS_AND_CONDITION_LABELS[type];
    }

    function checkIfAllTermsAndConditionsAccepted(pendingTermsAndConditions) {
        vm.acceptedAllTermsAndConditions = pendingTermsAndConditions.every(function (tc) {
            return tc.checked;
        });
    }

    function clearSessionInvitationCode() {
        localStorageService.remove(SESSION_DV_CODE_KEY);
    }
}

import { app as services } from "../app.module";
import { IDeviceService } from "./deviceServiceProvider";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic";
import { ILogService, IPromise, IQService } from "angular";

var serviceId = "nativeSettingsService";
services.factory(serviceId, nativeSettingsService);

export interface INativeSettingsService {
    isRemoteNotificationEnabled(): IPromise<boolean>;
}

// Adding a new native setting access require the capacitor.config.json to be updated for both apps
// See for available module: https://github.com/dpa99c/cordova-diagnostic-plugin#camera-module
// Installation Guide: https://github.com/ionic-team/capacitor/issues/2104
function nativeSettingsService($q: IQService, $log: ILogService, deviceService: IDeviceService): INativeSettingsService {
    "ngInject";

    return { isRemoteNotificationEnabled: isRemoteNotificationEnabled };

    async function isRemoteNotificationEnabled() {
        if (deviceService.isMobileApp) {
            return $q.when(Diagnostic.isRemoteNotificationsEnabled()).then(result => {
                $log.debug("nativeSettingsService - Notification Status ", result);
                return result;
            });
        }

        return $q.resolve<boolean>(false);
    }
}

import angular, { ILogService } from "angular";
import { app } from "../app.module";

export class recaptchaService {
    constructor(private $q: ng.IQService, private $interval: ng.IIntervalService, private $log: ILogService) {
        "ngInject";
    }

    readonly onLoadFunctionName = "__onGRecaptchaScriptLoaded";
    readonly url = "https://www.google.com/recaptcha/api.js";
    initialized = false;
    initializing = false;

    writeScript() {
        var tag = document.createElement("script");
        tag.src = `${this.url}?render=${process.env.RECAPTCHA_API_KEY}&onload=${this.onLoadFunctionName}&render=explicit`;
        window.document.body.appendChild(tag);
    }

    isExecuteAvailable() {
        return angular.isFunction((this.grecaptcha || {}).execute);
    }

    scriptExists() {
        return window.document.querySelector('script[src^="' + this.url + '"]');
    }

    execute(params) {
        const deferred = this.$q.defer();
        if (!this.isInitialized() && !this.isInitializing()) {
            deferred.resolve(undefined);
            return deferred.promise;
        }

        const recaptchaApiKey = process.env.RECAPTCHA_API_KEY;

        if (this.isInitializing()) {
            return deferred.promise.then(function () {
                return this.execute(params);
            });
        }

        this.grecaptcha.ready(() => {
            this.grecaptcha.execute(recaptchaApiKey, params).then(
                token => {
                    this.$log.debug("Acquired recaptcha token: ", token);
                    deferred.resolve(token);
                },
                e => {
                    this.$log.error("ReCaptcha token acquisition failed");
                    this.$log.error(e);
                    deferred.reject();
                }
            );
        });

        return deferred.promise;
    }

    get grecaptcha() {
        return window["grecaptcha"];
    }

    isInitialized() {
        return !!this.initialized;
    }

    isInitializing() {
        return !!this.initializing;
    }

    reset() {
        return this.grecaptcha.reset();
    }

    initialize() {
        const deferred = this.$q.defer();

        setTimeout(() => {
            try {
                if (this.isExecuteAvailable()) {
                    this.initialized = true;
                    this.initializing = false;
                    deferred.resolve();
                } else if (this.scriptExists()) {
                    this.initializing = true;
                    var wait = this.$interval(() => {
                        if (this.isExecuteAvailable()) {
                            this.$interval.cancel(wait);

                            this.initialized = true;
                            this.initializing = false;
                            deferred.resolve();
                        }
                    }, 50);
                } else {
                    this.initializing = true;
                    window[this.onLoadFunctionName] = () => {
                        this.initialized = true;
                        this.initializing = false;
                        deferred.resolve();
                    };

                    this.writeScript();
                }
            } catch (e) {
                deferred.reject(e);
            }
        });

        return deferred.promise;
    }
}
app.service("recaptchaService", recaptchaService);

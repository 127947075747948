import { Preferences } from "@capacitor/preferences";
import { app } from "../app.module";

var serviceId = "preferencesService";

export interface IPreferencesService {
    getIsCredentialSaved(): Promise<boolean>;
    getIsCredentialPrompted(): Promise<boolean>;

    setIsCredentialSaved(value: boolean): Promise<void>;
    setIsCredentialPrompted(value: boolean): Promise<void>;
}
export class PreferencesService implements IPreferencesService {
    isCredentialSavedKey = "isCredentialsSaved";
    isCredentialPromptedKey = "isCredentialsPrompted";

    public getIsCredentialSaved() {
        return Preferences.get({ key: this.isCredentialSavedKey }).then(result => {
            return result?.value === "true" ? true : false;
        });
    }

    public setIsCredentialSaved(value: boolean) {
        return Preferences.set({ key: this.isCredentialSavedKey, value: value ? "true" : "false" });
    }

    public getIsCredentialPrompted() {
        return Preferences.get({ key: this.isCredentialPromptedKey }).then(result => {
            return result?.value === "true" ? true : false;
        });
    }

    public setIsCredentialPrompted(value: boolean) {
        return Preferences.set({ key: this.isCredentialPromptedKey, value: value ? "true" : "false" });
    }
}

app.service(serviceId, PreferencesService);

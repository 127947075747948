import { app } from "../app.module";
import { IDeviceService } from "../services/deviceServiceProvider";
import templateUrl from "./studentHeader.html";
import "./enrolmentStatus";

app.directive("studentHeaderComponent", () => ({
    templateUrl,
    controller: studentHeaderLoad,
    scope: false,
    restrict: "A"
}));

function studentHeaderLoad(deviceService: IDeviceService) {
    "ngInject";

    this.isMobileApp = deviceService.isMobileApp;
}

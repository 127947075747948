import angular from "angular";
import { app } from "../app.module";
import INgModelController = angular.INgModelController;
app.directive("match", function () {
    return {
        require: "ngModel",
        restrict: "A",
        scope: {
            match: "="
        },
        link: function (scope: any, elem, attr, ngModel: INgModelController) {
            scope.$watch(
                function () {
                    return (ngModel.$pristine && angular.isUndefined(ngModel.$modelValue)) || scope.match === ngModel.$modelValue;
                },
                function (currentValue) {
                    ngModel.$setValidity("match", currentValue);
                }
            );
        }
    };
});

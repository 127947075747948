import angular from "angular";
import { Student } from "./student";
import { Enrolment } from "./enrolment";
import {
    UserViewModel,
    AbsenceSummaryViewModel,
    UpcomingEventsSummaryViewModel,
    PaymentsSummaryViewModel,
    AdminViewViewModel,
    AddressViewModel,
    ContactViewModel,
    UpdatedDetailsViewModel,
    SchoolViewModel,
    StudentPendingApprovalViewModel
} from "./api";

interface StudentEnrolment {
    student: Student;
    enrolment: Enrolment;
}

export class User implements UserViewModel {
    absenceSummary: AbsenceSummaryViewModel;
    upcomingEventsSummary: UpcomingEventsSummaryViewModel;
    paymentsSummary: PaymentsSummaryViewModel;
    isAdminView: boolean;
    adminViewDetails: AdminViewViewModel;

    oneSchoolParentId: number;
    firstName: string;
    lastName: string;
    redirectToAddStudentPage: boolean;
    residentialAddress: AddressViewModel;
    postalAddress: AddressViewModel;
    contacts: ContactViewModel[];
    emailAddress: string;
    pendingEmailAddress: string;
    mobileNumber: string;
    updatedDetails: UpdatedDetailsViewModel;
    schools: SchoolViewModel[];
    pendingStudents: StudentPendingApprovalViewModel[];
    pendingDelegatedStudents: StudentPendingApprovalViewModel[];
    pendingTermsAndConditions: string[];
    isPpao: boolean;
    status: string;
    studentPhotosEnabled: boolean;
    studentPhotosTermsAndConditionsKey: string;
    lastLoginTimestamp: string;
    isActive: boolean;

    students: Student[];
    delegatedStudents: Student[];
    allAvailableStudents: Student[];
    numRedactedDelegatedStudents: number;
    numRedactedStudents: number;
    consentRequestsEnabled: boolean;

    constructor(data: UserViewModel, isMobileApp: boolean) {
        angular.extend(this, data);

        this.delegatedStudents = data.delegatedStudents.map(s => new Student(s, true, isMobileApp));
        this.students = data.students.map(s => new Student(s, false, isMobileApp));
        this.allAvailableStudents = this.delegatedStudents.concat(this.students);
    }

    getStudentForId(studentId) {
        return this.allAvailableStudents.find(student => student.oneSchoolId === studentId);
    }

    getEnrolment(studentId: string, centreCode: string): StudentEnrolment {
        const student = this.getStudentForId(studentId);
        const enrolment = student?.enrolments.find(e => e.schoolCode == centreCode);
        if (student != null && enrolment != null) {
            return { student, enrolment };
        }
    }

    hasPendingTermsAndConditions() {
        return this.pendingTermsAndConditions && this.pendingTermsAndConditions.length > 0;
    }

    hasAnyStudents() {
        return this.hasAnyQpaoStudents() || this.hasAnyDvStudents();
    }

    hasAnyQpaoStudents() {
        return (this.students && this.students.length != 0) || (this.pendingStudents && this.pendingStudents.length != 0) || this.numRedactedStudents > 0;
    }

    hasAnyDvStudents() {
        return (this.delegatedStudents && this.delegatedStudents.length != 0) || (this.pendingDelegatedStudents && this.pendingDelegatedStudents.length != 0) || this.numRedactedDelegatedStudents > 0;
    }

    activeStudentsCount() {
        return this.students.length + this.delegatedStudents.length;
    }

    isAccountOwner() {
        return this.isPpao;
    }

    isDelegatedViewer() {
        return !this.isAccountOwner();
    }

    shouldRedirectToAddStudentPage() {
        return this.redirectToAddStudentPage && !this.hasPendingTermsAndConditions();
    }

    cancelRedirectToAddStudentPage() {
        this.redirectToAddStudentPage = false;
    }
}

import { ILocationService, ILogService, IQService } from "angular";
import { app } from "../app.module";
import { IApiService } from "../services/apiServiceProvider";

const controllerId = "changePassword";
import templateUrl from "./changePassword.html";
import { ICommonService } from "../services/common";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: changePassword,
    controllerAs: "vm",
    restrict: "E",
    replace: true
}));

function changePassword(common: ICommonService, $location: ILocationService, $q: IQService, apiService: IApiService, $log: ILogService, $scope) {
    "ngInject";
    var vm = this;

    vm.oldPassword = null;
    vm.newPassword = null;
    vm.newPasswordConfirmation = null;
    vm.error = null;
    vm.save = save;

    common.activateController([], controllerId).then(function () {});

    function save() {
        if ($scope.form.$invalid) return;

        if (vm.busy) return;
        vm.busy = true;
        vm.error = null;

        var deferred = $q.defer();
        apiService
            .changePassword({
                OldPassword: vm.oldPassword,
                NewPassword: vm.newPassword,
                NewPasswordConfirmation: vm.newPasswordConfirmation
            })
            .then(response => {
                const data = response.data;
                if (data.success) {
                    $location.path("/profile").hash("submitted");
                } else {
                    vm.error = data.errorMessage;
                    vm.oldPassword = null;
                }
            })
            .catch(response => {
                vm.error = "Your request could not be updated due to a system failure.";
                $log.error("Error changing the password", response);
            })
            .finally(() => {
                vm.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }
}

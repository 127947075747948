import { app } from "../app.module";
import "./mobile-app-message";
import "./qLearnAppMessage/qLearnAppMessage";
import "../login-form/loginForm";
import templateUrl from "./login.html";

import "./login.less";
import { ConnectionStatus } from "@capacitor/network";
import { INetworkService } from "../services/networkService";
import { IDeviceService } from "../services/deviceServiceProvider";
import { platformService } from "../services/platformService";

app.component("loginComponent", {
    templateUrl: templateUrl,
    controller: login,
    controllerAs: "vm"
});

function login($routeParams: angular.route.IRouteParamsService, platformService: platformService, networkService: INetworkService, deviceService: IDeviceService) {
    "ngInject";
    var vm = this;
    vm.reloadApp = reloadApp;
    vm.onNetworkStatusChanged = onNetworkStatusChangedHandler;
    vm.showEmailValidatedSuccess = false;
    vm.showEmailValidatedError = false;
    vm.isOffline = !networkService.isOnline();
    vm.isMobileApp = deviceService.isMobileApp;

    initialise();

    function initialise() {
        vm.showEmailValidatedSuccess = $routeParams.emailConfirmed === "true";
        vm.showEmailValidatedError = $routeParams.emailConfirmed === "false";

        // On capacitor apps, this will check the API to ensure that App version is compatible
        // It will navigate to an 'upgrade the app' page if not suitable.
        if (vm.isMobileApp) {
            networkService.getNetworkStatus().then(function (status) {
                vm.onNetworkStatusChanged(status);
            });
        }
        if (!vm.isOffline) {
            networkService.onNetworkStatusChanged(onNetworkStatusChangedHandler);
            vm.$onDestroy = function () {
                networkService.removeOnNetworkStatusChanged();
            };
        }
    }

    function onNetworkStatusChangedHandler(status: ConnectionStatus) {
        if (status.connected) {
            networkService.getIsMaintenance().then(isMaintenance => {
                vm.isMaintenance = isMaintenance;
                if (!isMaintenance) {
                    platformService.checkVersion();
                }
            });
        }
    }

    function reloadApp() {
        window.location.reload();
    }
}

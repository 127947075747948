import { app } from "../app.module";
import { IContextLoader, StudentContext } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import { FEATURES } from "../configuration";
import { Formio } from "formiojs";
import uswds from "@formio/uswds";
import { ConsentService } from "../services/consentService";
import { ConsentResponseState } from "../models/enums";
import { ConsentRequest } from "./consentRequestSummary/consentRequestSummary";
import { ConsentRequestViewModel } from "@/app/models/api";

import templateUrl from "./request.html";

const controllerId = "request";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: RequestComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

class RequestComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    email: {
        to: string;
        subject: string;
        body: string;
    };
    isEditable: boolean;
    hasResponse: boolean;
    request: ConsentRequest;
    loadingConsent: boolean;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        authorisationService: AuthorisationService,
        consentService: ConsentService,
        $location: ng.ILocationService
    ) {
        "ngInject";
        this.loadingConsent = true;
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasStudentFeatureAccess(this, FEATURES.CONSENT_REQUESTS)) {
                this.loadingConsent = false;
                return;
            }

            consentService.getConsentRequest(this.student.oneSchoolId, $routeParams.requestId, $routeParams.centreCode).then((data: ConsentRequestViewModel) => {
                this.loadingConsent = false;
                this.email = {
                    to: data.contactEmail,
                    subject: `[Consent change] ${this.student.fullName} | ${data.title}`,
                    body: `Dear ${data.contactName},%0D%0A%0D%0A(Please provide details of the any changes to consent you wish to make)%0D%0A%0D%0A${data.reference ? `Ref: ${data.reference}` : ""}`
                };
                this.hasResponse = data.consentState !== ConsentResponseState.Pending;
                this.request = {
                    id: $routeParams.requestId,
                    title: data.title,
                    state: data.consentState,
                    schoolName: !!this.enrolment ? this.enrolment.school : undefined,
                    withdrawnDate: data.withdrawnDate,
                    dueDate: data.dueDate,
                    responseDate: data.responseDate,
                    studentId: this.student.oneSchoolId,
                    studentFullName: this.student.fullName,
                    schoolCode: !!this.enrolment ? this.enrolment.schoolCode : $routeParams.centreCode
                };
                this.isEditable = data.consentState !== ConsentResponseState.Pending && data.consentState !== ConsentResponseState.Expired && data.consentState !== ConsentResponseState.Withdrawn;
                // @ts-ignore
                Formio.license = true;
                Formio.use(uswds);
                Formio.createForm(document.getElementById("consent-formio"), data.formTemplate, {
                    readOnly: data.consentState !== ConsentResponseState.Pending,
                    disableAlerts: true,
                    noAlerts: true,
                    highlightErrors: false
                }).then(form => {
                    form.submission = {
                        data: {
                            ...data.formData,
                            context: "preview",
                            ...(this.hasResponse
                                ? {}
                                : {
                                      parentName: `${this.user.firstName} ${this.user.lastName}`,
                                      studentName: `${this.student.firstName()} ${this.student.lastName}`,
                                      parentPhoneNumber: this.user.mobileNumber,
                                      parentEmailAddress: this.user.emailAddress
                                  })
                        }
                    };

                    form.nosubmit = true;

                    form.on("submitError", () => {
                        focusFirstError();
                    });

                    form.on("submit", submission => {
                        consentService.sendConsentResponse(submission, this.request.id, this.student.oneSchoolId, this.request.schoolCode).then(_ => {
                            const state = ConsentResponseState[submission.data.consentState as string];
                            const responseDate = new Date(Date.now()).toISOString();

                            form.emit("submitDone", submission);

                            consentService.getConsentRequestSummary(this.user).then(summary => {
                                summary.pendingRequestsCount -= 1;
                                summary.consentRequests = summary.consentRequests.map(cr =>
                                    cr.id === this.request.id && cr.eqId === this.student.oneSchoolId
                                        ? {
                                              ...cr,
                                              state,
                                              responseDate
                                          }
                                        : cr
                                );

                                $location.path(`/consent-request/${this.student.oneSchoolId}/${this.request.id}/${this.request.schoolCode}/submitted`).replace();
                            });
                        });
                    });
                });
            });
        });
    }
}

// .is-invalid is used by formio
const errorClass = "is-invalid";

const focusFirstError = () => {
    const firstError = document.querySelector(`.${errorClass} input, input.${errorClass}, textarea.${errorClass}`) as HTMLElement | null;

    if (firstError) firstError.focus();
};

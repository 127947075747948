import { app } from "../../../app.module";
import templateUrl from "./one-place-section.html";
import "./one-place-section.less";

app.component("onePlaceSectionComponent", {
    templateUrl: templateUrl,
    controller: onePlaceSection,
    controllerAs: "vm"
});

function onePlaceSection() {
    var vm = this;

    vm.loadImage = function(imagePath) {
        return require('../../../../content/img/' + imagePath);
    };
}
import angular, { IQService } from "angular";
import { app } from "./app.module";
import { ICheckerService } from "./tools/outageChecker";

import "./accessibility/accessibility";
import "./addStudent/addStudent";

import "./alert/alert";

import "./attendance/attendance";
import "./attendance/reportAbsence";
import "./attendance/reportReason";
import "./behaviour/behaviour";

import "./enrolmentHistory/enrolmentHistory";
import "./error/error";
import "./featureUnavailable/featureUnavailable";

import "./home";
import "./landingPage";
import "./login/login";
import "./login/forgotPassword/forgotPassword";
import "./login/forgotPassword/changeForgottenPassword";
import "./login/forgotPassword/confirmResetPassword";

import "./outage/outage";

import "./payment/payment";
import "./payment/processPayment";
import "./photo/schoolPhoto";
import "./photo/upload";

import "./privacy/privacy";
import "./profile/profile";
import "./profile/changePassword";
import "./profile/email";
import "./profile/mobile";
import "./profile/other";
import "./registration";
import "./reports/reports";
import "./reports/archive";
import "./consent/consent";
import "./consent/request";
import "./consent/responseSubmitted";
import "./student/student";
import "./student/studentPhoto";
import "./studentDetails/studentDetails";
import "./studentDetails/studentMedicalConditions";
import "./studentNotFound/studentNotFound";
import "./termsAndConditions/termsAndConditions";
import "./timetable/timetable";
import "./update/update";
import "./viewer/addViewer";
import "./viewer/viewer";

import "./admin-view/admin-view-logout";
import "./admin-view/admin-view-context-error";
import "./admin-view/admin-view-inactive-error";

import "./attendance/reportMultipleAbsences/reportMultipleAbsences";
import "./parentCentric/combinedUpcomingEvents/combinedUpcomingEvents";
import "./parentCentric/combinedPayments/combinedPayments";
import "./parentCentric/combinedConsentRequests/combinedConsentRequests";

// Collect the routes
app.constant("routes", getRoutes());

app.factory("appInit", appInitFactory);
function appInitFactory($q: IQService) {
    "ngInject";
    return $q.defer();
}

// Configure the routes and route resolvers
app.config(routeConfigurator);

function routeConfigurator($routeProvider: angular.route.IRouteProvider, routes: IRoute[]) {
    "ngInject";
    routes.forEach(function (r) {
        $routeProvider.when(r.url, extend(r.config));
    });
    $routeProvider.otherwise({ redirectTo: "/" });

    function extend(routeConfig: IRouteConfig) {
        let resolve = routeConfig.resolve || {};
        resolve = angular.extend(resolve, {
            init: [
                "appInit",
                function (appInit) {
                    return appInit.promise;
                }
            ]
        });
        resolve = angular.extend(resolve, {
            __outageChecker: [
                "outageChecker",
                "appInit",
                function (outageChecker: ICheckerService, appInit) {
                    appInit.promise.then(function () {
                        outageChecker.check(routeConfig.modules, "outage");
                    });
                }
            ],

            __systemAlertChecker: [
                "systemAlertChecker",
                "appInit",
                function (systemAlertChecker: ICheckerService, appInit) {
                    appInit.promise.then(function () {
                        systemAlertChecker.check(routeConfig.modules, "alert");
                    });
                }
            ]
        });

        routeConfig.resolve = resolve;

        return routeConfig;
    }
}

export interface IRoute {
    url: string;
    config: IRouteConfig;
}
export interface IRouteConfig {
    key: string;
    template: string;
    title: string;
    isAnonymous?: boolean;
    resolve?: any;
    hideHeader?: boolean;
    controller?: string | ((...args: any[]) => any);
    controllerAs?: string;
    modules?: OutageModules[];
    params?: any;
}

export type OutageModules = "System" | "Student" | "Timetable" | "Attendance" | "Behaviour" | "Payments" | "Reports" | "EnrolmentHistory";

// Define the routes
function getRoutes(): IRoute[] {
    return [
        {
            url: "/",
            config: {
                key: "home",
                template: "<home-component></home-component>",
                title: "QParents",
                modules: ["System"],
                resolve: {
                    checkForRedirectsBeforeLoadingView: function (homeInitialise) {
                        "ngInject";
                        return homeInitialise();
                    }
                }
            }
        },
        {
            url: "/landing",
            config: {
                key: "landing",
                template: "<landing-page-component></landing-page-component>",
                title: "Landing Page",
                isAnonymous: true,
                modules: ["System"]
            }
        },
        {
            url: "/login",
            config: {
                key: "login",
                template: "<login-component></login-component>",
                title: "Login",
                isAnonymous: true,
                modules: ["System"]
            }
        },
        {
            url: "/login/forgotPassword",
            config: {
                key: "forgotPassword",
                template: "<forgot-password-component></forgot-password-component>",
                title: "Forgotten Password",
                isAnonymous: true,
                modules: ["System"]
            }
        },
        {
            url: "/login/changeForgottenPassword",
            config: {
                key: "changeForgottenPassword",
                template: "<change-forgotten-password-component></change-forgotten-password-component>",
                title: "Change Forgotten Password",
                isAnonymous: true,
                modules: ["System"]
            }
        },
        {
            url: "/login/confirmResetPassword",
            config: {
                key: "confirmResetPassword",
                template: "<confirm-reset-password-component></confirm-reset-password-component>",
                title: "Reset Password",
                isAnonymous: true,
                modules: ["System"]
            }
        },
        {
            url: "/profile",
            config: {
                key: "profile",
                template: "<profile-component></profile-component>",
                title: "My Profile",
                modules: ["System"]
            }
        },
        {
            url: "/profile/other",
            config: {
                key: "profile-other",
                template: "<other-component></other-component>",
                title: "My Profile - Edit Other",
                modules: ["System"]
            }
        },
        {
            url: "/profile/mobile",
            config: {
                key: "profile-mobile",
                template: "<mobile-component></mobile-component>",
                title: "My Profile - Edit mobile number",
                modules: ["System"]
            }
        },
        {
            url: "/profile/email",
            config: {
                key: "profile-email",
                template: "<email-component></email-component>",
                title: "My Profile - Edit email address",
                modules: ["System"]
            }
        },
        {
            url: "/profile/password",
            config: {
                key: "profile-password",
                template: "<change-password-component></change-password-component>",
                title: "My Profile - Change password",
                modules: ["System"]
            }
        },
        {
            url: "/student/:studentId/:centreCode",
            config: {
                key: "student",
                template: "<student-component></student-component>",
                title: "Student",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/addStudent",
            config: {
                key: "addStudent",
                template: "<add-student-component></add-student-component>",
                title: "Add Student",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/enrolmentHistory/:studentId/:centreCode",
            config: {
                key: "enrolmentHistory",
                template: "<enrolment-history-component></enrolment-history-component>",
                title: "Enrolment History",
                modules: ["System", "Student", "EnrolmentHistory"]
            }
        },
        {
            url: "/consent/:studentId/:centreCode",
            config: {
                key: "consentRequests",
                template: "<consent-component></consent-component>",
                title: "Consent Requests",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/consent-request/:studentId/:requestId/:centreCode",
            config: {
                key: "consentRequest",
                template: "<request-component></request-component>",
                title: "Consent Request",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/consent-request/:studentId/:requestId/:centreCode/submitted",
            config: {
                key: "consentResponseSubmitted",
                template: "<response-submitted-component></response-submitted-component>",
                title: "Consent Response Submitted",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/attendance/:studentId/:centreCode",
            config: {
                key: "attendance",
                template: "<attendance-component></attendance-component>",
                title: "Attendance",
                modules: ["System", "Student", "Attendance"]
            }
        },
        {
            url: "/attendance/:studentId/:centreCode/report-absence",
            config: {
                key: "reportAbsence",
                template: "<report-absence-component></report-absence-component>",
                title: "Report Absence",
                modules: ["System", "Student", "Attendance"]
            }
        },
        {
            url: "/attendance/:studentId/:centreCode/report-reason/:absenceId",
            config: {
                key: "attendance-report-reason",
                template: "<report-reason-component></report-reason-component>",
                title: "Attendance - Submit Reason",
                modules: ["System", "Student", "Attendance"]
            }
        },
        {
            url: "/addViewer/:studentId/:centreCode",
            config: {
                key: "addViewer",
                template: "<add-viewer-component></add-viewer-component>",
                title: "Add Viewer",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/viewer/:viewerId/:studentId/:centreCode",
            config: {
                key: "viewer",
                template: "<viewer-component></viewer-component>",
                title: "Viewer",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/featureUnavailable",
            config: {
                key: "featureUnavailable",
                template: "<feature-unavailable-component></feature-unavailable-component>",
                title: "Feature Unavailable"
            }
        },
        {
            url: "/studentDetails/:studentId/:centreCode",
            config: {
                key: "studentDetails",
                template: "<student-details-component></student-details-component>",
                title: "Student Details",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/studentPhoto/:studentId/:centreCode",
            config: {
                key: "studentPhoto",
                template: "<student-photo-component></student-photo-component>",
                title: "Student Photo",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/studentPhoto/school/:studentId/:centreCode",
            config: {
                key: "student-photo-school",
                template: "<school-photo-component></school-photo-component>",
                title: "Student Photo",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/studentPhoto/upload/:studentId/:centreCode",
            config: {
                key: "student-photo-upload",
                template: "<upload-component></upload-component>",
                title: "Upload Photo",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/behaviour/:studentId/:centreCode",
            config: {
                key: "behaviour",
                template: "<behaviour-component></behaviour-component>",
                title: "Behaviour Reports",
                modules: ["System", "Student", "Behaviour"]
            }
        },
        {
            url: "/payment/:studentId/:centreCode",
            config: {
                key: "payment",
                template: "<payment-component></payment-component>",
                title: "Payments",
                modules: ["System", "Student", "Payments"]
            }
        },
        {
            url: "/payment/process/:studentId/:centreCode",
            config: {
                key: "payment",
                template: "<process-payment-component></process-payment-component>",
                title: "Process payment",
                modules: ["System", "Student", "Payments"]
            }
        },
        {
            url: "/studentMedicalConditions/:studentId/:centreCode",
            config: {
                key: "studentMedicalConditions",
                template: "<student-medical-conditions-component></student-medical-conditions-component>",
                title: "Student Medical Conditions",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/studentNotFound",
            config: {
                key: "studentNotFound",
                template: "<student-not-found-component></student-not-found-component>",
                title: "Student not found"
            }
        },
        {
            url: "/timetable/:studentId/:centreCode",
            config: {
                key: "timetable",
                template: "<timetable-component></timetable-component>",
                title: "Timetable",
                modules: ["System", "Student", "Timetable"]
            }
        },
        {
            url: "/reports/:studentId/:centreCode",
            config: {
                key: "reports",
                template: "<reports-component></reports-component>",
                title: "Academic Reports",
                modules: ["System", "Student", "Reports"]
            }
        },
        {
            url: "/reports-archive/:studentId/:centreCode",
            config: {
                key: "reports-archive",
                template: "<archive-component></archive-component>",
                title: "Academic Reports Archive",
                modules: ["System", "Student", "Reports"]
            }
        },
        {
            url: "/error",
            config: {
                key: "error",
                template: "<error-component></error-component>",
                title: "Error",
                isAnonymous: true
            }
        },
        {
            url: "/termsAndConditions",
            config: {
                key: "temsAndConditions",
                template: "<terms-and-conditions-component></terms-and-conditions-component>",
                title: "Terms and Conditions",
                hideHeader: true,
                modules: ["System"]
            }
        },
        {
            url: "/accessibility",
            config: {
                key: "accessibility",
                template: "<accessibility-component></accessibility-component>",
                title: "Accessibility",
                isAnonymous: true
            }
        },
        {
            url: "/privacy",
            config: {
                key: "privacy",
                template: "<privacy-component></privacy-component>",
                title: "privacy",
                isAnonymous: true
            }
        },
        {
            url: "/registration/account-owner/invitation/:code?",
            config: {
                key: "registration-account-owner-invitation-with-code",
                template: "<account-owner-invitation-component></account-owner-invitation-component>",
                title: "Enter your invitation code",
                isAnonymous: true,
                modules: ["System"]
            }
        },
        {
            url: "/registration/account-owner/verify",
            config: {
                key: "registration-account-owner-verify",
                template: "<account-owner-verify-component></account-owner-verify-component>",
                title: "Verify your identity",
                isAnonymous: true,
                controller: "accountOwnerVerify",
                controllerAs: "vm",
                modules: ["System"],
                resolve: {
                    dvsContext: function (accountOwnerVerifyInitialise) {
                        "ngInject";
                        return accountOwnerVerifyInitialise();
                    }
                }
            }
        },
        {
            url: "/registration/account-owner/register",
            config: {
                key: "registration-account-owner-register",
                template: "<account-owner-register-component></account-owner-register-component>",
                title: "Register",
                isAnonymous: true,
                controller: "accountOwnerRegister",
                controllerAs: "vm",
                modules: ["System"],
                resolve: {
                    registrationContext: function (accountOwnerRegisterInitialise) {
                        "ngInject";
                        return accountOwnerRegisterInitialise();
                    }
                }
            }
        },
        {
            url: "/registration/delegated-viewer/invitation/:code",
            config: {
                key: "registration-delegated-viewer-invitation",
                template: "<delegated-viewer-invitation-component></delegated-viewer-invitation-component>",
                title: "Invitation",
                isAnonymous: true,
                controller: "delegatedViewerInvitation",
                controllerAs: "vm",
                modules: ["System"],
                resolve: {
                    invitationContext: function (delegatedViewerInvitationInitialise) {
                        "ngInject";
                        return delegatedViewerInvitationInitialise();
                    }
                }
            }
        },
        {
            url: "/registration/delegated-viewer/verify",
            config: {
                key: "registration-delegated-viewer-verify",
                template: "<delegated-viewer-verify-component></delegated-viewer-verify-component>",
                title: "Invitation",
                modules: ["System"],
                isAnonymous: true
            }
        },
        {
            url: "/registration/delegated-viewer/register",
            config: {
                key: "registration-delegated-viewer-register",
                template: "<delegated-viewer-register-component></delegated-viewer-register-component>",
                title: "Register",
                modules: ["System"],
                isAnonymous: true
            }
        },
        {
            url: "/update/:versionInfo",
            config: {
                key: "update",
                template: "<update-component></update-component>",
                title: "Update",
                isAnonymous: true
            }
        },
        {
            url: "/outage",
            config: {
                key: "outage",
                template: "<outage-component></outage-component>",
                title: "Outage",
                isAnonymous: true
            }
        },
        {
            url: "/notice",
            config: {
                key: "notice",
                template: "<system-alert-component></system-alert-component>",
                title: "Notice",
                isAnonymous: true
            }
        },
        {
            url: "/admin-view/logout",
            config: {
                key: "admin-view-logout",
                template: "<admin-view-logout-component></admin-view-logout-component>",
                title: "Logout",
                isAnonymous: true,
                modules: ["System"]
            }
        },
        {
            url: "/admin-view/context-error",
            config: {
                key: "admin-view-context-error",
                template: "<admin-view-context-error-component></admin-view-context-error-component>",
                title: "Error",
                isAnonymous: true
            }
        },
        {
            url: "/admin-view/inactive-error",
            config: {
                key: "admin-view-inactive-error",
                template: "<admin-view-inactive-error-component></admin-view-inactive-error-component>",
                title: "Error",
                isAnonymous: true
            }
        },
        {
            url: "/attendance/report-absences",
            config: {
                key: "reportMultipleAbsences",
                template: "<report-multiple-absences-component></report-multiple-absences-component>",
                title: "Report Multiple Absences",
                modules: ["System", "Student", "Attendance"]
            }
        },
        {
            url: "/combined-upcoming-events",
            config: {
                key: "combinedUpcomingEvents",
                template: "<combined-upcoming-events-component></combined-upcoming-events-componet>",
                title: "Combined Upcoming Events",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/parent-centric/combined-consent-requests",
            config: {
                key: "combinedConsentRequests",
                template: "<combined-consent-requests-component></combined-consent-requests-component>",
                title: "Combined Consent Requests",
                modules: ["System", "Student"]
            }
        },
        {
            url: "/parent-centric/combined-payments",
            config: {
                key: "combinedPayments",
                template: "<combined-payments-component></combined-payments-componet>",
                title: "Combined Payments",
                modules: ["System", "Student"]
            }
        }
    ];
}

import { app as services } from "../app.module";
import { IRoute } from "../config.routes";

var serviceId = "routeService";
services.factory(serviceId, routeService);

export interface IRouteService {
    isAnonymousRoute: (path: string) => boolean;
    shouldHideHeader: (path: string) => boolean;
    isOnLoginPage: (path: string) => boolean;
}

function routeService(routes: IRoute[]): IRouteService {
    "ngInject";
    return {
        isAnonymousRoute: isAnonymousPath,
        shouldHideHeader: shouldHideHeader,
        isOnLoginPage: isOnLoginPage
    };

    function doesFilteredRoutesContainPath(filter: (route: IRoute) => boolean, path: string) {
        var pathWithoutQueryString = path.split("?")[0];

        var filteredRoutes = routes.filter(filter);
        var urls = filteredRoutes.map(function (route) {
            return route.url;
        });

        var match = urls.some(function (route) {
            if (route === pathWithoutQueryString) return true;

            // handle case where
            // route = /registration/delegated-viewer/invitation/:code
            // pathWithoutQueryString = /registration/delegated-viewer/invitation/1

            var colonPosition = route.indexOf(":");
            if (colonPosition > 0) {
                var routeWithoutParam = route.substring(0, colonPosition - 1);
                var pathWithoutParam = pathWithoutQueryString.substring(0, pathWithoutQueryString.lastIndexOf("/"));

                return routeWithoutParam === pathWithoutParam;
            }
        });

        return match;
    }

    function isAnonymousPath(path: string) {
        return doesFilteredRoutesContainPath(function (route: IRoute) {
            return route.config.isAnonymous;
        }, path);
    }

    function shouldHideHeader(path: string) {
        return doesFilteredRoutesContainPath(function (route: IRoute) {
            return route.config.isAnonymous || route.config.hideHeader;
        }, path);
    }

    function isOnLoginPage(path: string) {
        return doesFilteredRoutesContainPath(function (route: IRoute) {
            return route.config.key === "login";
        }, path);
    }
}

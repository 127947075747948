import { app } from "../app.module";
import { FEATURES } from "../configuration";
import { User } from "../models/user";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import templateUrl from "./behaviour.html";
const controllerId = "behaviour";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: BehaviourComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

class BehaviourComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    constructor($routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader, authorisationService: AuthorisationService) {
        "ngInject";
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasFeatureAccess(this, FEATURES.BEHAVIOUR)) return;
        });
    }

    loadImage(imagePath: string) {
        return require("../../content/img/" + imagePath);
    }
}

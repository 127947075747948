import { app } from "../app.module";
import { ILocationService } from "angular";
import { ILocalStorageService } from "angular-local-storage";
import { redirectRoutePathStorageKey } from "./pushNotificationsService";
import { IDeviceService } from "./deviceServiceProvider";

export class AdminViewRedirectService {
    constructor(
        private isAdminViewDomain: boolean,
        private $location: ILocationService,
        private localStorageService: ILocalStorageService,
        private deviceService: IDeviceService
    ) {
        "ngInject";
    }

    landingPageRedirect() {
        if (this.isAdminViewDomain) {
            this.$location.path("/admin-view/context-error");
        } else if (this.localStorageService.get(redirectRoutePathStorageKey) || this.deviceService.isMobileApp) {
            this.$location.path("/login");
        } else {
            this.$location.path("/landing");
        }
    }
}

app.service("adminViewRedirectService", AdminViewRedirectService);

import { app } from "../app.module";
import { User } from "../models/user";
import { IDataContext } from "../services/dataContext";
import { IDeviceService } from "../services/deviceServiceProvider";
import { INativeSettingsLauncherService } from "../services/nativeSettingsLauncherService";

const controllerId = "profile";
import templateUrl from "./profile.html";
import "./biometrics";
import "./push-notification-settings";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: ProfileComponent,
    controllerAs: "vm",
    restrict: "E",
    replace: true
}));

type ParentViewInfo =
    | { isRedacted: true }
    | {
          emailAddress: string;
          pendingEmailAddress: string;
          mobileNumber: string;
      };

class ProfileComponent {
    emailWasConfirmed: boolean;
    isMobileApp: boolean;
    parent: ParentViewInfo;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        private $location: ng.ILocationService,
        private dataContext: IDataContext,
        private deviceService: IDeviceService,
        private nativeSettingsLauncherService: INativeSettingsLauncherService
    ) {
        "ngInject";
        this.emailWasConfirmed = $routeParams.emailConfirmed === "true";
        this.isMobileApp = deviceService.isMobileApp;

        this.dataContext.getData().then(user => {
            if (this.isUnauthorised(user)) return;

            if (this.dataContext.isAdminView()) {
                this.parent = {
                    isRedacted: true
                };
                return;
            }

            this.parent = {
                emailAddress: user.emailAddress,
                mobileNumber: user.mobileNumber,
                pendingEmailAddress: user.pendingEmailAddress
            };

            if (this.emailWasConfirmed === true && this.parent.pendingEmailAddress) {
                this.parent.emailAddress = this.parent.pendingEmailAddress;
                this.parent.pendingEmailAddress = null;
            }
        });
    }

    isUnauthorised(user: User) {
        if (!user.isActive) {
            this.$location.path("/featureUnavailable");
            return true;
        }

        return false;
    }
}

import { app } from "../app.module";
import "../admin-view/adminViewDomainFactory";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { IDataContext } from "../services/dataContext";
import { AuthorisationService } from "../services/authorisationService";
import { AdminViewRedirectService } from "../services/adminViewRedirectService";
import { ADMIN_VIEW_COOKIES } from "../configuration";
import { AdminViewViewModel } from "../models/api";
import { IDeviceService } from "../services/deviceServiceProvider";
import { IApiService } from "../services/apiServiceProvider";
import { IAssetService } from "../services/assetService";
import { IPushNotificationsRegistrationService } from "../services/pushNotificationsRegistrationService";
import { IRouteService } from "../services/routeService";
import { IUrlResolver } from "../services/urlResolver";

var template = require("./shell.html");
app.directive("shellComponent", () => ({
    restrict: "A",
    templateUrl: template,
    controller: ShellComponent,
    controllerAs: "vm",
    scope: true
}));

interface IEnrolmentEntry {
    student: Student;
    enrolment: Enrolment;
}

class ShellComponent {
    isAuthenticated: boolean;
    firstName: string;
    lastName: string;
    isPpao: boolean;
    isActive: boolean;
    hasStudents: boolean;
    enrolmentEntries: IEnrolmentEntry[];
    hasMultipleEnrolments: boolean;
    showHeader: boolean;
    selectedEnrolment: IEnrolmentEntry;
    isAdminView: boolean;
    adminViewDetails: AdminViewViewModel;
    showSignInLink: boolean;

    constructor(
        private dataContext: IDataContext,
        private $log: ng.ILogService,
        private $location: ng.ILocationService,
        private routeService: IRouteService,
        private authorisationService: AuthorisationService,
        private adminViewRedirectService: AdminViewRedirectService,
        private deviceService: IDeviceService,
        private $rootScope: any & { busy: boolean },
        private apiService: IApiService,
        private pushNotificationsRegistrationService: IPushNotificationsRegistrationService,
        private assetService: IAssetService,
        private urlResolver: IUrlResolver,
        $route
    ) {
        "ngInject";
        this.firstName = "";
        this.lastName = "";
        this.enrolmentEntries = null;
        this.selectedEnrolment = null;

        this.updateShowHeader();
        this.updateShowSignInLink();

        $rootScope.$on("$routeChangeSuccess", (event, current) => {
            this.updateShowHeader();
            this.updateShowSignInLink();

            if (dataContext.isAdminView()) {
                try {
                    var studentId = $route.current.params["studentId"];
                    var title = $route.current.$$route.title;
                    var path = $location.path();
                    this.createAdminAuditLog(studentId, title, path, true);
                } catch (error) {
                    console.debug("Error creating log", error);
                }
            } else {
                // Clear the selected enrolment if user navigate outside the student path
                const selectedStudentPath = this.getCurrentSelectedStudentPath();
                if (!selectedStudentPath) {
                    this.selectedEnrolment = null;
                } else {
                    this.selectedEnrolment = this.getMatchedEnrolmentEntry(selectedStudentPath);
                }
            }
        });

        $rootScope.$on("cfpLoadingBar:started", () => {
            $rootScope.busy = true;
            $("body").css("cursor", "progress");
        });

        $rootScope.$on("cfpLoadingBar:completed", () => {
            $rootScope.busy = false;
            $("body").css("cursor", "default");
        });

        dataContext.getData().then(user => {
            this.onUserRefreshed(null, user);
        });

        dataContext.onUserSignedIn((ev, user) => this.onUserRefreshed(ev, user, true));
        dataContext.onUserSignedOut((ev, user) => this.onUserSignedOut());
        dataContext.onDataRefreshed((ev, user) => this.onUserRefreshed(ev, user));
    }

    canAccess(enrolment: Enrolment) {
        return !this.dataContext.isAdminView() || !this.adminViewDetails || enrolment.schoolCode == this.adminViewDetails.centreCode.toString();
    }

    onUserRefreshed(e, user: User, isSignedInEvent: boolean = false) {
        this.isAuthenticated = this.dataContext.isAuthenticated();

        if (user) {
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.isPpao = user.isPpao;
            this.isActive = user.isActive;
            this.hasStudents = user.hasAnyStudents();
            this.isAdminView = user.isAdminView;
            this.adminViewDetails = user.adminViewDetails;
            this.enrolmentEntries = user.allAvailableStudents
                .map(s =>
                    s.enrolments.map(e => ({
                        displayName: `${s.displayName()} - ${e.school}`,
                        student: s,
                        enrolment: e
                    }))
                )
                .reduce((acc, curr) => acc.concat(curr), [] as IEnrolmentEntry[]);

            const selectedStudentPath = this.getCurrentSelectedStudentPath();
            if (!selectedStudentPath) {
                this.selectedEnrolment = null;
            } else {
                this.selectedEnrolment = this.getMatchedEnrolmentEntry(selectedStudentPath);
            }
        }

        if (isSignedInEvent) {
            this.pushNotificationsRegistrationService.registerToken();
        }
    }

    private getMatchedEnrolmentEntry(selectedStudentPath: { oneSchoolId: string; schoolCode: string; path: string }) {
        const matchedEnrolmentEntries = this.enrolmentEntries.filter(a => a.student.oneSchoolId === selectedStudentPath.oneSchoolId && a.enrolment.schoolCode == selectedStudentPath.schoolCode);
        if (matchedEnrolmentEntries.length > 0) {
            return matchedEnrolmentEntries[0];
        }

        return null;
    }

    logout() {
        this.$rootScope.showFullScreenLoadingPane("Please wait while logging out...");

        this.isAuthenticated = false;

        this.pushNotificationsRegistrationService.unregisterToken().then(() => {
            this.authorisationService.logout().finally(() => {
                this.$rootScope.hideFullScreenLoadingPane();
            });
        });
    }

    redirectToStudent(selectedEnrolment: IEnrolmentEntry) {
        this.$log.debug("Click", selectedEnrolment);
        let redirectPath = "/";

        if (selectedEnrolment) {
            redirectPath = `/student/${selectedEnrolment.student.oneSchoolId}/${selectedEnrolment.enrolment.schoolCode}`;
        }

        this.$log.debug(`Redirecting to ${redirectPath}`);
        this.$location.path(redirectPath);
    }

    getCurrentSelectedStudentPath() {
        const currentPath = this.$location.path();
        const pathSegments = currentPath.split("/");
        let currentOneSchoolId: string;
        let currentSchoolCode: string;

        // Handle the current path: /student/OneSchoolId/SchoolCode
        const isSelectedStudentPath =
            pathSegments.length > 2 &&
            this.enrolmentEntries?.some(function (enrolmentEntry) {
                currentOneSchoolId = pathSegments[pathSegments.length - 2];
                currentSchoolCode = pathSegments[pathSegments.length - 1];
                return enrolmentEntry.student.oneSchoolId === currentOneSchoolId && enrolmentEntry.enrolment.schoolCode === currentSchoolCode;
            });
        if (isSelectedStudentPath) {
            const currentPath = pathSegments[pathSegments.length - 3];

            return {
                oneSchoolId: currentOneSchoolId,
                schoolCode: currentSchoolCode,
                path: currentPath
            };
        }

        return null;
    }

    onUserSignedOut() {
        this.isAuthenticated = false;

        this.$log.debug("onUserSignedOut - redirecting to login");
        this.adminViewRedirectService.landingPageRedirect();
    }

    updateShowHeader() {
        this.$log.debug("Update show header");
        this.showHeader = !this.routeService.shouldHideHeader(this.$location.path());
    }

    updateShowSignInLink() {
        this.showSignInLink = !this.isAuthenticated && !this.routeService.isOnLoginPage(this.$location.path());
    }

    schoolUserAccessingAdminView() {
        // if school user, loggedinUsername and schoolUsername is the same. Its different if its a corporate user impersonating a school user
        return this.adminViewDetails.schoolUsername.toUpperCase() === this.adminViewDetails.loggedInUsername.toUpperCase();
    }

    openHelpInSystemBrowser($event: Event) {
        if (!this.deviceService.isMobileApp) return; // just use the href
        $event.preventDefault();
        this.assetService.mobileOpenFile(this.urlResolver.resolve("#/help"), false);
    }

    createAdminAuditLog(studentId: string, routeTitle: string, routePath: string, isAdminView: boolean) {
        var schoolUserId = sessionStorage.getItem(ADMIN_VIEW_COOKIES.SCHOOL_USER_ID);
        var ppaoId = sessionStorage.getItem(ADMIN_VIEW_COOKIES.PARENT_ID);
        var centreCode = sessionStorage.getItem(ADMIN_VIEW_COOKIES.CENTRE_CODE);

        const auditRecord = {
            isAdminView: isAdminView,
            page: `${routeTitle} - (${routePath})`,
            schoolUserId: schoolUserId,
            schoolUsername: this.adminViewDetails.schoolUsername,
            ppaoId: ppaoId,
            centreCode: centreCode,
            eqIds: []
        };

        if (studentId) {
            auditRecord.eqIds.push(studentId);

            this.apiService.recordAudit(auditRecord);
        } else {
            this.dataContext
                .getData()
                .then(user => user.students.forEach(s => auditRecord.eqIds.push(s.oneSchoolId)))
                .finally(() => this.apiService.recordAudit(auditRecord));
        }
    }
}

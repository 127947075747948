import { app } from "../app.module";
import INgModelController = angular.INgModelController;
app.directive("password", function () {
    // - A minimum of 8 alpha / numeric characters
    // - Contain at least 1 special character ('!', '?', '@', '#', '$', '%', '^', '&', '*', '-', '+', '=', ',', ':', ';', '.')

    var SPECIAL_CHARS = "!?@#$%^&*\\-+=:;.,"; // '-' needs to be escaped, hence '\-'
    var REG_EXP = new RegExp(
        "" +
            "^.*" +
            "(?=.{8,})" + // minimum of 8 characters
            "(?=.*[" +
            SPECIAL_CHARS +
            "])" + // at least one special character
            ".*$"
    );

            function isValidPassword(password) {
                // password must be minimum of 8 characters including one
                // special character
                if (password === null || password === undefined) return false;
                if (password.indexOf(' ') >= 0) return false;

        return REG_EXP.test(password);
    }

    return {
        require: "ngModel",
        link: function (scope, elem, attr, ngModel: INgModelController) {
            ngModel.$parsers.unshift(checkForValidPassword);
            ngModel.$formatters.unshift(checkForValidPassword);

            function checkForValidPassword(password) {
                ngModel.$setValidity("password", isValidPassword(password));
                return password;
            }
        }
    };
});

import { app } from "../app.module";
import { FEATURES } from "../configuration";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import templateUrl from "./enrolmentHistory.html";

const controllerId = "enrolmentHistory";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: EnrolmentHistoryComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

class EnrolmentHistoryComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    constructor($routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader, authorisationService: AuthorisationService) {
        "ngInject";
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasStudentFeatureAccess(this, FEATURES.ENROLMENT_HISTORY)) return;
        });
    }
}

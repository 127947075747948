import { ILocationService } from "angular";
import { app } from "../app.module";
import { REDIRECT_PHOTO_COOKIE_NAME } from "../configuration";
import { IDataContext } from "../services/dataContext";

app.factory("homeInitialise", homeInitialise);

function homeInitialise($location: ILocationService, $cookies: ng.cookies.ICookiesService, dataContext: IDataContext) {
    "ngInject";

    function doesCookieExist(cookieName) {
        var cookieValue = $cookies.get(cookieName);
        return cookieValue !== undefined;
    }

    function shouldRedirectToPhotoPreview() {
        return doesCookieExist(REDIRECT_PHOTO_COOKIE_NAME);
    }

    function redirectToPhotoPreview() {
        // ie8 doesn't support 3xx redirects to locations with fragments (it strips the fragment)
        // so we had to set a cookie server side which we check client side and do the redirection. *sigh*
        // see: http://blogs.msdn.com/b/ieinternals/archive/2011/05/17/url-fragments-and-redirects-anchor-hash-missing.aspx
        // and LegacyUploadPhoto in StudentPhotoModule

        var studentIdToRedirectToPhotoPreviewPageFor = $cookies.get(REDIRECT_PHOTO_COOKIE_NAME);
        $cookies.remove(REDIRECT_PHOTO_COOKIE_NAME);

        $location.path("studentPhoto/legacyupload/" + studentIdToRedirectToPhotoPreviewPageFor).search("preview", "true");
    }

    function shouldRedirectToAddStudent(user) {
        return user.shouldRedirectToAddStudentPage();
    }

    function redirectToAddStudent() {
        $location.path("addStudent");
    }

    function checkIfRedirectRequired(user) {
        if (shouldRedirectToPhotoPreview()) {
            redirectToPhotoPreview();
        } else if (shouldRedirectToAddStudent(user)) {
            redirectToAddStudent();
        }
    }

    return function () {
        return dataContext.getData().then(checkIfRedirectRequired);
    };
}

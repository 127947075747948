import { app } from "../../../app.module";
import templateUrl from "./children-grow-section.html";
import "./children-grow-section.less";

app.component("childrenGrowSectionComponent", {
    templateUrl: templateUrl,
    controller: childrenGrowSection,
    controllerAs: "vm"
});

function childrenGrowSection() {
    var vm = this;

    vm.loadImage = function(imagePath) {
        return require('../../../../content/img/' + imagePath);
    };
}
import angular from "angular";

export const app = angular.module("app", [
    // Angular modules
    "ngAnimate",
    "ngRoute",
    "ngSanitize",
    "ngCookies",
    "ngResource",

    // 3rd Party Modules
    "LocalStorageModule",
    "chieffancypants.loadingBar",
    "toaster",
    "highcharts-ng",
    "angularPikaday",

    "duScroll",
    "ui.mask",
    "toastr",

    "isteven-multi-select"
]);

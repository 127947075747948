import { app } from "../../../app.module";
import templateUrl from "./safe-private-section.html";
import "./safe-private-section.less";

app.component("safePrivateSectionComponent", {
    templateUrl: templateUrl,
    controller: safePrivateSection,
    controllerAs: "vm"
});

function safePrivateSection() {
    var vm = this;

    vm.loadImage = function(imagePath) {
        return require('../../../../content/img/' + imagePath);
    };
}
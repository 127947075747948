import "./upcomingEventsWidget.less";
import moment from "moment";

import { IContextLoader, StudentContext } from "../../services/contextLoader";
import { getDayOfWeekLabel } from "../../services/dayHelper";

import { Enrolment } from "../../models/enrolment";
import { Student } from "../../models/student";
import { User } from "../../models/user";
import { app } from "../../app.module";
import { enumerateDaysBetweenDates } from "../../tools/dates";
import { EventViewModel } from "@/app/models/api";

import templateUrl from "./upcomingEventsWidget.html";
import { formatDateTimeToIcsDate, formatDateToShortMonthDay } from "../../services/dateHelper";
import { getFormattedTimeRange } from "../../services/timeHelper";
import { ICalendarData, ICalendarService } from "../../services/calendarService";

const controllerId = "upcomingEventsWidget";
app.directive(controllerId, () => ({
    templateUrl,
    controller: UpcomingEventsWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

var eventTypes = {
    Exam: 0,
    Assessment: 1,
    Excursion: 2,
    School: 3
};

var eventTypeNames = {
    0: "Exams",
    1: "Assessments",
    2: "Excursions",
    3: "School events"
};

var eventTypePrefixes = {
    0: "E",
    1: "A",
    2: "X",
    3: "V"
};

class UpcomingEventsWidget implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    upcomingEvents: EventViewModel[];
    allEvents: EventViewModel[];
    eventsByDay: { date: moment.Moment; agenda: EventViewModel[] }[];
    filterOptions: { value: number; text: any; active: boolean }[];
    isWeekend: boolean;

    constructor(private $routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader, private calendarService: ICalendarService) {
        "ngInject";
        this.upcomingEvents = null;
        this.isWeekend = false;

        this.filterOptions = Object.keys(eventTypes)
            .map(x => x)
            .map(x => eventTypes[x])
            .map(function (value) {
                return {
                    value: value,
                    text: eventTypeNames[value],
                    active: true
                };
            });

        this.eventsByDay = [];
        this.allEvents = [];

        contextLoader.load(this, $routeParams, controllerId).then(() => {
            this.allEvents = this.enrolment.upcomingEvents;
            this.refilterEvents();
        });
    }

    toggleFilter(filter) {
        filter.active = !filter.active;
        this.refilterEvents();
    }

    formatDate(date) {
        return formatDateToShortMonthDay(date);
    }

    getDayLabel(day) {
        return getDayOfWeekLabel(day);
    }

    getTimeLabel(upcomingEvent: EventViewModel) {
        return getFormattedTimeRange({ date: upcomingEvent.startDate, time: upcomingEvent.startTime }, { date: upcomingEvent.endDate, time: upcomingEvent.endTime });
    }

    getEventTypePrefix(eventType) {
        return eventTypePrefixes[eventType];
    }

    refilterEvents() {
        var startDatesOfEvents = this.allEvents.map(e => moment(new Date(e.startDate)));
        var endDatesOfEvents = this.allEvents.map(e => moment(new Date(e.endDate)));
        var minStartDateOfEvent = moment.min(startDatesOfEvents);
        var maxEndDateOfEvent = moment.max(endDatesOfEvents);

        var allEventsDateRange = enumerateDaysBetweenDates(minStartDateOfEvent.toDate(), maxEndDateOfEvent.toDate());

        var filteredEvents = this.allEvents.filter(ev => this.isInActiveFilter(ev));
        this.eventsByDay = allEventsDateRange
            .map(key => {
                var items = filteredEvents.filter(e => moment(key).isBetween(moment(e.startDate).startOf("day"), moment(e.endDate).endOf("day"), undefined, "[]"));
                return {
                    date: moment(key),
                    agenda: items.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))
                };
            })
            .filter(e => e.agenda.length > 0);

        this.eventsByDay = this.eventsByDay.sort((a, b) => moment(a.date).diff(moment(b.date)));
    }

    isInActiveFilter(upcomingEvent: EventViewModel) {
        return (
            this.filterOptions.filter(fo => {
                return fo.active && fo.value === upcomingEvent.eventType;
            }).length > 0
        );
    }

    loadImage(imagePath: string) {
        return require("../../../content/img/" + imagePath);
    }

    openEventInCalendar(event: EventViewModel) {
        const icsEvent = this.createIcsEvent(event);

        const icsEventBlob = this.calendarService.createIcsFile([icsEvent]);

        if (icsEventBlob) {
            this.calendarService.openIcsFile(icsEventBlob);
        }
    }

    createIcsEvent(event: EventViewModel): ICalendarData {
        var studentId = this.$routeParams.studentId;
        var centreCode = this.$routeParams.centreCode;

        return {
            id: `${studentId}||${centreCode}||${event.eventType}||${formatDateTimeToIcsDate(event.startDate, event.startTime)}||${formatDateTimeToIcsDate(event.endDate, event.endTime)}`,
            startDate: event.startDate,
            startTime: event.startTime,
            endDate: event.endDate,
            endTime: event.endTime,
            summary: `${studentId} - ${event.displayName}`,
            description: event.detail
        };
    }

    loadCalendarIcon() {
        return require("../../../content/icons/calendar-outline.svg");
    }
}

import { ILogService, IPromise } from "angular";
import { app as services } from "../app.module";
import { IApiService } from "./apiServiceProvider";
import { recaptchaService } from "./recaptchaService";
import { LoginRequest } from "../models/api";
import { IDeviceService } from "./deviceServiceProvider";
import { NativeBiometric, BiometryType } from "@capgo/capacitor-native-biometric";
import angular from "angular";
import { IPreferencesService } from "./preferencesService";
import { IDataContext } from "./dataContext";

const serviceId = "loginService";
services.factory(serviceId, loginService);

export interface ILoginService {
    login(loginRequest: LoginRequest): IPromise<any>;
    loadBiometricCredential(): IPromise<UserLogin>;
    saveBiometricCredential(userlogin: UserLogin): IPromise<boolean>;
    deleteBiometricCredential(): IPromise<boolean>;
    checkIfBiometricAvailable(): IPromise<CheckAvailableBiometricResult>;
    checkIfMatchingCredentialExist(userlogin: UserLogin): IPromise<CheckMatchResult>;
}

export interface UserLogin {
    username: string;
    password: string;
}

export interface CheckMatchResult {
    usernameMatch: boolean;
    passwordMatch: boolean;
}

export interface CheckAvailableBiometricResult {
    isAvailable: boolean;
    description?: string;
}

function loginService(
    $q: ng.IQService,
    $log: ILogService,
    $injector,
    recaptchaService: recaptchaService,
    apiService: IApiService,
    deviceService: IDeviceService,
    preferencesService: IPreferencesService,
    dataContext: IDataContext
): ILoginService {
    "ngInject";

    let SERVER_URL: string;

    if ($injector.has("SERVER_URL")) {
        SERVER_URL = $injector.get("SERVER_URL");
    }

    return {
        login: login,
        loadBiometricCredential: loadBiometricCredential,
        saveBiometricCredential: saveBiometricCredential,
        deleteBiometricCredential: deleteBiometricCredential,
        checkIfBiometricAvailable: checkIfBiometricAvailable,
        checkIfMatchingCredentialExist: checkIfMatchingCredentialExist
    };

    function login(request: LoginRequest) {
        const deferred = $q.defer<any>();
        const loginRequest = request;

        checkRecaptcha()
            .then(recaptchaToken => {
                // this will be undefined when the reCaptchaService is not initialised
                // We should not care about undefined recaptcha as the login is not risky (passed the VIP 2FA already)
                loginRequest.captchaToken = recaptchaToken;

                $log.debug("loginService - Logging in");

                dataContext.preSignIn();
                apiService
                    .login(loginRequest)
                    .then(response => {
                        if (response.status !== 200) {
                            $log.error("loginService - Login failed");
                            deferred.reject(response);
                            return;
                        } else {
                            $log.debug("loginService - Login success");
                        }

                        $log.debug("loginService - Retrieving user");
                        dataContext.flagSignedIn(loginRequest.email);
                        dataContext
                            .verifyAuthenticated()
                            .then(() => {
                                $log.debug("loginService - Retrieving user succeed");
                                deferred.resolve();
                            })
                            .catch(response => {
                                deferred.reject();
                                $log.error("loginService - Retrieving user failed", angular.toJson(response));
                            });
                    })
                    .catch(response => {
                        $log.error("loginService - Login failed", angular.toJson(response));
                        deferred.reject(response);
                    });
            })
            .catch(response => {
                $log.error("loginService - Checking Recaptcha failed", angular.toJson(response));
                deferred.reject(response);
            });

        return deferred.promise;
    }

    function saveBiometricCredential(userlogin: UserLogin) {
        const deferred = $q.defer<boolean>();

        checkIfBiometricAvailable().then(result => {
            if (result.isAvailable) {
                $log.debug("loginService - Saving credentials");
                // Save user's credentials
                $q.when(
                    NativeBiometric.setCredentials({
                        username: userlogin.username,
                        password: userlogin.password,
                        server: SERVER_URL
                    })
                )
                    .then(() => {
                        $log.debug("loginService - Saving Preferences");
                        preferencesService
                            .setIsCredentialSaved(true)
                            .then(() => {
                                $log.debug("loginService - Preference Updated");
                            })
                            .finally(() => {
                                deferred.resolve(true);
                            });
                    })
                    .catch(response => {
                        $log.error("loginService - Saving Credentials failed", response);
                        deferred.reject(false);
                    });
            } else {
                deferred.resolve(false);
            }
        });
        return deferred.promise;
    }

    function loadBiometricCredential() {
        const deferred = $q.defer<UserLogin>();

        checkIfBiometricAvailable().then(result => {
            if (!result.isAvailable) {
                deferred.resolve(null);
                return;
            }
            $log.debug("loginService - Check Preferences");

            preferencesService
                .getIsCredentialPrompted()
                .then(isCredentialPrompted => {
                    if (!isCredentialPrompted) {
                        deferred.resolve(null);
                        return;
                    }
                    preferencesService
                        .getIsCredentialSaved()
                        .then(isCredentialSaved => {
                            if (!isCredentialSaved) {
                                deferred.resolve(null);
                                return;
                            }
                            verifyBiometricCredential(deferred);
                        })
                        .catch(response => {
                            $log.error("loginService - Getting Credentials failed", response);
                            deferred.reject(null);
                        });
                })
                .catch(response => {
                    $log.error("loginService - Unexpected error when loading the preferences", angular.toJson(response));
                    deferred.reject(null);
                });
        });
        return deferred.promise;
    }

    function verifyBiometricCredential(deferred: angular.IDeferred<UserLogin>) {
        $q.when(
            NativeBiometric.verifyIdentity({
                reason: "To securely access your account",
                title: "Account Login",
                subtitle: "Quick and secure access",
                description: "For a seamless login experience, please verify your identity using biometrics."
            })
        )
            .then(() => {
                $log.debug("loginService - Loading credential");
                // Save user's credentials
                $q.when(
                    NativeBiometric.getCredentials({
                        server: SERVER_URL
                    })
                )
                    .then(result => {
                        deferred.resolve({
                            username: result.username,
                            password: result.password
                        });
                    })
                    .catch(response => {
                        $log.error("loginService - Unexpected error when retrieving credential info", angular.toJson(response));
                        deferred.reject(null);
                    });
            })
            .catch(response => {
                $log.error("loginService - Unexpected error when verifying credential", angular.toJson(response));
                deferred.reject(null);
            });
    }

    function deleteBiometricCredential() {
        const deferred = $q.defer<boolean>();

        checkIfBiometricAvailable().then(result => {
            if (result.isAvailable) {
                $log.debug("loginService - Deleting credential");
                // Save user's credentials
                $q.when(
                    NativeBiometric.deleteCredentials({
                        server: SERVER_URL
                    })
                )
                    .then(() => {
                        $log.debug("loginService - Updating Preference");
                        preferencesService
                            .setIsCredentialSaved(false)
                            .then(() => {
                                $log.debug("loginService - Preference Updated");
                            })
                            .finally(() => {
                                deferred.resolve(true);
                            });
                    })
                    .catch(response => {
                        $log.error("loginService - Unexpected error when deleting credential info", angular.toJson(response));
                        deferred.reject(false);
                    });
            } else {
                deferred.resolve(false);
            }
        });
        return deferred.promise;
    }

    function checkIfMatchingCredentialExist(userlogin: UserLogin) {
        const deferred = $q.defer<CheckMatchResult>();

        checkIfBiometricAvailable()
            .then(result => {
                if (result.isAvailable) {
                    $log.debug("loginService - Checking matching credential");
                    $q.when(
                        NativeBiometric.getCredentials({
                            server: SERVER_URL
                        })
                    )
                        .then(result => {
                            const checkMatchResult: CheckMatchResult = {
                                usernameMatch: result.username === userlogin.username,
                                passwordMatch: result.password === userlogin.password
                            };

                            deferred.resolve(checkMatchResult);
                        })
                        .catch(response => {
                            $log.error("loginService - Unexpected error when retrieving credential info", angular.toJson(response));
                            deferred.reject(null);
                        });
                } else {
                    deferred.resolve(null);
                }
            })
            .catch(response => {
                $log.error("loginService - Checking Biometric failed", response);
                deferred.reject(null);
            });
        return deferred.promise;
    }

    function checkIfBiometricAvailable() {
        const deferred = $q.defer<CheckAvailableBiometricResult>();

        if (deviceService.isMobileApp) {
            $q.when(NativeBiometric.isAvailable())
                .then(result => {
                    if (result.isAvailable) {
                        $log.debug(`loginService - Biometrics is available for ${result.biometryType}`);
                        deferred.resolve({ isAvailable: true, description: getBiometryTypeDescription(result.biometryType) });
                    } else {
                        $log.debug(`loginService - Biometrics is unavailable (${result.errorCode})`);
                        deferred.resolve({ isAvailable: false });
                    }
                })
                .catch(response => {
                    $log.error("loginService - Unexpected error when retrieving biometrics availability", angular.toJson(response));
                    deferred.resolve({ isAvailable: false });
                });
        } else {
            // Currently Biometrics only supported on the native device
            deferred.resolve({ isAvailable: false });
        }

        return deferred.promise;
    }

    function getBiometryTypeDescription(biometryType: BiometryType) {
        switch (biometryType) {
            case BiometryType.FACE_AUTHENTICATION:
            case BiometryType.FACE_ID: {
                return "Face ID";
            }
            case BiometryType.IRIS_AUTHENTICATION: {
                return "Iris ID";
            }
            case BiometryType.FINGERPRINT: {
                return "Fingerprint ID";
            }
            case BiometryType.TOUCH_ID: {
                return "Touch ID";
            }
            default: {
                return "Fingerprint/Touch ID";
            }
        }
    }

    function checkRecaptcha() {
        const deferred = $q.defer<any>();

        $log.debug("loginService - Executing Check Recaptcha");

        recaptchaService
            .execute({ action: "login" })
            .then(recaptchaToken => {
                deferred.resolve(recaptchaToken);
                $log.debug("loginService - Check Recaptcha Executed");
            })
            .catch(response => {
                deferred.reject(response);
                $log.error("loginService - Check Recaptcha Failed", angular.toJson(response));
            });

        return deferred.promise;
    }
}

import { ILocalStorageService } from "angular-local-storage";
import { app as services } from "../app.module";
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from "@capacitor/push-notifications";
import { IDeviceService } from "./deviceServiceProvider";

const serviceId = "pushNotificationsService";

const pushNotificationTokenStorageKey = "PushNotificationToken";
export const redirectRoutePathStorageKey = "RedirectRoutePath";

export const pushNotificationRegisteredEvent = "pushNotificationsRegistered";
export const pushNotificationRegistrationFailedEvent = "pushNotificationsRegistrationFailed";
export const pushNotificationReceivedEvent = "pushNotificationReceived";
export const pushNotificationActionedEvent = "pushNotificationActioned";

export interface IPushNotificationsService {
    getToken(): string | null;
    getIsRegistered(): boolean;
    register(): Promise<void>;
}

function pushNotificationsService(
    $rootScope: angular.IRootScopeService,
    $log: angular.ILogService,
    deviceService: IDeviceService,
    localStorageService: ILocalStorageService
): IPushNotificationsService {
    "ngInject";

    registerPushNotificationAction();

    return {
        getToken: getToken,
        getIsRegistered: getIsRegistered,
        register: register
    };

    function getToken(): string | null {
        return localStorageService.get(pushNotificationTokenStorageKey);
    }

    function getIsRegistered(): boolean {
        return getToken() !== null;
    }

    async function register(): Promise<void> {
        if (deviceService.isMobileApp) {
            $log.debug("PushNotificationsService - Registering device for push notification on native platform.");
            // Request Permission to use the Push Notifications
            // iOS will prompt the user
            // Android will grant by default for Android 12 or less
            await requestPushNotificationsPermission();
        }
    }

    function registerPushNotificationAction() {
        if (deviceService.isMobileApp) {
            PushNotifications.addListener("pushNotificationReceived", notification => onPushNotificationReceived(notification));

            // notification tap by the user
            PushNotifications.addListener("pushNotificationActionPerformed", actionPerformed => onPushNotificationActioned(actionPerformed));
        }
    }

    function onPushNotificationActioned(actionPerformed: ActionPerformed) {
        $log.debug(`PushNotificationsService - Push notification action performed with ${JSON.stringify(actionPerformed)}.`);

        if (actionPerformed.actionId === "tap") {
            const actionData = actionPerformed.notification.data;
            $log.debug(`PushNotificationsService - Push notification action redirect to ${actionData.routePath}.`);
            // Store the route for redirection
            localStorageService.set(redirectRoutePathStorageKey, actionData.routePath);
            window.location.reload();
        }

        $rootScope.$broadcast(pushNotificationActionedEvent, actionPerformed);
    }

    function onPushNotificationReceived(notification: PushNotificationSchema) {
        $log.debug(`PushNotificationsService - Push notification received with ${JSON.stringify(notification)}`);

        $rootScope.$broadcast(pushNotificationReceivedEvent, notification);
    }

    async function requestPushNotificationsPermission() {
        $log.debug("PushNotificationsService - Request permissions to receive push notification.");

        try {
            let pushNotificationRequestPermissionResult = await PushNotifications.checkPermissions();
            if (pushNotificationRequestPermissionResult.receive === "prompt") {
                pushNotificationRequestPermissionResult = await PushNotifications.requestPermissions();
            }

            if (pushNotificationRequestPermissionResult.receive === "granted") {
                // Register Push Notification Events
                await PushNotifications.register();

                await registerPushNotificationRegistrationAction();
            } else {
                $log.debug(`PushNotificationsService - The permission to receive push notification unsuccessful with ${pushNotificationRequestPermissionResult.receive}`);
            }
        } catch (error) {
            $log.debug(`PushNotificationsService - The permission to receive push notification failed due to ${JSON.stringify(error)}`);
            throw error;
        }
    }

    async function registerPushNotificationRegistrationAction() {
        // Successful Registration
        await PushNotifications.addListener("registration", token => onPushNotificationsRegistered(token));

        // Failed Registration
        await PushNotifications.addListener("registrationError", error => onPushNotificationsRegistrationFailed(error));
    }

    function onPushNotificationsRegistered(token: Token) {
        localStorageService.set(pushNotificationTokenStorageKey, token.value);

        $log.debug(`PushNotificationsService - Push registration succeeded with token: ${token.value}`);

        $rootScope.$broadcast(pushNotificationRegisteredEvent, token);
    }

    function onPushNotificationsRegistrationFailed(error: any) {
        localStorageService.set(pushNotificationTokenStorageKey, null);

        $log.debug(`PushNotificationsService - Push registration failed: ${JSON.stringify(error)}`);

        $rootScope.$broadcast(pushNotificationRegistrationFailedEvent, error);
    }
}
services.factory(serviceId, pushNotificationsService);

import { app } from "../../app.module";
import { SESSION_AO_CODE_KEY } from "../../configuration";
import { IAssociateAdditionalRegistration } from "../../services/dataContext";

const controllerId = "accountOwnerVerify";
import templateUrl from "./verify.html";

app.controller(controllerId, accountOwnerVerify);

app.component(`${controllerId}Component`, {
    templateUrl: templateUrl,
    controller: controllerId,
    controllerAs: "vm",
    bindings: {
        dvsContext: "="
    }
});

function accountOwnerVerify($location, $sce, $route, localStorageService, dataContext) {
    "ngInject";
    var vm = this;

    vm.isThisYou = null;
    vm.hasExistingLogin = null;

    vm.busy = false;

    vm.dvs = $route.current.locals.dvsContext;
    vm.dvsUrl = null;

    vm.cancel = cancel;
    vm.dvsSubmit = dvsSubmit;
    vm.onLogin = onLogin;

    vm.associateAdditionalRegistrationResponse = null;

    initialise();

    function initialise() {
        vm.dvsUrl = $sce.trustAsResourceUrl(vm.dvs.dvsUrl);
    }

    function cancel() {
        localStorageService.remove(SESSION_AO_CODE_KEY);
        $location.path("/");
    }

    function dvsSubmit() {
        if (vm.busy) return;
        vm.busy = true;

        document.forms["dvsForm"].submit();
    }

    function onLogin() {
        if (vm.busy) return;

        vm.busy = true;
        vm.hasAssociateRegError = false;
        vm.associateRegError = null;

        dataContext.associateAdditionalRegistration(getSessionToken()).then(
            function (data: IAssociateAdditionalRegistration) {
                vm.busy = false;
                vm.associateAdditionalRegistrationResponse = data;
                vm.associateRegError = data.errorMessage;

                if (data.valid) {
                    $location.path("/addStudent");
                } else if (data.userNotAccountOwner) {
                    dvsSubmit();
                }
            },
            function (data) {
                vm.associateAdditionalRegistrationResponse = data;
                vm.busy = false;
            }
        );
    }

    function getSessionToken() {
        return localStorageService.get(SESSION_AO_CODE_KEY);
    }
}

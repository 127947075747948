import { app } from "../app.module";

import templateUrl from "./mobile-app-message.html";
import "./mobile-app-message.less";

app.directive("mobileAppMessage", function () {
    return {
        restrict: "E",
        templateUrl,
        scope: true
    };
});

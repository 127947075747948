import { app } from "../../app.module";
import { StudentContext, IContextLoader } from "../../services/contextLoader";
import { User } from "../../models/user";
import { Student } from "../../models/student";
import { Enrolment } from "../../models/enrolment";
const templateUrl = require("./paymentWidget.html");
require("./paymentWidget.less");
const controllerId = "paymentWidget";
app.directive(controllerId, () => ({
    templateUrl,
    controller: PaymentWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

class PaymentWidget implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    numberOfDuePayments: number;
    outstandingInvoicesTotal: number;
    hasOutstandingInvoices: boolean;

    constructor($routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader) {
        "ngInject";
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            this.numberOfDuePayments = this.enrolment.getNumberOfOutstandingInvoices();

            this.outstandingInvoicesTotal = this.enrolment.getOutstandingInvoicesTotal();
            this.hasOutstandingInvoices = this.outstandingInvoicesTotal !== 0;
        });
    }

    getPaymentIconClass() {
        return this.hasOutstandingInvoices ? "fa-exclamation-triangle" : "fa-check-square";
    }
}

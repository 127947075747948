import { app } from "../app.module";
import { IDeviceService } from "../services/deviceServiceProvider";
import { IAssetService } from "../services/assetService";

import templateUrl from "./error.html";
import { IUrlResolver } from "../services/urlResolver";

app.directive("errorComponent", () => ({
    templateUrl,
    restrict: "E",
    controller: error,
    controllerAs: "vm",
    scope: true,
    replace: true
}));

function error(deviceService: IDeviceService, assetService: IAssetService, urlResolver: IUrlResolver) {
    "ngInject";
    var vm = this;
    vm.openHelpInSystemBrowser = openHelpInSystemBrowser;

    function openHelpInSystemBrowser($event: Event) {
        if (!deviceService.isMobileApp) return; // just use the href
        $event.preventDefault();
        assetService.mobileOpenFile(urlResolver.resolve("#/help"), false);
    }
}

import { app } from "../app.module";
import { IDeviceService } from "../services/deviceServiceProvider";
import { INativeSettingsLauncherService } from "../services/nativeSettingsLauncherService";

import templateUrl from "./push-notification-settings.html";
import "./push-notification-settings.less";
import { INativeSettingsService } from "../services/nativeSettingsService";

const controllerId = "pushNotificationSettings";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: PushNotificationsComponent,
    controllerAs: "vm",
    restrict: "E",
    replace: true
}));

class PushNotificationsComponent {
    busy: boolean;
    error: boolean;
    isFeatureAvailable: boolean;
    isPushNotificationEnabled: boolean;
    pushNotificationSetting: { receiveNewConsentRequestNotification: boolean };

    constructor(
        deviceService: IDeviceService,
        private nativeSettingsService: INativeSettingsService,
        private nativeSettingsLauncherService: INativeSettingsLauncherService
    ) {
        "ngInject";

        this.isFeatureAvailable = deviceService.isMobileApp;
        this.nativeSettingsService.isRemoteNotificationEnabled().then(result => {
            this.isPushNotificationEnabled = result;
        });

        this.pushNotificationSetting = {
            receiveNewConsentRequestNotification: true
        };
    }

    openPushNotificationSettings() {
        this.nativeSettingsLauncherService.openPushNotificationSettings().then(result => {
            return result;
        });
    }
}

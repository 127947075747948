import { app } from "../app.module";
import { FEATURES } from "../configuration";
import { Enrolment } from "../models/enrolment";
import { Student } from "../models/student";
import { User } from "../models/user";
import { AuthorisationService } from "../services/authorisationService";
import { IContextLoader, StudentContext } from "../services/contextLoader";
import angular, { ILogService } from "angular";
import { ReportAbsenceWriteModel } from "@/app/models/api";
import templateUrl from "./reportAbsence.html";
import { IDeviceService } from "../services/deviceServiceProvider";
import { IApiService } from "../services/apiServiceProvider";
import { parseDate } from "../services/dateHelper";
import { getToday, getTimeOptions } from "../services/dayHelper";
import { EnrolmentStatus } from "../models/enums";

const controllerId = "reportAbsence";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: ReportAbsenceComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

const REASON_MAXLENGTH = 89;

interface IReportAbsenceScope extends angular.IScope {
    submitAbsenceForm: angular.IFormController;
}

class ReportAbsenceComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    isMobileApp: boolean;
    today: Date;
    timeOptions: Date[];
    reason: string;
    reasonCharactersRemaining: number;
    allDay: boolean;
    dateFrom: Date;
    dateTo: Date;
    timeFrom: string;
    timeTo: string;
    absenceSubmitted: boolean;
    busy: boolean;
    error: boolean;
    isFeatureAvailable: boolean;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        authorisationService: AuthorisationService,
        deviceService: IDeviceService,
        private apiService: IApiService,
        private $location: angular.ILocationService,
        private $timeout: angular.ITimeoutService,
        private $document: angular.IDocumentService,
        private $scope: IReportAbsenceScope,
        private $q: angular.IQService,
        private $log: ILogService
    ) {
        "ngInject";

        this.isFeatureAvailable = true;
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasFeatureAccess(this, FEATURES.ATTENDANCE) || this.isDelegatedViewer()) {
                this.isFeatureAvailable = false;
                return;
            }

            // FutureStudentNoAbsence - Currently, Absence cannot be recorded for Future Student
            if (this.enrolment?.enrolmentStatus !== EnrolmentStatus.Active) {
                this.isFeatureAvailable = false;
                return;
            }

            this.today = getToday();
            this.timeOptions = getTimeOptions();
            this.onReasonChange(false);
        });

        this.isMobileApp = deviceService.isMobileApp;
        $("#dateFrom").trigger("focus");
    }

    onReasonChange(hasReachedMaxlength) {
        if (hasReachedMaxlength) {
            this.reasonCharactersRemaining = -1;
        } else {
            var reasonLength = this.reason ? this.reason.length : 0;
            this.reasonCharactersRemaining = REASON_MAXLENGTH - reasonLength;
        }
    }

    isDelegatedViewer() {
        if (!this.student.isUserPPAOForStudent) {
            var newPath = "/featureUnavailable";
            this.$location.path(newPath);
            return true;
        }
        return false;
    }

    onAllDayChecked(isAllDay) {
        if (isAllDay && this.dateFrom && !this.dateTo) {
            const date = parseDate(this.dateFrom);

            if (date) {
                this.dateTo = date.toDate();
            }
        } else if (isAllDay && !this.dateFrom && this.dateTo) {
            const date = parseDate(this.dateTo);

            if (date) {
                this.dateFrom = date.toDate();
            }
        }
        if (!isAllDay && this.timeFrom && this.timeTo) {
            this.$scope.submitAbsenceForm.timeFrom.$setDirty();
            this.$scope.submitAbsenceForm.timeTo.$setDirty();
        }
    }

    submitAbsence() {
        this.setFormFieldsDirty();

        if (this.busy || !this.$scope.submitAbsenceForm.$valid || !this.student.isUserPPAOForStudent) return;

        const deferred = this.$q.defer();

        this.busy = true;
        this.absenceSubmitted = false;
        this.error = false;

        const data: ReportAbsenceWriteModel = {
            eQId: this.student.oneSchoolId,
            allDay: this.allDay,
            dateAbsentFrom: this.dateFrom,
            dateAbsentTo: this.dateTo,
            timeFrom: this.allDay ? "" : this.timeFrom,
            timeTo: this.allDay ? "" : this.timeTo,
            schoolCode: this.enrolment.schoolCode,
            reason: this.reason
        };

        this.apiService
            .reportAbsence(data)
            .then(response => {
                this.absenceSubmitted = true;

                this.$timeout(() => {
                    var successDiv = angular.element(document.getElementById("success"));
                    (<any>this.$document).scrollToElement(successDiv, 0, 0);
                }, 100);
            })
            .catch(response => {
                this.error = true;
                this.$log.error("Error reporting absence for student", response);
            })
            .finally(() => {
                this.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    setFormFieldsDirty() {
        this.$scope.submitAbsenceForm.dateTo.$setDirty();
        this.$scope.submitAbsenceForm.dateFrom.$setDirty();
        this.$scope.submitAbsenceForm.reason.$setDirty();
        if (this.$scope.submitAbsenceForm.timeFrom) {
            this.$scope.submitAbsenceForm.timeFrom.$setDirty();
        }
        if (this.$scope.submitAbsenceForm.timeTo) {
            this.$scope.submitAbsenceForm.timeTo.$setDirty();
        }
    }

    public alignStartAndEndDate() {
        try {
            if (this.dateFrom && this.dateTo) {
                if (this.dateFrom > this.dateTo) {
                    this.dateTo = this.dateFrom;
                }
            }
        } catch (err) {}
    }

    loadImage(imagePath: string) {
        return require("../../content/img/" + imagePath);
    }
}

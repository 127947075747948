import { app } from "../app.module";
import { IContextLoader, StudentContext } from "../services/contextLoader";
import { Enrolment } from "../models/enrolment";
import { Student } from "../models/student";
import { User } from "../models/user";
import { FEATURES } from "../configuration";
import { AuthorisationService } from "../services/authorisationService";
import { ConsentResponseState } from "../models/enums";
import "./consent.less";
import { ConsentRequest } from "./consentRequestSummary/consentRequestSummary";
import { ConsentService } from "../services/consentService";
import { ConsentRequestSummaryViewModel } from "../models/api";

import templateUrl from "./consent.html";

const controllerId = "consent";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: ConsentComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

class ConsentComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    allEnrolments: Enrolment[];

    pendingRequests: ConsentRequest[] = [];
    pastRequests: ConsentRequest[] = [];
    consentLoadedSuccessfully: boolean;
    consentLoading: boolean;

    constructor($routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader, authorisationService: AuthorisationService, consentService: ConsentService) {
        "ngInject";
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasStudentFeatureAccess(this, FEATURES.CONSENT_REQUESTS)) return;

            this.consentLoading = true;
            consentService
                .getConsentRequestSummary(this.user)
                .then(summary => {
                    const allRequestsForThisStudent = summary.consentRequests.filter(s => s.eqId === this.student.oneSchoolId && s.schoolCode === $routeParams.centreCode);
                    this.pendingRequests = allRequestsForThisStudent.filter(r => r.state === ConsentResponseState.Pending).map(this.consentRequestMapper);
                    this.pastRequests = allRequestsForThisStudent.filter(r => r.state !== ConsentResponseState.Pending).map(this.consentRequestMapper);
                    this.consentLoadedSuccessfully = summary.loadedSuccessfully;
                })
                .catch(e => {
                    this.consentLoadedSuccessfully = false;
                })
                .finally(() => {
                    this.consentLoading = false;
                });

            return;
        });
    }

    consentRequestMapper = (request: ConsentRequestSummaryViewModel): ConsentRequest => {
        const enrolment = this.student.enrolments.find(e => e.schoolCode === request.schoolCode);
        return {
            id: request.id,
            title: request.title,
            dueDate: request.dueDate,
            responseDate: request.responseDate,
            withdrawnDate: request.withdrawnDate,
            schoolCode: request.schoolCode,
            state: request.state,
            schoolName: !!enrolment ? enrolment.school : undefined,
            studentFullName: this.student.fullName,
            studentId: this.student.oneSchoolId
        };
    };
}

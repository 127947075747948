import angular from "angular";
import { app } from "../app.module";
import INgModelController = angular.INgModelController;
app.directive("isChanged", function () {
    return {
        require: "ngModel",
        restrict: "A",
        scope: {
            isChanged: "="
        },
        link: function (scope: any, elem, attr, ngModel: INgModelController) {
            scope.$watch(
                function () {
                    var formValue = scope.isChanged.toUpperCase();
                    var compareToValue = String(ngModel.$modelValue).toUpperCase();

                    return (ngModel.$pristine && angular.isUndefined(ngModel.$modelValue)) || formValue !== compareToValue;
                },
                function (currentValue) {
                    ngModel.$setValidity("isChanged", currentValue);
                }
            );
        }
    };
});

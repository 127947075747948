import { app } from "../app.module";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { User } from "../models/user";
import { IDataContext } from "../services/dataContext";

import templateUrl from "./studentPhoto.html";
import { IApiService } from "../services/apiServiceProvider";
import { BlobService } from "../services/blobService";
import { IUrlResolver } from "../services/urlResolver";
import { IQService } from "angular";

class StudentPhotoComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    selectedOption: string;

    busy: boolean;
    acceptedTermsAndConditions: boolean;
    termsAndConditions: { key: string; url: string; checked: boolean };

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        private $location: ng.ILocationService,
        $sce: ng.ISCEService,
        private blobService: BlobService,
        private apiService: IApiService,
        private dataContext: IDataContext,
        private $window: ng.IWindowService,
        private $anchorScroll: ng.IAnchorScrollService,
        private $log: ng.ILogService,
        private $q: IQService,
        urlResolver: IUrlResolver
    ) {
        "ngInject";
        this.termsAndConditions = { key: undefined, url: undefined, checked: false };
        this.busy = false;
        contextLoader
            .load(this, $routeParams, "studentPhoto")
            .then(() => {
                if (this.isDelegatedViewer(this.student)) return;

                this.acceptedTermsAndConditions = this.user.studentPhotosEnabled || this.user.studentPhotosTermsAndConditionsKey == null;
                if (!this.acceptedTermsAndConditions) {
                    this.termsAndConditions.key = this.user.studentPhotosTermsAndConditionsKey;
                    this.termsAndConditions.url = $sce.trustAsResourceUrl(urlResolver.resolve("api/termsandconditions/" + this.user.studentPhotosTermsAndConditionsKey));
                    this.termsAndConditions.checked = false;
                }
            })
            .then(() => {
                this.$anchorScroll("main-content");
            });
    }

    isAdminView() {
        return this.dataContext.isAdminView();
    }

    canSubmit() {
        return !this.dataContext.isAdminView() || this.selectedOption != "none";
    }

    isDelegatedViewer(student: Student) {
        if (!student.isUserPPAOForStudent) {
            var newPath = "/";
            this.$location.path(newPath);
            return true;
        }
        return false;
    }

    acceptTermsAndConditions() {
        if (this.busy) return;
        const data = { ids: [this.termsAndConditions.key] };
        const deferred = this.$q.defer();
        this.busy = true;

        this.apiService
            .acceptTermsAndConditions(data)
            .then(() => {
                this.acceptedTermsAndConditions = true;
                this.user.studentPhotosEnabled = true;
            })
            .catch(response => {
                this.$log.error("Error accepting terms and conditions", response);
            })
            .finally(() => {
                this.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    removePhoto() {
        if (this.isAdminView()) return;
        if (this.busy) return;
        const deferred = this.$q.defer();
        this.busy = true;
        this.apiService
            .removeStudentPhoto(this.student.oneSchoolId)
            .then(() => {
                this.student.photoUrl = null;
                this.blobService
                    .createBlobFromDataUrl(this.student.photoUrlOrPlaceholder(), "image/png")
                    .then(blob => {
                        this.blobService
                            .getPhotoContentUrl(blob)
                            .then(url => {
                                this.student.photoContentUrl = url;
                            })
                            .catch(blobResponse => {
                                this.$log.error("Error Getting Photo Content Failed", blobResponse);
                            })
                            .finally(() => {
                                this.busy = false;
                                deferred.resolve();
                            });
                    })
                    .catch(response => {
                        this.$log.error("Error creating blob from data url", response);
                        this.busy = false;
                        deferred.resolve();
                    });
            })
            .catch(response => {
                this.$log.error("Error removing student photo", response);
                this.busy = false;
                deferred.resolve();
            });
        return deferred.promise;
    }

    navigateBack() {
        this.$window.history.back();
    }

    navigate($event: Event, selectedOption: string): void {
        $event.preventDefault();

        switch (selectedOption.toLowerCase()) {
            case "school":
                this.$location.path(`/studentPhoto/school/${this.student.oneSchoolId}/${this.enrolment.schoolCode}`);
                break;
            case "upload":
                this.$location.path(`/studentPhoto/upload/${this.student.oneSchoolId}/${this.enrolment.schoolCode}`);
                break;
            case "none":
                this.removePhoto();
                break;
            default:
                break;
        }
    }
}

app.directive("studentPhotoComponent", () => ({
    templateUrl,
    restrict: "E",
    scope: true,
    controller: StudentPhotoComponent,
    controllerAs: "vm"
}));

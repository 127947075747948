import moment from "moment";

import { app } from "../app.module";
app.directive("cardExpiry", [
    function () {
        return {
            require: "ngModel",
            link: function (scope, elem, attr, ngModel) {
                ngModel.$parsers.push(validateVerificatioNumber);

                function validateVerificatioNumber(verificationNumber) {
                    ngModel.$setValidity("format", true);
                    ngModel.$setValidity("futureDate", true);

                    if (verificationNumber === undefined) {
                        ngModel.$setValidity("format", false);
                        return verificationNumber;
                    }

                    if (verificationNumber.length != 4) {
                        ngModel.$setValidity("format", false);
                        return verificationNumber;
                    }

                    var parsedMonth = parseInt(verificationNumber[0] + verificationNumber[1], 10);
                    var parsedYear = parseInt(verificationNumber[2] + verificationNumber[3], 10);

                    if (isNaN(parsedMonth) || isNaN(parsedYear)) {
                        ngModel.$setValidity("format", false);
                        return verificationNumber;
                    }

                    var now = moment();
                    var nowYear = parseInt(now.format("YY"), 10);
                    var nowMonth = parseInt(now.format("MM"), 10);

                    if (parsedMonth != 99) {
                        var isInFuture = parsedYear > nowYear || (parsedYear === nowYear && parsedMonth >= nowMonth);
                        var monthIsValidMonth = parsedMonth <= 12;

                        ngModel.$setValidity("format", monthIsValidMonth);

                        if (monthIsValidMonth) ngModel.$setValidity("futureDate", isInFuture);

                        return verificationNumber;
                    }

                    return verificationNumber;
                }
            }
        };
    }
]);

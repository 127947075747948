import {app as services} from '../app.module';

const serviceId = "featuresService";
services.factory(serviceId, [featuresService]);

function featuresService() {
    return {
        hasAccessToEnrolmentHistory: hasAccessToEnrolmentHistory,
        hasAccessToStudentDetails: hasAccessToStudentDetails
    };

    function hasAccessToEnrolmentHistory(features) {
        return hasAccessToFeature(features, "Enrolment History");
    }

    function hasAccessToStudentDetails(features) {
        return hasAccessToFeature(features, "Student Details");
    }

    function hasAccessToFeature(features, featureName) {
        return features.includes(featureName);
    }
}

import { app as services } from "../app.module";
import { NativeSettings, AndroidSettings, IOSSettings } from "capacitor-native-settings";
import { IDeviceService } from "./deviceServiceProvider";
import { ILogService } from "angular";

var serviceId = "nativeSettingsLauncherService";
services.factory(serviceId, nativeSettingsLauncherService);

export interface INativeSettingsLauncherService {
    openPushNotificationSettings(): Promise<boolean>;
}

function nativeSettingsLauncherService($log: ILogService, deviceService: IDeviceService): INativeSettingsLauncherService {
    "ngInject";

    return {
        openPushNotificationSettings: openPushNotificationSettings
    };

    async function openPushNotificationSettings() {
        if (!deviceService.isMobileApp) {
            $log.debug("NativeLauncher - Not supported.");
            return false;
        }

        $log.debug("NativeLauncher - Opening Notification Settings");

        let result: { status: boolean };
        if (deviceService.isAndroid()) {
            result = await NativeSettings.openAndroid({
                option: AndroidSettings.AppNotification
            });
        } else {
            const result = await NativeSettings.openIOS({
                // @ts-ignore: a new option for iOS to open specific appNotifications Page
                option: "app" + IOSSettings.Notifications
            });
        }

        $log.debug(`NativeLauncher - Open Notification Settings (${result.status})`);

        return result.status;
    }
}

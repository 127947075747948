import moment from "moment";

export function enumerateDaysBetweenDates(startDate: Date, endDate: Date): Date[] {
  var dates = [startDate];

  var currDate = moment(startDate).startOf('day');
  var lastDate = moment(endDate).endOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
}
import moment from "moment";
import { app } from "../app.module";
import INgModelController = angular.INgModelController;
app.directive("isDate", function () {
    return {
        require: "ngModel",
        link: function (scope, elem, attr, ngModel: INgModelController) {
            var format = attr["format"];

            //For DOM -> model validation
            ngModel.$parsers.unshift(function (value) {
                var date = moment(value, format);

                ngModel.$setValidity("isDate", date.isValid());

                return value;
            });

            //For model -> DOM validation
            ngModel.$formatters.unshift(function (value) {
                var date = moment(value, format);

                ngModel.$setValidity("isDate", date.isValid());

                return value;
            });
        }
    };
});

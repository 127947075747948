import { app } from "../../app.module";
import templateUrl from "./register-link.html";
import "./register-link.less";
import { IDeviceService } from "@/app/services/deviceServiceProvider";
import { IAssetService } from "@/app/services/assetService";
import { IUrlResolver } from "@/app/services/urlResolver";

app.component("registerLinkComponent", {
    templateUrl: templateUrl,
    controller: registerLink,
    controllerAs: "vm"
});

function registerLink(assetService: IAssetService, deviceService: IDeviceService, urlResolver: IUrlResolver) {
    "ngInject";
    const vm = this;
    vm.redirectUser = function ($event) {
        if (!deviceService.isMobileApp) {
            return true;
        }

        $event.preventDefault();
        assetService.mobileOpenFile(urlResolver.resolve("/#/registration/account-owner/invitation/"), false);

        return false;
    };
}


import angular from "angular";
import { app } from "../../../app.module";
import templateUrl from "./user-reviews-section.html";
import "./user-reviews-section.less";

interface IUserReview {
    name: string
    review: string
    imageSrc: string
}

const userReviews: IUserReview[] = [
    {
        name: 'Michelle L., Bardon State School',
        review: '"We started using QParents two years ago, and now I can\'t imagine our week without it. So much time saved not waiting on the phone, or on last minute trips to the school get a payment for an excursion."',
        imageSrc: 'avatarNoPhoto.png',
    },
    {
        name: 'Blake L., Bardon State School',
        review: '"We started using QParents two years ago, and now I can\'t imagine our week without it. So much time saved not waiting on the phone, or on last minute trips to the school get a payment for an excursion."',
        imageSrc: 'avatarNoPhoto.png',
    },
    {
        name: 'Jason L., Bardon State School',
        review: '"We started using QParents two years ago, and now I can\'t imagine our week without it. So much time saved not waiting on the phone, or on last minute trips to the school get a payment for an excursion."',
        imageSrc: 'avatarNoPhoto.png',
    },
    {
        name: 'Ripley L., Bardon State School',
        review: '"We started using QParents two years ago, and now I can\'t imagine our week without it. So much time saved not waiting on the phone, or on last minute trips to the school get a payment for an excursion."',
        imageSrc: 'avatarNoPhoto.png',
    }
]

app.component("userReviewsSectionComponent", {
    templateUrl: templateUrl,
    controller: userReviewsSection,
    controllerAs: "vm"
});

function userReviewsSection($timeout) {
    "ngInject";
    var vm = this;
    vm.slideIndex = 0;
    vm.userReviews = userReviews;

    let timer = null;

    vm.carouselScrollEventHandler = function() {
        if(timer !== null) {
            clearTimeout(timer);        
        }
        timer = setTimeout(function() {
            document.getElementById('navButton-' + vm.slideIndex).classList.remove('selected');
            var carousel = document.getElementById('review-carousel');
            var width = carousel.scrollWidth;
            var numOfElements = vm.userReviews.length;
            var scrollLeft = Math.round(carousel.scrollLeft);
            var slideIndex = Math.round(scrollLeft / width * numOfElements);
            
            document.getElementById('navButton-' + slideIndex).classList.add('selected');
            vm.slideIndex = slideIndex;
        }, 100);
    }

    vm.$postLink = function () {

        $timeout(function() {
            document
                .getElementById('review-carousel')
                .addEventListener('scroll', vm.carouselScrollEventHandler, false);
        });
    };

    vm.loadImage = function(imagePath) {
        return require('../../../../content/img/' + imagePath);
    };

    vm.moveSlider = function(index: number){
        const reviewElement = angular.element(document.getElementById("review-" + index));
        reviewElement[0].scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }
}
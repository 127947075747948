import angular, { ILogService, IQService } from "angular";
import { app } from "../app.module";
import { FEATURES } from "../configuration";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import { IDataContext } from "../services/dataContext";

const controllerId = "studentMedicalConditions";
import templateUrl from "./studentMedicalConditions.html";
import { MedicalConditionViewModel, MedicalConditionType } from "../models/api";
import { IApiService } from "../services/apiServiceProvider";
const ALERGYDETAILS_MAXLENGTH: number = 20;

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: StudentMedicalConditionsComponent,
    controllerAs: "vm",
    restrict: "E",
    replace: false
}));

class StudentMedicalConditionsComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    originalMedicalConditions: MedicalConditionViewModel[];
    medicalConditions: MedicalConditionViewModel[];
    medicalConditionTypes: MedicalConditionType[];
    medicalConditionsUpdated: boolean = false;
    detailsSubmitted: boolean;
    busy: boolean;
    error: boolean;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        authorisationService: AuthorisationService,
        private $log: ILogService,
        private apiService: IApiService,
        private $q: IQService,
        private $location: angular.ILocationService,
        private dataContext: IDataContext
    ) {
        "ngInject";
        this.busy = false;
        this.error = false;
        contextLoader
            .load(this, $routeParams, controllerId)
            .then(() => {
                if (!authorisationService.hasStudentFeatureAccess(this, FEATURES.STUDENT_DETAILS)) return;

                this.originalMedicalConditions = this.student.medicalConditions.slice(0);
                this.medicalConditions = angular.copy(this.originalMedicalConditions);
            })
            .then(() => {
                return this.apiService.getStudentMedicalConditionTypes<MedicalConditionType[]>().then(response => {
                    this.medicalConditionTypes = response.data;
                });
            });
    }

    hasAllergyDetails(medicalCondition: MedicalConditionViewModel) {
        var condition = this.getMedicalCondition(medicalCondition);
        if (!condition) return false;

        return condition.requiresAllergyDetails;
    }

    alergyDetailsCharactersRemaining(medicalCondition: MedicalConditionViewModel): number {
        if (!medicalCondition.alergyDetails) return ALERGYDETAILS_MAXLENGTH;
        return ALERGYDETAILS_MAXLENGTH - medicalCondition.alergyDetails.length;
    }

    get AlergyDetailsMaxLength() {
        return ALERGYDETAILS_MAXLENGTH;
    }

    getMedicalCondition(medicalCondition: MedicalConditionViewModel): MedicalConditionType {
        if (!medicalCondition.medicalConditionId) return;
        return this.medicalConditionTypes?.find(condition => condition.id === medicalCondition.medicalConditionId);
    }

    isUsed(medicalConditionTypeId: number, currentMedicalConditionTypeId: number | null): boolean {
        const medicalConditionType = this.medicalConditionTypes?.find(x => x.id === medicalConditionTypeId);
        const medicalConditionAlreadyInUse = this.medicalConditions?.filter(c => c.medicalConditionId !== currentMedicalConditionTypeId).some(c => c.medicalConditionId === medicalConditionTypeId);

        return medicalConditionAlreadyInUse && !medicalConditionType.requiresAllergyDetails;
    }

    back() {
        this.$location.path(`/studentDetails/${this.student.oneSchoolId}/${this.enrolment.schoolCode}`);
    }

    addMedicalCondition() {
        this.medicalConditions.push({
            id: null,
            medicalConditionId: null,
            isNew: true,
            isCurrent: true,
            advisedDate: <any>new Date(), // Sorry, reusing API types in views something something
            alergyDetails: null,
            description: null
        });
    }

    submit(medicalConditionsForm) {
        if (this.busy || !this.student.isUserPPAOForStudent || !medicalConditionsForm.$valid) {
            return;
        }

        const deferred = this.$q.defer();

        this.busy = true;
        this.error = false;
        this.detailsSubmitted = false;

        angular.forEach(this.medicalConditions, medicalCondition => {
            if (medicalCondition.isNew && !medicalCondition.isCurrent) {
                var index = this.medicalConditions.indexOf(medicalCondition);
                this.medicalConditions.splice(index);
            }
            // remove allergy details that migth have been entered
            // if this type has no allergy details
            if (!this.hasAllergyDetails(medicalCondition)) {
                medicalCondition.alergyDetails = "";
            }
        });

        var data = {
            oneSchoolId: this.student.oneSchoolId,
            oneSchoolParentId: this.user.oneSchoolParentId,
            medicalConditions: this.medicalConditions
        };

        this.dataContext
            .saveStudentMedicalConditions(data)
            .then(() => {
                this.medicalConditionsUpdated = true;

                this.student.updatedMedicalConditions = {
                    timestamp: <any>new Date()
                };

                const updatedMedicalConditions = this.medicalConditions
                    .filter(medicalCondition => medicalCondition.isCurrent)
                    .map(medicalCondition => {
                        return {
                            ...medicalCondition,
                            isNew: false
                        } as MedicalConditionViewModel;
                    });

                this.medicalConditions = updatedMedicalConditions;
                this.originalMedicalConditions = angular.copy(updatedMedicalConditions);

                medicalConditionsForm.$setPristine();
            })
            .catch(response => {
                this.error = true;
                this.$log.error("Error changing the student medical conditions details", response);
            })
            .finally(() => {
                this.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }
}

import { app as services } from "../app.module";
import { IUrlResolver } from "./urlResolver";
import { BPointClientPaymentResponse, PayableFinanceRecordViewModel, BPointVerifyBatchResultViewModel } from "../models/api";
import { IDeviceService } from "./deviceServiceProvider";
import { IApiService } from "./apiServiceProvider";
import { ILogService } from "angular";
export type ProcessPaymentResult = BPointClientPaymentResponse;

declare var window: any;

export class ProcessPaymentService {
    constructor(private $q: ng.IQService, private $log:ILogService, private apiService: IApiService, private deviceService: IDeviceService, private urlResolver: IUrlResolver) {
        "ngInject";
    }

    getPaymentAuthenticationTokens(payableFinanceRecords) {
        return this.apiService.paymentAuthenticationToken<PayableFinanceRecordViewModel[]>(payableFinanceRecords).then(r => r.data);
    }

    verifyPaymentResponse(bpointPaymentResponses: BPointClientPaymentResponse[]) {
        return this.apiService.verifyPaymentResponse<BPointVerifyBatchResultViewModel>(bpointPaymentResponses).then(r => r.data);
    }

    auditPayment(payableFinanceRecord: PayableFinanceRecordViewModel) {
        return this.apiService.auditPayment(payableFinanceRecord).then(r => r.data);
    }

    sendPayment(payableFinanceRecord: PayableFinanceRecordViewModel, cardNumber: string, cardExpiry: string, cardCVV: string, paymentIndex: number) {
        if (this.deviceService.isMobileApp) return this.sendPaymentCapacitor(payableFinanceRecord, cardNumber, cardExpiry, cardCVV, paymentIndex);
        return this.sendPaymentWeb(payableFinanceRecord, cardNumber, cardExpiry, cardCVV, paymentIndex);
    }

    convertDollarsToCents(dollarAmount) {
        // Some decimal numbers don't handle multiplying by 100 well ie. 37.55
        var roughDollars = dollarAmount * 100;
        var dollarError = roughDollars % 1;
        if (dollarError !== 0) {
            if (dollarError < 0.5) return Math.floor(roughDollars);
            return Math.ceil(roughDollars);
        }

        return roughDollars;
    }

    sendPaymentWeb(payableFinanceRecord: PayableFinanceRecordViewModel, cardNumber: string, cardExpiry: string, cardCVV: string, paymentIndex: number) {
        var deferred = this.$q.defer<ProcessPaymentResult>();
        var expiryMonth = String(cardExpiry).substring(0, 2);
        var expiryYear = String(cardExpiry).substring(2);
        var redirectUrl = window.location.protocol + "//" + window.location.host + "/api/processpaymenthandler/paymentresponse";

        var iframe = document.createElement("iframe");
        iframe.style.display = "none";
        var html =
            // call the callback after 60 seconds - this is for the scenario we never get a response from BPOINT.
            "<script>window.setTimeout(function(){window.parent.paymentCompleteHandler" +
            paymentIndex +
            "(false, '" +
            payableFinanceRecord.paymentAmount +
            "','" +
            payableFinanceRecord.invoiceNumber +
            "');}, 60 * 1000);</script>" +
            "<html>" +
            "<body>" +
            '<form id="paymentForm' +
            paymentIndex +
            '" method="POST" action="' +
            payableFinanceRecord.bPointPaymentUrl +
            '">' +
            '<input type="text" name="in_merchant_number" value="' +
            payableFinanceRecord.merchantNumber +
            '"/>' +
            '<input type="text" name="in_billercode" value="' +
            payableFinanceRecord.billerCode +
            '"/>' +
            '<input type="text" name="in_pay_token" value="' +
            payableFinanceRecord.paymentAuthToken +
            '"/>' +
            '<input type="text" name="in_amount" value="' +
            this.convertDollarsToCents(payableFinanceRecord.paymentAmount) +
            '"/>' +
            '<input type="text" name="in_merchant_reference" value="QParents"/>' +
            '<input type="text" name="in_crn1" value="' +
            payableFinanceRecord.customerReferenceNumber +
            '"/>' +
            '<input type="text" name="in_crn2" value="' +
            payableFinanceRecord.invoiceNumber +
            '"/>' +
            '<input type="text" name="in_crn3" value="' +
            payableFinanceRecord.studentName +
            '"/>' +
            '<input type="text" name="in_credit_card" value="' +
            cardNumber +
            '"/>' +
            '<input type="text" name="in_expiry_month" value="' +
            expiryMonth +
            '"/>' +
            '<input type="text" name="in_expiry_year" value="' +
            expiryYear +
            '"/>' +
            '<input type="text" name="in_cvv" value="' +
            cardCVV +
            '"/>' +
            '<input type="text" name="in_receipt_page_url" value="' +
            redirectUrl +
            '"/>' +
            '<input type="text" name="in_paymentForm_index" value="' +
            paymentIndex +
            '"/>' +
            "</form>" +
            "</body>" +
            "</html>";

        document.body.appendChild(iframe);
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(html);
        iframe.contentWindow.document.close();

        window["paymentCompleteHandler" + paymentIndex] = function (value: false | BPointClientPaymentResponse, amount: number, invoiceNumber: string) {
            if (value === false) {
                var unknownOutcomeResult = {
                    requestAmount: amount,
                    requestInvoiceNumber: invoiceNumber,
                    unknownResponseStatus: true
                };
                deferred.resolve(<any>unknownOutcomeResult);
            } else {
                deferred.resolve(value);
            }
            document.body.removeChild(iframe);
            window["paymentCompleteHandler" + paymentIndex] = null;
        };

        this.auditPayment(payableFinanceRecord);
        const paymentForm: HTMLFormElement = <HTMLFormElement>iframe.contentWindow.document.getElementById("paymentForm" + paymentIndex);
        paymentForm.submit();

        return deferred.promise;
    }

    // For capacitor, use the capacitor plugin as it allows us to send the form POST and process the redirect response without the
    // need for the web server and it avoids CORS issues.
    sendPaymentCapacitor(payableFinanceRecord: PayableFinanceRecordViewModel, cardNumber: string, cardExpiry: string, cardCVV: string, paymentIndex: number) {
        const deferred = this.$q.defer<ProcessPaymentResult>();
        const parentLog = this.$log;        
        const expiryMonth = String(cardExpiry).substring(0, 2);
        const expiryYear = String(cardExpiry).substring(2);
        var postData = {
            in_merchant_number: payableFinanceRecord.merchantNumber,
            in_billercode: payableFinanceRecord.billerCode,
            in_pay_token: payableFinanceRecord.paymentAuthToken,
            in_amount: this.convertDollarsToCents(payableFinanceRecord.paymentAmount),
            in_merchant_reference: "QParents",
            in_crn1: payableFinanceRecord.customerReferenceNumber,
            in_crn2: payableFinanceRecord.invoiceNumber,
            in_crn3: payableFinanceRecord.studentName,
            in_credit_card: cardNumber,
            in_expiry_month: expiryMonth,
            in_expiry_year: expiryYear,
            in_cvv: cardCVV,
            in_receipt_page_url: this.urlResolver.resolve("/api/processpayment/paymentResponse"), // No actually used...  We'll catch the redirect below...
            in_paymentForm_index: paymentIndex
        };

        var postOptions = {
            url: this.urlResolver.resolve(payableFinanceRecord.bPointPaymentUrl),
            data: postData,
            disableRedirects: true
        };
        
        window.cordovaHTTP.disableRedirect(true);
        window.cordovaHTTP.post(postOptions.url, postOptions.data, {}, tryHandleResponse, tryHandleResponse);

        return deferred.promise;

        function tryHandleResponse(response) {
            // If we get a redirect, we have a response from BPOINT, we need to process the redirect query params as these contain the
            // actual payment response.
            if (response.status === 302) {
                // The query string within the Location header contains all of the response data.  Parse those params into
                // an object and return that to the caller!
                var location = response.headers["Location"];
                var query = location.substring(location.indexOf("?") + 1);

                var match,
                    pl = /\+/g, // Regex for replacing addition symbol with a space
                    search = /([^&=]+)=?([^&]*)/g,
                    decode = function (s) {
                        return decodeURIComponent(s.replace(pl, " "));
                    },
                    paymentResult = <any>{};

                while ((match = search.exec(query))) paymentResult[decode(match[1])] = decode(match[2]);

                deferred.resolve(paymentResult);
                return;
            }

            unknownPaymentStatus(response);
        }

        function unknownPaymentStatus(reason) {
            var unknownOutcomeResult = {
                requestAmount: payableFinanceRecord.paymentAmount,
                requestInvoiceNumber: payableFinanceRecord.invoiceNumber,
                unknownResponseStatus: true
            };
            deferred.resolve(<any>unknownOutcomeResult);

            parentLog.debug("Payment failed.");
            parentLog.error(reason);
        }
    }
}

services.service("processPaymentService", ProcessPaymentService);

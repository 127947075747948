import { ILocationService, ILogService, IQService } from "angular";
import { app } from "../app.module";
import { YEAR_LEVELS } from "../configuration";
import { ICommonService } from "../services/common";
import { IDataContext } from "../services/dataContext";
import templateUrl from "./addStudent.html";
import { StudentState } from "../models/enums";

const controllerId = "addStudent";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: addStudent,
    controllerAs: "vm",
    restrict: "E",
    replace: true
}));

function addStudent(common: ICommonService, dataContext: IDataContext, $scope, $q: IQService, $location: ILocationService, $log: ILogService) {
    "ngInject";
    var vm = this,
        user = null;

    vm.eqid = "";
    vm.schools = [];
    vm.selectedSchool = null;
    vm.YEAR_LEVELS = YEAR_LEVELS;
    vm.selectedYearLevel = null;
    vm.save = save;
    vm.cancel = cancel;
    vm.addAnotherStudent = addAnotherStudent;
    vm.busy = false;
    vm.showWelcomeText = false;
    vm.error = false;
    vm.submitted = false;
    vm.alreadyApproved = false;
    vm.alreadyPending = false;

    common.activateController([initialiseUser()], controllerId).then(function () {});

    function initialiseUser() {
        return dataContext.getData().then(function (data) {
            user = data;
            vm.schools = data.schools;
            vm.showWelcomeText = wasAutoRedirectedToThisPage();
        });
    }

    function wasAutoRedirectedToThisPage() {
        return user.redirectToAddStudentPage;
    }

    function setInitialFocus() {
        document.getElementById("eqid").focus();
    }

    function save(form) {
        if (!form.$valid) return;
        if (vm.busy) return;

        const deferred = $q.defer();

        vm.busy = true;
        vm.error = false;
        vm.submitted = false;
        vm.alreadyApproved = false;
        vm.alreadyPending = false;

        dataContext
            .saveStudentRelation({
                eqid: vm.eqid,
                schoolId: vm.selectedSchool.id,
                yearLevel: vm.selectedYearLevel
            })
            .then(responseData => {
                vm.studentName = responseData.name;
                vm.submitted = responseData.state === StudentState.MatchAcceptedApprovalPending;
                vm.alreadyApproved = responseData.state === StudentState.AlreadyMatchedAndApproved;
                vm.alreadyPending = responseData.state === StudentState.AlreadyMatchedApprovalPending;
                dontAutoRedirectToAddStudent();

                if (responseData.state !== StudentState.MatchAcceptedApprovalPending) {
                    return;
                }
                if (!user.pendingStudents) {
                    user.pendingStudents = [];
                }
                user.pendingStudents.push({
                    id: vm.eqid,
                    name: responseData.name,
                    schoolName: vm.selectedSchool.name,
                    yearLevelName: vm.selectedYearLevel
                });
            })
            .catch(response => {
                vm.error = true;
                $log.error("Error adding a new student", response);
            })
            .finally(() => {
                vm.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    function addAnotherStudent() {
        vm.eqid = "";
        vm.selectedSchool = null;
        vm.selectedYearLevel = null;
        vm.submitted = false;
        vm.showWelcomeText = false;

        dontAutoRedirectToAddStudent();

        $scope.addForm.$setPristine();

        setInitialFocus();
    }

    function cancel() {
        dontAutoRedirectToAddStudent();
        $location.path("/");
    }

    function dontAutoRedirectToAddStudent() {
        user.cancelRedirectToAddStudentPage();
    }
}

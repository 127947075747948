import { AdminViewRedirectService } from "./adminViewRedirectService";
import { app as services } from "../app.module";
import { ILocationService, ILogService, IQService } from "angular";
import { IRouteService } from "./routeService";
import { showToastsOnError } from "../configuration";

const serviceId = "errorLoggingService";
services.factory(serviceId, errorLoggingService);

export interface IErrorLoggingService {
    responseError: (rejection: any) => angular.IPromise<any>;
}

function errorLoggingService(
    $q: IQService,
    $log: ILogService,
    $rootScope,
    $location: ILocationService,
    toaster,
    routeService: IRouteService,
    $injector,
    adminViewRedirectService: AdminViewRedirectService
): IErrorLoggingService {
    "ngInject";
    return {
        responseError: function (rejection) {
            // hide splash screen in case of error
            $rootScope.hideFullScreenLoadingPane();

            // Should we just plain ignore?
            if (handleSessionExpired(rejection)) {
                return $q.reject(rejection);
            }

            if ([500, 400, 405].some(x => x === rejection.status)) {
                if (showToastsOnError) {
                    toaster.pop("error", rejection.config.method + " " + rejection.config.url, (rejection.data.ErrorMessage || "") + "\n" + (rejection.data.FullException || ""));
                }

                $log.error(buildMessage(rejection));

                $location.path("/error");
            }

            return $q.reject(rejection);
        }
    };

    function handleSessionExpired(rejection) {
        if (!rejection) return false;
        if (rejection.status == 401 && !routeService.isAnonymousRoute($location.path())) {
            // required to avoid circular dependency errors.
            let authorisationService = $injector.get("authorisationService");
            authorisationService.clearAuthenticationCookie().then(() => {
                adminViewRedirectService.landingPageRedirect();
                $log.debug("handleSessionExpired - redirecting to login");
            });

            return true;
        }
        return false;
    }

    function buildMessage(rejection) {
        if (!rejection) return "Something is wrong here.";

        var reason = rejection.headers().reason;
        var reasonAppend = reason ? " - " + reason : "";
        if (rejection.status == 400) {
            return "Check what's wrong and try again" + reasonAppend;
        } else if (rejection.status == 403) {
            return "You aren't allowed to do that" + reasonAppend;
        } else if (rejection.status == 404) {
            return "We couldn't find what you were looking for" + reasonAppend;
        } else {
            return "Something has gone wrong" + reasonAppend;
        }
    }
}

import { IApiService } from "@/app/services/apiServiceProvider";
import { app } from "../../app.module";
import { SESSION_DV_CODE_KEY } from "../../configuration";
import { AuthorisationService } from "../../services/authorisationService";
import { ILocationService, ILogService, IQService } from "angular";
import { ILocalStorageService } from "angular-local-storage";

const serviceId = "delegatedViewerInvitationInitialise";

app.factory(serviceId, delegatedViewerInvitationInitialise);

function delegatedViewerInvitationInitialise(
    $q: IQService,
    $location: ILocationService,
    $log: ILogService,
    $route,
    localStorageService: ILocalStorageService,
    apiService: IApiService,
    authorisationService: AuthorisationService
) {
    "ngInject";
    return function () {
        var deferred = $q.defer();
        var code = $route.current.params.code;

        if (!code) {
            $location.path("/");
            deferred.reject();
        } else {
            apiService
                .getDelegatedViewerInvitation(code)
                .then(function successCallback(response) {
                    const data = response.data;
                    // if no pending terms and conditions then
                    // skip the accept terms and conditions
                    if (data.validCode && data.pendingTermsAndConditionsKeys.length === 0) {
                        localStorageService.set(SESSION_DV_CODE_KEY, {
                            sessionId: data.sessionId,
                            name: data.delegatedViewerFullName
                        });

                        $location.path("/registration/delegated-viewer/verify");
                        deferred.reject();
                    } else {
                        deferred.resolve(data);
                    }
                })
                .catch(response => {
                    $log.error("invitationInitialise - Error initialising the invitation", response);
                    deferred.reject();
                })
                .finally(function () {
                    // If user is already logged in, then log them out as a security precaution
                    // this will prevent the case where the DV accepts the invitation on a computer, where someone
                    // else is logged into QParents and we automatically add the delegated student to the wrong account
                    authorisationService.clearAuthenticationCookieWithoutPublishing();
                });
        }

        return deferred.promise;
    };
}

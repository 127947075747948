// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../content/img/mobileapplogo.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<form class=\"mobile-app-message\"> <fieldset> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" class=\"mobileapp-message\" alt=\"Mobile App\"/> <p>Download the free QParents app from <a href=\"https://itunes.apple.com/app/qparents/id923225089?mt=8\" target=\"_blank\" rel=\"noopener\">iTunes</a> or <a href=\"https://play.google.com/store/apps/details?id=au.gov.qld.deta.qparents\" target=\"_blank\" rel=\"noopener\">Google Play</a></p> </fieldset> </form> ";
// Exports
var _module_exports = code;;
var path = '/app/login/mobile-app-message.html';
window.angular.module('app').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
import {app as services} from '../app.module';

var serviceId = 'phoneService';
    services.factory(serviceId, [phoneService]);

    function phoneService() {
        return {
            isAValidPhoneNumber: isAValidPhoneNumber
        };

        function isNumeric(char) {
            return /\d/.test(char);
        }

        function getDigitsOnlyString(phoneNumber: string) {
            var characters = phoneNumber.split('');
            var digitsOnly = characters.filter(function (char) {
                return isNumeric(char);
            });

            var digitsOnlyString = digitsOnly.join('');
            return digitsOnlyString;
        }

        // all phone number must contain 10 digits (other characters will be stripped server side)
        function isAValidPhoneNumber(phoneNumber, allowedPrefixes) {
            // must contain 10 digits (other characters will be stripped server side)
            // and start with 02, 03, 07, 08, 13 or 18

            // allow blank (required will take care of validation)
            if (phoneNumber === null || phoneNumber === undefined) return true;
            if (phoneNumber === '') return true;

            if (phoneNumber.length < 10) return false;

            let isInternational = phoneNumber.charAt(0) === '+';

            let phoneNumberToCheck = (isInternational ? phoneNumber.substr(1) : phoneNumber).replace(/ /g,'');
            var digitsOnlyString = getDigitsOnlyString(phoneNumberToCheck);
            if (digitsOnlyString !== phoneNumberToCheck) return false;

            if (isInternational) {
                return phoneNumber.length <= 50;
            }

            // Local mobile numbers should be exactly 10 digits
            if (digitsOnlyString.length !== 10) return false;

            return allowedPrefixes.some(function (prefix) {
                return digitsOnlyString.indexOf(prefix) === 0;
            });
        }
    }
import angular from "angular";
import { PayableFinanceRecordViewModel, PendingPaymentViewModel, FinanceRecordViewModel } from "./api";

export class FinanceRecord implements PayableFinanceRecordViewModel {
    bPointPaymentUrl: string;
    amountOwing: number;
    originalAmount: number;
    transactionDate: string;
    status: string;
    invoiceNumber: string;
    paymentRef: string;
    dueDate: string;
    description: string;
    pdfExists: boolean;
    customerReferenceNumber: string;
    paymentsAreInProgress: boolean;
    isInvoice: boolean;
    isPayment: boolean;
    pendingPayments: PendingPaymentViewModel[];
    paymentReceiptNumber: string;
    typeDescription: string;
    studentName: string;

    isSelectedForPayment: boolean;
    paymentAmount: number;
    paymentAuthToken: string;
    merchantNumber: string;
    billerCode: string;

    constructor(data: FinanceRecordViewModel) {
        angular.extend(this, data);

        this.isSelectedForPayment = false;
        this.paymentAmount = this.amountOwing;
    }
}

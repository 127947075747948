import { app } from "../app.module";
import moment from "moment";

import INgModelController = angular.INgModelController;
app.directive("greaterThanDate", function () {
    return {
        require: "ngModel",
        link: function (scope, elem, attr, ngModel: INgModelController) {
            var format = attr["format"],
                fromDate,
                toDate,
                timeFrom,
                timeTo;

            attr.$observe("fromDate", function (value) {
                fromDate = (<string>value).replace(/"/g, "");
                performValidation();
            });

            attr.$observe("timeFrom", function (value) {
                timeFrom = value;
                performValidation();
            });

            attr.$observe("toDate", function (value) {
                toDate = (<string>value).replace(/"/g, "");
                performValidation();
            });

            attr.$observe("timeTo", function (value) {
                timeTo = value;
                performValidation();
            });

            ngModel.$parsers.unshift(function (value) {
                timeTo = value;
                performValidation();
                return value;
            });

            function performValidation() {
                if (fromDate && toDate && timeFrom && timeTo) {
                    var from = moment(fromDate).add(moment.duration(timeFrom).asHours(), "hours");
                    var to = moment(toDate).add(moment.duration(timeTo).asHours(), "hours");

                    ngModel.$setValidity("greaterThanDate", to.toDate() > from.toDate());
                }
            }
        }
    };
});

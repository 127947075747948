import {app} from '../app.module';
app.directive('mobileNumber', ['phoneService', function (phoneService) {
            var VALID_AUSTRALIAN_PREFIXES = ['04', '014'];

            return {
                require: 'ngModel',
                link: function (scope, elem, attr, ngModel) {
                    ngModel.$parsers.unshift(checkForValidMobileNumber);
                    ngModel.$formatters.unshift(checkForValidMobileNumber);

                    function checkForValidMobileNumber(mobileNumber) {
                        ngModel.$setValidity('mobileNumber',phoneService.isAValidPhoneNumber(mobileNumber, VALID_AUSTRALIAN_PREFIXES));
                        return mobileNumber;
                    }
                }
            };
        }]);
import angular from "angular";
import { app } from "../app.module";
import { FEATURES } from "../configuration";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import { DelegateViewerService } from "../services/delegateViewer";
import { DelegateViewerViewModel } from "../models/api";
import templateUrl from "./viewer.html";
import { IApiService } from "../services/apiServiceProvider";
const controllerId = "viewer";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: ViewerComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

type Viewer = DelegateViewerViewModel & { registeredEmail?: string };

class ViewerComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    viewerId: string;

    originalViewer: Viewer;
    // Honestly unsure why this registered email is here instead of just email
    viewer: Viewer;
    editingInformation: boolean;
    editingStatus: boolean;
    showEditInformation: boolean;
    showResendInvitation: boolean;
    showEditStatus: boolean;
    canEditContactDetails: boolean;
    canEditFeatures: boolean;
    busy: boolean;
    message: { header: string; body: string };
    confirmRemove: boolean;
    error: boolean;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        authorisationService: AuthorisationService,
        private apiService: IApiService,
        private $location: ng.ILocationService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private delegateViewer: DelegateViewerService
    ) {
        "ngInject";
        this.viewerId = $routeParams.viewerId;

        this.message = null;
        this.showEditInformation = true;
        this.showResendInvitation = true;
        this.showEditStatus = true;
        this.editingInformation = false;
        this.editingStatus = false;
        this.canEditContactDetails = false;
        this.canEditFeatures = false;
        this.confirmRemove = false;
        this.busy = false;
        this.error = false;

        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasStudentFeatureAccess(this, FEATURES.STUDENT_DETAILS)) return;
            if (this.isDelegatedViewer()) return;

            this.originalViewer = this.student.delegateViewers.find(viewer => viewer.token === this.viewerId);
            this.viewer = angular.copy(this.originalViewer);

            if (this.viewer.qId == null) {
                this.viewer.registeredEmail = "";
            } else {
                this.getDelegatedViewerDetails(this.viewer.qId).then(result => {
                    this.viewer.registeredEmail = result.emailAddress;
                });
            }

            this.resetPage(this.viewer);
        });
    }

    getDelegatedViewerDetails(qId) {
        return this.apiService.getUserEmail(qId).then(response => response.data);
    }

    displayRemoveConfirmation() {
        return this.viewer.status === "Remove";
    }

    isDelegatedViewer() {
        if (!this.student.isUserPPAOForStudent) {
            var newPath = "/featureUnavailable";
            this.$location.path(newPath);
            return true;
        }
        return false;
    }

    resetPage(originalViewer: Viewer) {
        originalViewer.allFeatures = originalViewer.allFeatures.filter(function (feature) {
            return feature !== FEATURES.STUDENT_DETAILS && feature !== FEATURES.CONSENT_REQUESTS;
        });

        originalViewer.features = originalViewer.features.filter(function (feature) {
            return feature !== FEATURES.STUDENT_DETAILS && feature !== FEATURES.CONSENT_REQUESTS;
        });

        this.viewer = angular.copy(originalViewer);

        if (this.viewer.qId == null || this.viewer.status === "Remove") {
            this.viewer.registeredEmail = "";
        } else {
            this.getDelegatedViewerDetails(this.viewer.qId).then(result => {
                this.viewer.registeredEmail = result.emailAddress;
            });
        }

        this.editingInformation = false;
        this.editingStatus = false;

        var isViewerBlocked = this.viewer.status === "Blocked" || this.viewer.status === "Remove";
        var isViewerDeleted = this.viewer.status === "Remove";
        var isPending = this.viewer.status === "Invited";

        this.showEditInformation = !isViewerDeleted;
        this.showResendInvitation = isPending;
        this.showEditStatus = !isViewerDeleted;
        this.canEditContactDetails = isPending;
        this.canEditFeatures = !isViewerBlocked;
    }

    edit() {
        this.message = null;
        this.editingInformation = true;
        this.setFocus("firstName");
    }

    editStatus() {
        this.message = null;
        this.editingStatus = true;
        this.setFocus("status");
    }

    cancel() {
        this.resetPage(this.originalViewer);
    }

    setFocus(elementId) {
        this.$timeout(() => {
            $("#" + elementId).trigger("focus");
        }, 100);
    }

    resendInvite() {
        if (this.busy) return;
        const deferred = this.$q.defer();
        this.busy = true;
        this.error = false;

        this.delegateViewer
            .resendInvite(this.viewer)
            .then(() => {
                this.setMessage("SUCCESS!", "An invitation has been sent to " + this.viewer.fullName + " at the email address of " + this.viewer.email + ".");
            })
            .catch(response => {
                this.error = true;
                this.$log.error("Error resending invitation", response);
            })
            .finally(() => {
                this.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    save() {
        if (this.busy) return;
        const deferred = this.$q.defer();
        this.busy = true;
        this.error = false;

        var detailsChanged = this.haveDetailsChanged(this.originalViewer, this.viewer);
        var statusChanged = this.hasStatusChanged(this.originalViewer, this.viewer);

        if (detailsChanged && statusChanged) {
            this.updateViewer()
                .then(() => this.updateStatus())
                .then(viewers => this.refreshPage(viewers))
                .catch(response => {
                    this.error = true;
                    this.$log.error("Error updating viewer and status", response);
                })
                .finally(() => {
                    this.busy = false;
                    deferred.resolve();
                });
        } else if (detailsChanged) {
            this.updateViewer()
                .then(viewers => this.refreshPage(viewers))
                .catch(response => {
                    this.error = true;
                    this.$log.error("Error updating viewer", response);
                })
                .finally(() => {
                    this.busy = false;
                    deferred.resolve();
                });
        } else if (statusChanged) {
            this.updateStatus()
                .then(viewers => this.refreshPage(viewers))
                .catch(response => {
                    this.error = true;
                    this.$log.error("Error updating status", response);
                })
                .finally(() => {
                    this.busy = false;
                    deferred.resolve();
                });
        }

        return deferred.promise;
    }

    canSave() {
        var detailsChanged = this.haveDetailsChanged(this.originalViewer, this.viewer);
        var statusChanged = this.hasStatusChanged(this.originalViewer, this.viewer);

        return (detailsChanged || statusChanged) && this.anyFeaturesSelected() && this.anyRemoveApproved();
    }

    updateViewer() {
        return this.delegateViewer.updateViewer(this.viewer, this.student.oneSchoolId);
    }

    updateStatus() {
        return this.delegateViewer.updateStatus(this.viewer.token, this.student.oneSchoolId, this.viewer.status);
    }

    anyRemoveApproved() {
        if (this.displayRemoveConfirmation()) {
            return this.confirmRemove;
        }
        return true;
    }

    refreshPage(updatedDelegatedViewers: DelegateViewerViewModel[]) {
        this.student.delegateViewers = updatedDelegatedViewers;
        this.originalViewer = updatedDelegatedViewers.find(viewer => viewer.token === this.viewerId);

        if (this.originalViewer == null) {
            // status was updated to remove, so it doesn't exist in
            // the delegated viewer array anymore
            this.originalViewer = this.viewer;
        }

        this.resetPage(this.originalViewer);

        this.setMessage("Success!", "Thank you for updating " + this.viewer.fullName + "'s details. All details have been successfully saved.");
    }

    hasStatusChanged(originalViewer: DelegateViewerViewModel, newViewer: DelegateViewerViewModel) {
        return originalViewer.status !== newViewer.status;
    }

    haveDetailsChanged(originalViewer: DelegateViewerViewModel, newViewer: DelegateViewerViewModel) {
        var haveFeaturesChanged = !angular.equals(newViewer.features.sort(), originalViewer.features.sort());

        return originalViewer.firstName !== newViewer.firstName || originalViewer.lastName !== newViewer.lastName || originalViewer.email !== newViewer.email || haveFeaturesChanged;
    }

    displayStatus(viewer: DelegateViewerViewModel) {
        if (!viewer) return "";
        if (viewer.status === "Remove") return "Removed";
        return viewer.status;
    }

    setMessage(header: string, body: string) {
        this.message = {
            header: header,
            body: body
        };
    }

    approveDelegatedViewer() {
        if (this.busy) return;
        const deferred = this.$q.defer();
        this.busy = true;
        this.error = false;

        this.delegateViewer
            .approve(this.student.oneSchoolId, this.viewer)
            .then(v => this.refreshPage(v))
            .catch(response => {
                this.error = true;
                this.$log.error("Error approving delegate viewer", response);
            })
            .finally(() => {
                this.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    anyFeaturesSelected() {
        return this.viewer.features.length != 0;
    }
}

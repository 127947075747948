import { app as services } from "../app.module";
import { IApiService } from "./apiServiceProvider";
import { IPushNotificationsService, pushNotificationRegisteredEvent } from "./pushNotificationsService";
import { Token } from "@capacitor/push-notifications";

const serviceId = "pushNotificationsRegistrationService";

export interface IPushNotificationsRegistrationService {
    registerToken(): Promise<void>;
    unregisterToken(): Promise<void>;
}
function PushNotificationsRegistrationService($rootScope: angular.IRootScopeService, $log: angular.ILogService, pushNotificationsService: IPushNotificationsService, apiService: IApiService) {
    "ngInject";

    var pushNotificationRegistrationTimeoutId;

    return {
        registerToken: registerToken,
        unregisterToken: unregisterToken
    };

    async function registerToken() {
        const token = pushNotificationsService.getToken();

        if (token) {
            $log.debug("PushNotificationsRegistration - Re-register the user into the push notification.");
            registerPushNotificationToken(token);
        } else {
            $log.debug("PushNotificationsRegistration - Register the user into the push notification.");
        }

        var unregisteredEvent = $rootScope.$on(pushNotificationRegisteredEvent, function (event, token: Token) {
            registerPushNotificationToken(token.value);
        });

        $rootScope.$on("$destroy", function () {
            unregisteredEvent();
        });

        await pushNotificationsService.register();
    }

    async function unregisterToken() {
        const token = pushNotificationsService.getToken();
        if (token) {
            $log.debug("PushNotificationsRegistration - Unregister the user from push notification.");
            await apiService.unregisterPushNotification(token);
        } else {
            $log.debug("PushNotificationsRegistration - The user is not unregister from the push notification.");
        }
    }

    function registerPushNotificationToken(token: string) {
        clearTimeout(pushNotificationRegistrationTimeoutId);
        pushNotificationRegistrationTimeoutId = setTimeout(async () => {
            $log.debug("PushNotificationsRegistration - Save the user registration .");
            await apiService.registerPushNotification(token);
        }, 1000);
    }
}

services.factory(serviceId, PushNotificationsRegistrationService);

import { app } from "../app.module";
import angular from "angular";
app.directive("loadingPane", function () {
    return {
        restrict: "A",
        link: function (scope, element, attr) {
            var directiveId = "loadingPane";

            var targetElement;

            var overlayElement;
            var spinnerElement;

            function init(element) {
                targetElement = element;

                overlayElement = angular.element("<div>");
                overlayElement.addClass("overlay");

                spinnerElement = angular.element("<div>");
                spinnerElement.addClass("overlay-spinner");

                targetElement.append(overlayElement);
                targetElement.append(spinnerElement);
            }

            function updateVisibility(isVisible) {
                if (isVisible) {
                    show();
                } else {
                    hide();
                }
            }

            function show() {
                overlayElement.css("display", "block");
                spinnerElement.css("display", "block");
            }

            function hide() {
                overlayElement.css("display", "none");
                spinnerElement.css("display", "none");
            }

            init(element);

            scope.$watch(attr[directiveId], function (newVal) {
                updateVisibility(newVal);
            });

            scope.$on("$destroy", function cleanup() {
                overlayElement.remove();
                spinnerElement.remove();
            });
        }
    };
});

import { app } from "../../app.module";
require("./widgetFeatureUnavailable.less");
import templateUrl from "./widgetFeatureUnavailable.html";

app.directive("widgetFeatureUnavailable", function () {
    return {
        restrict: "A",
        templateUrl,
        scope: {
            featureName: "@"
        }
    };
});

// import * as _ from 'underscore';
import { app } from "./app.module";
import "./services/common";
import { ENABLE_CONSOLE_LOGGING } from "./configuration";
import { ILocationProvider, ILogProvider } from "angular";

app.config([
    "commonConfigProvider",
    function (cfg) {
        cfg.config.controllerActivatedEvent = "app.controller-activated";
        cfg.config.controllerActivatingEvent = "app.controller-activating";
    }
]);

app.config([
    "$logProvider",
    function ($logProvider: ILogProvider) {
        "ngInject";
        $logProvider.debugEnabled(ENABLE_CONSOLE_LOGGING);
    }
]);

app.config([
    "cfpLoadingBarProvider",
    function (cfpLoadingBarProvider) {
        cfpLoadingBarProvider.includeSpinner = false;
    }
]);

app.config([
    "$locationProvider",
    function ($locationProvider: ILocationProvider) {
        "ngInject";

        // Set the hash prefix (e.g., for older browsers)
        $locationProvider.hashPrefix("");
    }
]);
// register underscore as a factory (assumes underscore has already been loaded on the page)
// app.constant('_', _ );

import {app} from '../app.module';
app
    .directive('phoneNumber', ['phoneService', function (phoneService) {
        var VALID_PREFIXES = ['02', '03', '07', '08', '13', '18'];

        return {
            require: 'ngModel',
            link: function (scope, elem, attr, ngModel) {
                ngModel.$parsers.unshift(checkForValidPhoneNumber);
                ngModel.$formatters.unshift(checkForValidPhoneNumber);

                function checkForValidPhoneNumber(phoneNumber) {
                    ngModel.$setValidity('phoneNumber', phoneService.isAValidPhoneNumber(phoneNumber, VALID_PREFIXES));
                    return phoneNumber;
                }
            }
        };
    }]);

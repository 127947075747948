import {app} from "../../app.module";
import {ConsentResponseState, consentResponseStateDescription} from "../../models/enums";
import templateUrl from "./consentRequestSummary.html";

export type ConsentRequest = {
  id: string;
  studentFullName: string;
  title: string;
  withdrawnDate: string;
  dueDate: string;
  responseDate: string;
  state: ConsentResponseState;
  schoolName: string;
  studentId: string;
  schoolCode: string;
};

export class ConsentRequestSummary {
  chipBackgroundColor
  stateDescription
  state: ConsentResponseState
  isPending: boolean
  withdrawnDate: string
  dueDate: string
  responseDate: string
  date: string
  goToRequest?: () => void
  clickable: boolean

  constructor() {
    "ngInject";
    this.update()
  }

  $onChanges() {
    this.update()
  }

  private update() {
    this.chipBackgroundColor = getBackgroundColorFromState(this.state)
    this.stateDescription = consentResponseStateDescription(this.state)
    this.isPending = this.state === ConsentResponseState.Pending
    this.date = this.state === ConsentResponseState.Withdrawn
      ? this.withdrawnDate
      : this.state === ConsentResponseState.Expired || this.state === ConsentResponseState.Pending
        ? this.dueDate
        : this.responseDate
  }
}


const controllerId = "consentRequestSummary"
app.component(`${controllerId}Component`, {
  templateUrl,
  controller: ConsentRequestSummary,
  controllerAs: "vm",
  bindings: {
    id: '@',
    studentName: "@",
    schoolName: "@",
    requestTitle: "@",
    withdrawnDate: "<",
    dueDate: "<",
    responseDate: "<",
    state: "<",
    goToRequest: "&",
    clickable: "<"
  }
});

export const getBackgroundColorFromState = (state: ConsentResponseState) => {
  switch (state) {
    case ConsentResponseState.Pending:
      return "pending";
    case ConsentResponseState.Granted:
      return "granted";
    case ConsentResponseState.Denied:
      return "denied";
    case ConsentResponseState.Mixed:
      return "mixed";
    case ConsentResponseState.Expired:
      return "expired";
    case ConsentResponseState.Withdrawn:
      return "withdrawn";
  }
}

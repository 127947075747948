import { app } from "../app.module";
import { FEATURES } from "../configuration";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import { AbsenceRecordViewModel, SubmitReasonWriteModel } from "../models/api";
import { IApiService } from "../services/apiServiceProvider";
import templateUrl from "./reportReason.html";
import { EnrolmentStatus } from "../models/enums";

const REASON_MAXLENGTH = 89;

const controllerId = "reportReason";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: ReportReasonComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

interface IReportReasonScope {
    form: ng.IFormController;
}

class ReportReasonComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    absenceRecord: AbsenceRecordViewModel;
    reasonCharactersRemaining: number;
    reason: string;
    busy: boolean;
    reasonSubmitted: boolean;
    isFeatureAvailable: boolean;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        authorisationService: AuthorisationService,
        private apiService: IApiService,
        private $location: ng.ILocationService,
        private $timeout: ng.ITimeoutService,
        private $scope: IReportReasonScope,
        private $q: ng.IQService
    ) {
        "ngInject";
        const absenceId = $routeParams.absenceId;

        this.isFeatureAvailable = true;

        contextLoader.load(this, $routeParams, "reportReason").then(() => {
            if (!authorisationService.hasFeatureAccess(this, FEATURES.ATTENDANCE) || this.isDelegatedViewer()) {
                this.isFeatureAvailable = false;
                return;
            }

            // FutureStudentNoAbsence - Currently, Absence cannot be recorded for Future Student
            if (this.enrolment?.enrolmentStatus !== EnrolmentStatus.Active) {
                this.isFeatureAvailable = false;
                return;
            }

            this.absenceRecord = this.enrolment.getAbsenceRecordForId(absenceId);
            if (this.redirectIfAbsenceRecordNull()) {
                this.isFeatureAvailable = false;
                return;
            }

            this.setInitialFocus();
            this.onreasonChange(false);
        });
    }

    isDelegatedViewer() {
        if (!this.student.isUserPPAOForStudent) {
            var newPath = "/featureUnavailable";
            this.$location.path(newPath);
            return true;
        }
        return false;
    }

    redirectIfAbsenceRecordNull() {
        if (this.absenceRecord == null) {
            var newPath = `/attendance/${this.student.oneSchoolId}/${this.enrolment.schoolCode}`;
            this.$location.path(newPath);
            return true;
        }
        return false;
    }

    setInitialFocus() {
        this.$timeout(function () {
            document.getElementById("reason").focus();
        }, 100);
    }

    onreasonChange(hasReachedMaxlength) {
        if (hasReachedMaxlength) {
            this.reasonCharactersRemaining = -1;
        } else {
            var reasonLength = this.reason ? this.reason.length : 0;
            this.reasonCharactersRemaining = REASON_MAXLENGTH - reasonLength;
        }
    }

    submitReason(reason) {
        if (this.$scope.form.$invalid) return;
        if (this.busy) return;

        const deferred = this.$q.defer();
        this.busy = true;

        const data: SubmitReasonWriteModel = {
            eQId: this.student.oneSchoolId,
            studentAbsenceId: this.absenceRecord.studentAbsenceId,
            absenceDate: this.absenceRecord.absenceDate,
            reason: reason
        };

        this.apiService
            .submitAbsenceReason(data)
            .then(() => {
                this.reasonSubmitted = true;
                this.absenceRecord.reasonAwaitingApproval = true;
            })
            .finally(() => {
                this.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    loadImage(imagePath: string) {
        return require("../../content/img/" + imagePath);
    }
}

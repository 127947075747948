import { RegisterRequest, RegisterResponse } from "@/app/models/api";
import { app } from "../../app.module";
import { SESSION_AO_CODE_KEY } from "../../configuration";
import "./register.less";

const controllerId = "accountOwnerRegister";
import templateUrl from "./register.html";
import { IApiService } from "@/app/services/apiServiceProvider";
import { ILocationService, ILogService, IQService } from "angular";
import { ILocalStorageService } from "angular-local-storage";

app.controller(controllerId, accountOwnerRegister);

app.component(`${controllerId}Component`, {
    templateUrl: templateUrl,
    bindings: {
        registrationContext: "="
    },
    controller: controllerId,
    controllerAs: "vm"
});

function accountOwnerRegister(
    $rootScope,
    $log: ILogService,
    $route: angular.route.IRouteService,
    $window,
    $location: ILocationService,
    $q: IQService,
    apiService: IApiService,
    localStorageService: ILocalStorageService
) {
    "ngInject";
    var vm = this;
    var sessionId = null;

    vm.registrationContext = $route.current.locals.registrationContext;
    vm.mfaSessionId = null;
    vm.verified = false;
    vm.message = null;

    vm.registerBusy = false;
    vm.registration = {};
    vm.showConfirmationMessage = false;
    vm.showEmailAlreadyRegistered = false;
    vm.submitButtonText = "Submit";
    vm.showToken = false;
    vm.statusMessage = "";
    vm.errorMessage = "";

    vm.showRegister = false;
    vm.showAlreadyLoggedInMessage = false;
    vm.showAlreadyRegisteredAccountOwner = false;

    vm.cancel = cancel;
    vm.submit = submit;
    vm.navigateHome = navigateHome;
    vm.retryMfa = retryMfa;

    initialise();

    function initialise() {
        var sessionToken = getSessionToken();
        if (!sessionToken) {
            $location.path("/");
            return;
        }

        sessionId = sessionToken;

        if (vm.registrationContext.verified && vm.registrationContext.wasLoggedIn) {
            vm.showAlreadyLoggedInMessage = true;
            vm.heading = "Congratulations";
            vm.verified = vm.registrationContext.verified;

            removeSessionToken();
        } else {
            vm.verified = vm.registrationContext.verified;
            vm.message = vm.registrationContext.message;
            vm.showRegister = true;
            vm.heading = "Create an account";
        }

        $rootScope.hideFullScreenLoadingPane();
    }

    function retryMfa() {
        vm.mfaSessionId = null;
        submit(vm.registration);
    }

    function cancel() {
        removeSessionToken();
        $location.path("/");
    }

    function submit(registration: RegisterRequest) {
        if (vm.registerBusy) return;
        const deferred = $q.defer();

        vm.registerBusy = true;
        
        // R Check registration.mfaCode , Coluld not locate assignment of registration.mfaCode
        console.log('Submit Registration')

        vm.showEmailAlreadyRegistered = false;
        registration.sessionId = sessionId;
        registration.mfaSessionId = vm.mfaSessionId;

        vm.statusMessage = "";
        apiService
            .accountOwnerRegister(registration)
            .then(function (response) {
                const data = response.data as RegisterResponse;
                if (data.emailAlreadyRegistered) {
                    vm.showEmailAlreadyRegistered = true;
                    vm.submitButtonText = "Retry";
                } else if (data.mfaRequired) {
                    vm.mfaSessionId = data.mfaSessionId;
                    vm.showToken = true;
                    vm.statusMessage = data.statusMessage;
                    vm.errorMessage = data.errorMessage;
                } else {
                    vm.mfaSessionId = null;
                    vm.showToken = false;

                    removeSessionToken();

                    vm.showConfirmationMessage = true;
                }
            })
            .catch(response => {
                $log.error("Registration Failure", response);
                deferred.reject();
            })
            .finally(function () {
                vm.registerBusy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    function getSessionToken() {
        return localStorageService.get(SESSION_AO_CODE_KEY);
    }

    function removeSessionToken() {
        localStorageService.remove(SESSION_AO_CODE_KEY);
    }

    function navigateHome() {
        removeSessionToken();
        $window.location = "/";
    }
}

import { app } from "../app.module";
import { IUrlResolver } from "../services/urlResolver";
import { TERMS_AND_CONDITION_LABELS } from "../configuration";
import { AdminViewRedirectService } from "../services/adminViewRedirectService";
import { IDeviceService } from "../services/deviceServiceProvider";
import { IApiService } from "../services/apiServiceProvider";

const controllerId = "termsAndConditions";
import templateUrl from "./termsAndConditions.html";
import { ICommonService } from "../services/common";
import { ILocationService, ISCEService } from "angular";
import { IDataContext } from "../services/dataContext";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: termsAndConditions,
    controllerAs: "vm",
    restrict: "E",
    replace: true
}));

function termsAndConditions(
    common: ICommonService,
    dataContext: IDataContext,
    $sce: ISCEService,
    $location: ILocationService,
    adminViewRedirectService: AdminViewRedirectService,
    apiService: IApiService,
    deviceService: IDeviceService,
    urlResolver: IUrlResolver
) {
    "ngInject";
    var vm = this;
    vm.declineTermsAndConditions = declineTermsAndConditions;
    vm.pendingTermsAndConditions = [];
    vm.acceptedAll = false;
    vm.isMobileApp = deviceService.isMobileApp;
    vm.checkIfAllAccepted = checkIfAllAccepted;
    vm.acceptTermsAndConditions = acceptTermsAndConditions;

    common.activateController([getUser()], controllerId).then(function () {});

    function getUser() {
        return dataContext.getData().then(function (user) {
            vm.pendingTermsAndConditions = user.pendingTermsAndConditions.map(function (key) {
                return {
                    url: $sce.trustAsResourceUrl(urlResolver.resolve("/api/termsandconditions/" + key)),
                    checked: false,
                    key: key,
                    label: getCheckboxLabelForTermsAndCondition(key)
                };
            });
            if (vm.isMobileApp) {
                fetchTermsAndConditions();
            }
        });
    }

    function getCheckboxLabelForTermsAndCondition(key) {
        var type = key.split("-")[0].toUpperCase();
        return TERMS_AND_CONDITION_LABELS[type];
    }

    function checkIfAllAccepted() {
        vm.acceptedAll = vm.pendingTermsAndConditions.every(function (tc: any) {
            return tc.checked;
        });
    }

    function acceptTermsAndConditions() {
        apiService.acceptPendingTermsAndConditions().then(function successCallback() {
            dataContext.verifyAuthenticated().then(function () {
                $location.path("/");
            });
        });
    }

    function declineTermsAndConditions() {
        return dataContext.flagSignedOut(false);
    }

    function fetchTermsAndConditions() {
        vm.pendingTermsAndConditions.forEach(function (toc) {
            apiService.getTermsAndConditions(toc.key).then(function successCallback(resp) {
                toc.content = resp.data;
            });
        });
    }
}

import {app} from '../app.module';
app

    .directive('qpTooltip', function () {
        return {
            restrict: 'EA',
            transclude: true,
            scope: {
                linkText: '@',
                className: '@'
            },
            template: '<div class="tooltip">\n    <label class="{{className}}" ng-click="vm.toggle()">{{linkText}}</label>\n    <div ng-show="vm.visible">\n        <div>\n            <span ng-transclude></span>\n            <label class="icon" ng-click="vm.toggle()">&#x2421;</label>\n        </div>\n\n    </div>\n</div>',
            controllerAs: 'vm',
            controller:  function ($rootScope, $scope) {
                "ngInject";
                var vm = this;
                var eventName = 'tooltip-showing';

                vm.visible = false;
                vm.toggle = toggle;

                var unbindOnTooltipShowing = $rootScope.$on(eventName, onTooltipShowing);
                $scope.$on('$destroy', unbindOnTooltipShowing);

                function toggle() {
                    var isVisible = !vm.visible;

                    if (isVisible) {
                        $rootScope.$broadcast(eventName);
                    }

                    vm.visible = isVisible;
                }

                function onTooltipShowing() {
                    vm.visible = false;
                }
            }
        };
    });

export enum VerifyAccountOwnerInvitationCodeResultStatusCode {
    Valid = 0,
    Invalid = 1,
    AlreadyUsed = 2,
    AlreadyRegistered = 3
}
export function verifyAccountOwnerInvitationCodeResultStatusCodeDescription(value: VerifyAccountOwnerInvitationCodeResultStatusCode) {
    switch (value) {
        case VerifyAccountOwnerInvitationCodeResultStatusCode.Valid:
            return `Valid`;
        case VerifyAccountOwnerInvitationCodeResultStatusCode.Invalid:
            return `Invalid`;
        case VerifyAccountOwnerInvitationCodeResultStatusCode.AlreadyUsed:
            return `AlreadyUsed`;
        case VerifyAccountOwnerInvitationCodeResultStatusCode.AlreadyRegistered:
            return `AlreadyRegistered`;
    }
}
export interface IVerifyAccountOwnerInvitationCodeResultStatusCodeDescriptor { value: VerifyAccountOwnerInvitationCodeResultStatusCode; name: string; description: string; }
export var allVerifyAccountOwnerInvitationCodeResultStatusCode: IVerifyAccountOwnerInvitationCodeResultStatusCodeDescriptor[] = [
    { value: VerifyAccountOwnerInvitationCodeResultStatusCode.Valid, name: `Valid`, description: `Valid` },
    { value: VerifyAccountOwnerInvitationCodeResultStatusCode.Invalid, name: `Invalid`, description: `Invalid` },
    { value: VerifyAccountOwnerInvitationCodeResultStatusCode.AlreadyUsed, name: `AlreadyUsed`, description: `AlreadyUsed` },
    { value: VerifyAccountOwnerInvitationCodeResultStatusCode.AlreadyRegistered, name: `AlreadyRegistered`, description: `AlreadyRegistered` }
];

export function getVerifyAccountOwnerInvitationCodeResultStatusCodeDescriptor(value: VerifyAccountOwnerInvitationCodeResultStatusCode) {
    switch (value) {
        case VerifyAccountOwnerInvitationCodeResultStatusCode.Valid:
            return { value: VerifyAccountOwnerInvitationCodeResultStatusCode.Valid, name: `Valid`, description: `Valid` };
        case VerifyAccountOwnerInvitationCodeResultStatusCode.Invalid:
            return { value: VerifyAccountOwnerInvitationCodeResultStatusCode.Invalid, name: `Invalid`, description: `Invalid` };
        case VerifyAccountOwnerInvitationCodeResultStatusCode.AlreadyUsed:
            return { value: VerifyAccountOwnerInvitationCodeResultStatusCode.AlreadyUsed, name: `AlreadyUsed`, description: `AlreadyUsed` };
        case VerifyAccountOwnerInvitationCodeResultStatusCode.AlreadyRegistered:
            return { value: VerifyAccountOwnerInvitationCodeResultStatusCode.AlreadyRegistered, name: `AlreadyRegistered`, description: `AlreadyRegistered` };
    }
}
export enum ContactType {
    PhoneWork = 0,
    PhoneHome = 1,
    MobilePersonal = 2,
    MobileWork = 3,
    Fax = 4,
    PhoneInternational = 5,
    Email = 6,
    NullableMobilePersonal = 7
}
export function contactTypeDescription(value: ContactType) {
    switch (value) {
        case ContactType.PhoneWork:
            return `Phone Work`;
        case ContactType.PhoneHome:
            return `Phone Home`;
        case ContactType.MobilePersonal:
            return `Mobile`;
        case ContactType.MobileWork:
            return `Mobile Work`;
        case ContactType.Fax:
            return `Fax`;
        case ContactType.PhoneInternational:
            return `Phone International`;
        case ContactType.Email:
            return `Email`;
        case ContactType.NullableMobilePersonal:
            return `Mobile`;
    }
}
export interface IContactTypeDescriptor { value: ContactType; name: string; description: string; }
export var allContactType: IContactTypeDescriptor[] = [
    { value: ContactType.PhoneWork, name: `PhoneWork`, description: `Phone Work` },
    { value: ContactType.PhoneHome, name: `PhoneHome`, description: `Phone Home` },
    { value: ContactType.MobilePersonal, name: `MobilePersonal`, description: `Mobile` },
    { value: ContactType.MobileWork, name: `MobileWork`, description: `Mobile Work` },
    { value: ContactType.Fax, name: `Fax`, description: `Fax` },
    { value: ContactType.PhoneInternational, name: `PhoneInternational`, description: `Phone International` },
    { value: ContactType.Email, name: `Email`, description: `Email` },
    { value: ContactType.NullableMobilePersonal, name: `NullableMobilePersonal`, description: `Mobile` }
];

export function getContactTypeDescriptor(value: ContactType) {
    switch (value) {
        case ContactType.PhoneWork:
            return { value: ContactType.PhoneWork, name: `PhoneWork`, description: `Phone Work` };
        case ContactType.PhoneHome:
            return { value: ContactType.PhoneHome, name: `PhoneHome`, description: `Phone Home` };
        case ContactType.MobilePersonal:
            return { value: ContactType.MobilePersonal, name: `MobilePersonal`, description: `Mobile` };
        case ContactType.MobileWork:
            return { value: ContactType.MobileWork, name: `MobileWork`, description: `Mobile Work` };
        case ContactType.Fax:
            return { value: ContactType.Fax, name: `Fax`, description: `Fax` };
        case ContactType.PhoneInternational:
            return { value: ContactType.PhoneInternational, name: `PhoneInternational`, description: `Phone International` };
        case ContactType.Email:
            return { value: ContactType.Email, name: `Email`, description: `Email` };
        case ContactType.NullableMobilePersonal:
            return { value: ContactType.NullableMobilePersonal, name: `NullableMobilePersonal`, description: `Mobile` };
    }
}
export enum EnrolmentStatus {
    Active = 0,
    Deceased = 1,
    Exempt = 2,
    Future = 3,
    Inactive = 4,
    Left = 5,
    NonAttendee = 6
}
export function enrolmentStatusDescription(value: EnrolmentStatus) {
    switch (value) {
        case EnrolmentStatus.Active:
            return `A`;
        case EnrolmentStatus.Deceased:
            return `D`;
        case EnrolmentStatus.Exempt:
            return `E`;
        case EnrolmentStatus.Future:
            return `F`;
        case EnrolmentStatus.Inactive:
            return `I`;
        case EnrolmentStatus.Left:
            return `L`;
        case EnrolmentStatus.NonAttendee:
            return `N`;
    }
}
export interface IEnrolmentStatusDescriptor { value: EnrolmentStatus; name: string; description: string; }
export var allEnrolmentStatus: IEnrolmentStatusDescriptor[] = [
    { value: EnrolmentStatus.Active, name: `Active`, description: `A` },
    { value: EnrolmentStatus.Deceased, name: `Deceased`, description: `D` },
    { value: EnrolmentStatus.Exempt, name: `Exempt`, description: `E` },
    { value: EnrolmentStatus.Future, name: `Future`, description: `F` },
    { value: EnrolmentStatus.Inactive, name: `Inactive`, description: `I` },
    { value: EnrolmentStatus.Left, name: `Left`, description: `L` },
    { value: EnrolmentStatus.NonAttendee, name: `NonAttendee`, description: `N` }
];

export function getEnrolmentStatusDescriptor(value: EnrolmentStatus) {
    switch (value) {
        case EnrolmentStatus.Active:
            return { value: EnrolmentStatus.Active, name: `Active`, description: `A` };
        case EnrolmentStatus.Deceased:
            return { value: EnrolmentStatus.Deceased, name: `Deceased`, description: `D` };
        case EnrolmentStatus.Exempt:
            return { value: EnrolmentStatus.Exempt, name: `Exempt`, description: `E` };
        case EnrolmentStatus.Future:
            return { value: EnrolmentStatus.Future, name: `Future`, description: `F` };
        case EnrolmentStatus.Inactive:
            return { value: EnrolmentStatus.Inactive, name: `Inactive`, description: `I` };
        case EnrolmentStatus.Left:
            return { value: EnrolmentStatus.Left, name: `Left`, description: `L` };
        case EnrolmentStatus.NonAttendee:
            return { value: EnrolmentStatus.NonAttendee, name: `NonAttendee`, description: `N` };
    }
}
export enum EventType {
    Exam = 0,
    Assessment = 1,
    Excursion = 2,
    School = 3
}
export function eventTypeDescription(value: EventType) {
    switch (value) {
        case EventType.Exam:
            return `Exam`;
        case EventType.Assessment:
            return `Assessment`;
        case EventType.Excursion:
            return `Excursion`;
        case EventType.School:
            return `School`;
    }
}
export interface IEventTypeDescriptor { value: EventType; name: string; description: string; }
export var allEventType: IEventTypeDescriptor[] = [
    { value: EventType.Exam, name: `Exam`, description: `Exam` },
    { value: EventType.Assessment, name: `Assessment`, description: `Assessment` },
    { value: EventType.Excursion, name: `Excursion`, description: `Excursion` },
    { value: EventType.School, name: `School`, description: `School` }
];

export function getEventTypeDescriptor(value: EventType) {
    switch (value) {
        case EventType.Exam:
            return { value: EventType.Exam, name: `Exam`, description: `Exam` };
        case EventType.Assessment:
            return { value: EventType.Assessment, name: `Assessment`, description: `Assessment` };
        case EventType.Excursion:
            return { value: EventType.Excursion, name: `Excursion`, description: `Excursion` };
        case EventType.School:
            return { value: EventType.School, name: `School`, description: `School` };
    }
}
export enum MfaMedium {
    SMS = 1,
    Email = 2
}
export function mfaMediumDescription(value: MfaMedium) {
    switch (value) {
        case MfaMedium.SMS:
            return `SMS`;
        case MfaMedium.Email:
            return `Email`;
    }
}
export interface IMfaMediumDescriptor { value: MfaMedium; name: string; description: string; }
export var allMfaMedium: IMfaMediumDescriptor[] = [
    { value: MfaMedium.SMS, name: `SMS`, description: `SMS` },
    { value: MfaMedium.Email, name: `Email`, description: `Email` }
];

export function getMfaMediumDescriptor(value: MfaMedium) {
    switch (value) {
        case MfaMedium.SMS:
            return { value: MfaMedium.SMS, name: `SMS`, description: `SMS` };
        case MfaMedium.Email:
            return { value: MfaMedium.Email, name: `Email`, description: `Email` };
    }
}
export enum Feature {
    EnrolmentHistory = 0,
    Behaviour = 1,
    Attendance = 2,
    Timetable = 3,
    Finance = 4,
    AcademicReports = 5,
    StudentDetails = 6,
    StudentPhoto = 7,
    AssessmentPlanner = 8,
    ExamTimetable = 9,
    UpcomingEvents = 10,
    ConsentManagement = 11
}
export function featureDescription(value: Feature) {
    switch (value) {
        case Feature.EnrolmentHistory:
            return `Enrolment History`;
        case Feature.Behaviour:
            return `Behaviour`;
        case Feature.Attendance:
            return `Attendance`;
        case Feature.Timetable:
            return `Timetable`;
        case Feature.Finance:
            return `Finance`;
        case Feature.AcademicReports:
            return `Academic Reports`;
        case Feature.StudentDetails:
            return `Student Details`;
        case Feature.StudentPhoto:
            return `Student Photo`;
        case Feature.AssessmentPlanner:
            return `Assessment Planner`;
        case Feature.ExamTimetable:
            return `Exam Timetable`;
        case Feature.UpcomingEvents:
            return `Upcoming Events`;
        case Feature.ConsentManagement:
            return `Consent Requests`;
    }
}
export interface IFeatureDescriptor { value: Feature; name: string; description: string; }
export var allFeature: IFeatureDescriptor[] = [
    { value: Feature.EnrolmentHistory, name: `EnrolmentHistory`, description: `Enrolment History` },
    { value: Feature.Behaviour, name: `Behaviour`, description: `Behaviour` },
    { value: Feature.Attendance, name: `Attendance`, description: `Attendance` },
    { value: Feature.Timetable, name: `Timetable`, description: `Timetable` },
    { value: Feature.Finance, name: `Finance`, description: `Finance` },
    { value: Feature.AcademicReports, name: `AcademicReports`, description: `Academic Reports` },
    { value: Feature.StudentDetails, name: `StudentDetails`, description: `Student Details` },
    { value: Feature.StudentPhoto, name: `StudentPhoto`, description: `Student Photo` },
    { value: Feature.AssessmentPlanner, name: `AssessmentPlanner`, description: `Assessment Planner` },
    { value: Feature.ExamTimetable, name: `ExamTimetable`, description: `Exam Timetable` },
    { value: Feature.UpcomingEvents, name: `UpcomingEvents`, description: `Upcoming Events` },
    { value: Feature.ConsentManagement, name: `ConsentManagement`, description: `Consent Requests` }
];

export function getFeatureDescriptor(value: Feature) {
    switch (value) {
        case Feature.EnrolmentHistory:
            return { value: Feature.EnrolmentHistory, name: `EnrolmentHistory`, description: `Enrolment History` };
        case Feature.Behaviour:
            return { value: Feature.Behaviour, name: `Behaviour`, description: `Behaviour` };
        case Feature.Attendance:
            return { value: Feature.Attendance, name: `Attendance`, description: `Attendance` };
        case Feature.Timetable:
            return { value: Feature.Timetable, name: `Timetable`, description: `Timetable` };
        case Feature.Finance:
            return { value: Feature.Finance, name: `Finance`, description: `Finance` };
        case Feature.AcademicReports:
            return { value: Feature.AcademicReports, name: `AcademicReports`, description: `Academic Reports` };
        case Feature.StudentDetails:
            return { value: Feature.StudentDetails, name: `StudentDetails`, description: `Student Details` };
        case Feature.StudentPhoto:
            return { value: Feature.StudentPhoto, name: `StudentPhoto`, description: `Student Photo` };
        case Feature.AssessmentPlanner:
            return { value: Feature.AssessmentPlanner, name: `AssessmentPlanner`, description: `Assessment Planner` };
        case Feature.ExamTimetable:
            return { value: Feature.ExamTimetable, name: `ExamTimetable`, description: `Exam Timetable` };
        case Feature.UpcomingEvents:
            return { value: Feature.UpcomingEvents, name: `UpcomingEvents`, description: `Upcoming Events` };
        case Feature.ConsentManagement:
            return { value: Feature.ConsentManagement, name: `ConsentManagement`, description: `Consent Requests` };
    }
}
export enum OneSchoolPaymentStatus {
    Open = 0,
    Closed = 1
}
export function oneSchoolPaymentStatusDescription(value: OneSchoolPaymentStatus) {
    switch (value) {
        case OneSchoolPaymentStatus.Open:
            return `Open`;
        case OneSchoolPaymentStatus.Closed:
            return `Closed`;
    }
}
export interface IOneSchoolPaymentStatusDescriptor { value: OneSchoolPaymentStatus; name: string; description: string; }
export var allOneSchoolPaymentStatus: IOneSchoolPaymentStatusDescriptor[] = [
    { value: OneSchoolPaymentStatus.Open, name: `Open`, description: `Open` },
    { value: OneSchoolPaymentStatus.Closed, name: `Closed`, description: `Closed` }
];

export function getOneSchoolPaymentStatusDescriptor(value: OneSchoolPaymentStatus) {
    switch (value) {
        case OneSchoolPaymentStatus.Open:
            return { value: OneSchoolPaymentStatus.Open, name: `Open`, description: `Open` };
        case OneSchoolPaymentStatus.Closed:
            return { value: OneSchoolPaymentStatus.Closed, name: `Closed`, description: `Closed` };
    }
}
export enum ConsentResponseState {
    Pending = 1,
    Granted = 2,
    Denied = 3,
    Mixed = 4,
    Withdrawn = 5,
    Expired = 6
}
export function consentResponseStateDescription(value: ConsentResponseState) {
    switch (value) {
        case ConsentResponseState.Pending:
            return `Pending`;
        case ConsentResponseState.Granted:
            return `Granted`;
        case ConsentResponseState.Denied:
            return `Denied`;
        case ConsentResponseState.Mixed:
            return `Mixed`;
        case ConsentResponseState.Withdrawn:
            return `Withdrawn`;
        case ConsentResponseState.Expired:
            return `Expired`;
    }
}
export interface IConsentResponseStateDescriptor { value: ConsentResponseState; name: string; description: string; }
export var allConsentResponseState: IConsentResponseStateDescriptor[] = [
    { value: ConsentResponseState.Pending, name: `Pending`, description: `Pending` },
    { value: ConsentResponseState.Granted, name: `Granted`, description: `Granted` },
    { value: ConsentResponseState.Denied, name: `Denied`, description: `Denied` },
    { value: ConsentResponseState.Mixed, name: `Mixed`, description: `Mixed` },
    { value: ConsentResponseState.Withdrawn, name: `Withdrawn`, description: `Withdrawn` },
    { value: ConsentResponseState.Expired, name: `Expired`, description: `Expired` }
];

export function getConsentResponseStateDescriptor(value: ConsentResponseState) {
    switch (value) {
        case ConsentResponseState.Pending:
            return { value: ConsentResponseState.Pending, name: `Pending`, description: `Pending` };
        case ConsentResponseState.Granted:
            return { value: ConsentResponseState.Granted, name: `Granted`, description: `Granted` };
        case ConsentResponseState.Denied:
            return { value: ConsentResponseState.Denied, name: `Denied`, description: `Denied` };
        case ConsentResponseState.Mixed:
            return { value: ConsentResponseState.Mixed, name: `Mixed`, description: `Mixed` };
        case ConsentResponseState.Withdrawn:
            return { value: ConsentResponseState.Withdrawn, name: `Withdrawn`, description: `Withdrawn` };
        case ConsentResponseState.Expired:
            return { value: ConsentResponseState.Expired, name: `Expired`, description: `Expired` };
    }
}
export enum ResultStatusCode {
    Valid = 0,
    Invalid = 1,
    CodeAlreadyUsed = 2,
    UserNotAccountOwner = 3
}
export function resultStatusCodeDescription(value: ResultStatusCode) {
    switch (value) {
        case ResultStatusCode.Valid:
            return `Valid`;
        case ResultStatusCode.Invalid:
            return `Invalid`;
        case ResultStatusCode.CodeAlreadyUsed:
            return `CodeAlreadyUsed`;
        case ResultStatusCode.UserNotAccountOwner:
            return `UserNotAccountOwner`;
    }
}
export interface IResultStatusCodeDescriptor { value: ResultStatusCode; name: string; description: string; }
export var allResultStatusCode: IResultStatusCodeDescriptor[] = [
    { value: ResultStatusCode.Valid, name: `Valid`, description: `Valid` },
    { value: ResultStatusCode.Invalid, name: `Invalid`, description: `Invalid` },
    { value: ResultStatusCode.CodeAlreadyUsed, name: `CodeAlreadyUsed`, description: `CodeAlreadyUsed` },
    { value: ResultStatusCode.UserNotAccountOwner, name: `UserNotAccountOwner`, description: `UserNotAccountOwner` }
];

export function getResultStatusCodeDescriptor(value: ResultStatusCode) {
    switch (value) {
        case ResultStatusCode.Valid:
            return { value: ResultStatusCode.Valid, name: `Valid`, description: `Valid` };
        case ResultStatusCode.Invalid:
            return { value: ResultStatusCode.Invalid, name: `Invalid`, description: `Invalid` };
        case ResultStatusCode.CodeAlreadyUsed:
            return { value: ResultStatusCode.CodeAlreadyUsed, name: `CodeAlreadyUsed`, description: `CodeAlreadyUsed` };
        case ResultStatusCode.UserNotAccountOwner:
            return { value: ResultStatusCode.UserNotAccountOwner, name: `UserNotAccountOwner`, description: `UserNotAccountOwner` };
    }
}
export enum StudentState {
    Invalid = 0,
    MatchAcceptedApprovalPending = 1,
    AlreadyMatchedAndApproved = 2,
    AlreadyMatchedApprovalPending = 3
}
export function studentStateDescription(value: StudentState) {
    switch (value) {
        case StudentState.Invalid:
            return `Invalid`;
        case StudentState.MatchAcceptedApprovalPending:
            return `MatchAcceptedApprovalPending`;
        case StudentState.AlreadyMatchedAndApproved:
            return `AlreadyMatchedAndApproved`;
        case StudentState.AlreadyMatchedApprovalPending:
            return `AlreadyMatchedApprovalPending`;
    }
}
export interface IStudentStateDescriptor { value: StudentState; name: string; description: string; }
export var allStudentState: IStudentStateDescriptor[] = [
    { value: StudentState.Invalid, name: `Invalid`, description: `Invalid` },
    { value: StudentState.MatchAcceptedApprovalPending, name: `MatchAcceptedApprovalPending`, description: `MatchAcceptedApprovalPending` },
    { value: StudentState.AlreadyMatchedAndApproved, name: `AlreadyMatchedAndApproved`, description: `AlreadyMatchedAndApproved` },
    { value: StudentState.AlreadyMatchedApprovalPending, name: `AlreadyMatchedApprovalPending`, description: `AlreadyMatchedApprovalPending` }
];

export function getStudentStateDescriptor(value: StudentState) {
    switch (value) {
        case StudentState.Invalid:
            return { value: StudentState.Invalid, name: `Invalid`, description: `Invalid` };
        case StudentState.MatchAcceptedApprovalPending:
            return { value: StudentState.MatchAcceptedApprovalPending, name: `MatchAcceptedApprovalPending`, description: `MatchAcceptedApprovalPending` };
        case StudentState.AlreadyMatchedAndApproved:
            return { value: StudentState.AlreadyMatchedAndApproved, name: `AlreadyMatchedAndApproved`, description: `AlreadyMatchedAndApproved` };
        case StudentState.AlreadyMatchedApprovalPending:
            return { value: StudentState.AlreadyMatchedApprovalPending, name: `AlreadyMatchedApprovalPending`, description: `AlreadyMatchedApprovalPending` };
    }
}

import { ILocationService, ILogService, IQService } from "angular";
import { app } from "../app.module";
import { IApiService } from "../services/apiServiceProvider";
import { IDeviceService } from "../services/deviceServiceProvider";
import { ICheckerService, check } from "../tools/outageChecker";
import templateUrl from "./outage.html";
import { ILocalStorageService } from "angular-local-storage";

app.factory("outageChecker", outageCheckerFactory);

function outageCheckerFactory($q: IQService, $location: ILocationService, $log: ILogService, $routeParams: angular.route.IRouteParamsService, deviceService: IDeviceService, apiService: IApiService, localStorageService: ILocalStorageService) {
    "ngInject";
    return check($location, $log, $q, $routeParams, apiService, deviceService, localStorageService);
}

app.directive("outageComponent", () => ({
    templateUrl,
    controller: outage,
    controllerAs: "vm",
    restrict: "E",
    replace: true,
    scope: true
}));

function outage($routeParams: angular.route.IRouteParamsService, outageChecker: ICheckerService, $location: ILocationService) {
    this.studentId = $routeParams.studentId;
    outageChecker.getOutage($routeParams.id).then(data => {
        this.data = data;
    });
    this.goHome = () => {
        $location.path("/");
    };
}

import { app } from "../app.module";
import { ICommonService } from "../services/common";

const controllerId = "studentNotFound";
const templateUrl = require("./studentNotFound.html");

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: studentNotFound,
    controllerAs: "vm",
    restrict: "E",
    replace: true
}));

class studentNotFound {
    constructor(common: ICommonService) {
        "ngInject";
        common.activateController([], controllerId);
    }
}

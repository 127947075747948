import { ILogService } from "angular";
import { app as services } from "../app.module";

services.factory("exceptionHandler", exceptionHandler);

export interface IExceptionHandler {
    addHandler(subHandler: (exception: any, cause: any) => void): void;
}

function exceptionHandler($log: ILogService): IExceptionHandler {
    "ngInject";
    var subHandlers = [];

    const handler: any = handlerFn;

    handler.addHandler = function (subHandler) {
        subHandlers.push(subHandler);
    };

    return handler;

    function handlerFn(exception, cause) {
        $log.error.apply($log, arguments);
        subHandlers.forEach(function (sh) {
            sh(exception, cause);
        });
    }
}

import { app } from "../app.module";
import { User } from "../models/user";
import { IDataContext } from "../services/dataContext";

import templateUrl from "./home.html";

app.component("homeComponent", {
    templateUrl: templateUrl,
    controller: home,
    controllerAs: "vm"
});

interface IHomeViewModel {
    user: User;
}

function home(dataContext: IDataContext, $rootScope) {
    "ngInject";
    var vm: IHomeViewModel = this;
    vm.user = null;

    initialise();

    function initialise() {
        dataContext
            .getData()
            .then(function (data) {
                vm.user = data;
            })
            .finally(function () {
                $rootScope.hideFullScreenLoadingPane();
            });
    }
}

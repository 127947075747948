// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../content/img/qLearnLogo.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div ng-if=\"vm.link.linkIsActiveWhenLoggedOut\" class=\"q-learn-app-message\"> <div class=\"wrapper\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"\"/> <div class=\"text\"> <p><b>QLearn</b></p> <p>Online student learning platform. Log in using your QParents Account.</p> <p> <a ng-href=\"{{vm.link.qLearnLink}}\" rel=\"noopener\" target=\"_blank\">Access QLearn</a> <a ng-href=\"{{vm.link.learnMoreLink}}\" rel=\"noopener\" target=\"_blank\">Learn more</a> </p> </div> </div> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/login/qLearnAppMessage/qLearnAppMessage.html';
window.angular.module('app').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
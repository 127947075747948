import { app } from "../app.module";
import { parseDate } from "../services/dateHelper";

// these two directives will perform cross date validation making sure that dateFrom =< dateTo
// one accesses the value of the other via an attribute and uses that value to compare it to
// its own value (the bound data and attribute value are monitored for changes)
app.directive("validateGreaterOrEqualDate", isGreaterOrEqualDateValidator);
app.directive("validateLessOrEqualDate", isLessOrEqualDateValidator);
app.directive("validateIsNotEmpty", isNotEmptyValidator);
app.directive("validateIsValidDate", isValidDateValidator);
app.directive("validateIsWeekDay", isWeekDayValidator);

function isLessOrEqualDate(date, maxDate) {
    const parsedMaxDate = parseDate(maxDate);
    const parsedDate = parseDate(date);

    if (parsedMaxDate && parsedDate) {
        return parsedMaxDate.isSameOrAfter(parsedDate);
    } else {
        return true;
    }
}

function isGreaterOrEqualDate(date, minDate) {
    const parsedMinDate = parseDate(minDate);
    const parsedDate = parseDate(date);

    if (parsedMinDate && parsedDate) {
        return parsedMinDate.isSameOrBefore(parsedDate);
    } else {
        return true;
    }
}

function isWeekDay(date) {
    const result = parseDate(date);

    return result !== null && result.isoWeekday() <= 5;
}

function isNotEmpty(date) {
    if (date) {
        if (typeof date === "string") {
            return date.trim() !== "";
        } else {
            return true;
        }
    }

    return false;
}

function isLessOrEqualDateValidator() {
    return {
        restrict: "A",
        require: "ngModel",
        link: (scope, element, attributes, control) => {
            control.$validators.isLessOrEqualDate = value => isLessOrEqualDate(value, attributes.validateLessOrEqualDate);

            scope.$watch("data", () => control.$validate(), true); // monitor input value for changes
            attributes.$observe("validateLessOrEqualDate", () => control.$validate()); // monitor attribute bound value for changes
        }
    };
}

function isGreaterOrEqualDateValidator() {
    return {
        restrict: "A",
        require: "ngModel",
        link: (scope, element, attributes, control) => {
            control.$validators.isGreaterOrEqualDate = value => isGreaterOrEqualDate(value, attributes.validateGreaterOrEqualDate);

            scope.$watch("data", () => control.$validate(), true);
            attributes.$observe("validateGreaterOrEqualDate", () => control.$validate());
        }
    };
}

function isNotEmptyValidator() {
    return {
        restrict: "A",
        require: "ngModel",
        link: (scope, element, attributes, control) => {
            control.$validators.isNotEmpty = value => {
                return isNotEmpty(value);
            };

            scope.$watch("data", () => control.$validate(), true); // monitor input value for changes
        }
    };
}

function isValidDateValidator() {
    return {
        restrict: "A",
        require: "ngModel",
        link: (scope, element, attributes, control) => {
            control.$validators.isValidDate = value => isNotEmpty(value) && parseDate(value) !== null;

            scope.$watch("data", () => control.$validate(), true); // monitor input value for changes
        }
    };
}

function isWeekDayValidator() {
    return {
        restrict: "A",
        require: "ngModel",
        link: (scope, element, attributes, control) => {
            control.$validators.isWeekDay = value => !value || isWeekDay(value);

            scope.$watch("data", () => control.$validate(), true); // monitor input value for changes
        }
    };
}

import angular, { ILocationService, ILogService, IQService } from "angular";
import { app } from "../app.module";

const controllerId = "other";
import templateUrl from "./other.html";
import { IDataContext } from "../services/dataContext";
import { User } from "../models/user";
import { ICommonService } from "../services/common";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: other,
    controllerAs: "vm",
    restrict: "E",
    replace: true
}));

function other(common: ICommonService, dataContext: IDataContext, $location: ILocationService, STATES, $q: IQService, $log: ILogService, $timeout) {
    "ngInject";
    var vm = this;
    var originalParent: User = null;

    vm.STATES = STATES;

    vm.parent = null;
    vm.postalAddressIsSameAsResidential = false;

    vm.save = save;
    vm.clearPostalAddress = clearPostalAddress;
    vm.busy = false;
    vm.error = false;

    common.activateController([getProfile()], controllerId).then(function () {});

    function getProfile() {
        return dataContext.getData().then(function (user) {
            if (isUnauthorised(user)) return;

            originalParent = user;
            vm.parent = angular.copy(originalParent);

            setIfPostalAddressIsSameAsResidential();
            setInitialFocus();
        });
    }

    function isUnauthorised(user: User) {
        if (!user.isPpao) {
            $location.path("/featureUnavailable");
            return true;
        }
        return false;
    }

    function setInitialFocus() {
        $timeout(function () {
            $("#residentialLine1").trigger("focus");
        }, 100);
    }

    function clearPostalAddress(shouldClear) {
        if (shouldClear) {
            angular.forEach(vm.parent.postalAddress, function (_, key) {
                vm.parent.postalAddress[key] = "";
            });
        }
    }

    function setIfPostalAddressIsSameAsResidential() {
        vm.postalAddressIsSameAsResidential = vm.parent.postalAddress.line1 === "";
    }

    function save() {
        if (vm.busy) return;

        var deferred = $q.defer();
        vm.busy = true;
        vm.error = false;

        dataContext
            .saveParentDetails(vm.parent)
            .then(function () {
                populateUpdatedDetails(originalParent, vm);
                $location.path("/profile").hash("submitted");
            })
            .catch(response => {
                vm.error = true;
                $log.error("Error changing the other details", response);
            })
            .finally(() => {
                vm.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    function populateUpdatedDetails(originalParent: User, vm) {
        const parent: User = vm.parent;
        originalParent.updatedDetails = {
            oneSchoolParentId: parent.oneSchoolParentId,
            timestamp: new Date(),
            residentialAddress: angular.copy(parent.residentialAddress),
            contacts: angular.copy(parent.contacts),
            postalAddress: vm.postalAddressIsSameAsResidential ? null : angular.copy(parent.postalAddress)
        };
    }
}

import { app } from "../../app.module";
import templateUrl from "./landing-page-section.html";
import "./landing-page-section.less";

app.component("landingPageSectionComponent", {
    templateUrl: templateUrl,
    bindings: {
        sectionclass: "@"
    },
    controller: landingPageSection,
    controllerAs: "vm",
    transclude: true
});

function landingPageSection() { }
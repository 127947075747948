import { app } from "../../../app.module";
import templateUrl from "./app-download-section.html";
import "./app-download-section.less";

app.component("appDownloadSectionComponent", {
    templateUrl: templateUrl,
    controller: appDownloadSection,
    controllerAs: "vm"
});

function appDownloadSection() {
    var vm = this;

    vm.loadImage = function(imagePath) {
        return require('../../../../content/img/' + imagePath);
    };
}
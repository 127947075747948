import angular, { ILocationService, ILogService, IQService } from "angular";
import { app } from "../app.module";
import template from "./loginForm.html";

import "./loginForm.less";
import { IUrlResolver } from "../services/urlResolver";
import { REMEMBER_EMAIL_KEY } from "../configuration";
import { recaptchaService } from "../services/recaptchaService";
import { IDeviceService } from "../services/deviceServiceProvider";
import { LoginResponse } from "../models/api";
import { ILoginService, UserLogin } from "../services/loginService";
import { IPreferencesService } from "../services/preferencesService";
import { Dialog } from "@capacitor/dialog";
import { MfaMedium, mfaMediumDescription } from "../models/enums";
import { ICsrfTokenService } from "../services/csrfTokenService";
import { ILocalStorageService } from "angular-local-storage";

app.directive("loginForm", () => ({
    restrict: "E",
    templateUrl: template,
    controller: loginForm,
    controllerAs: "vm",
    scope: {
        onCancel: "&",
        onLoginComplete: "&"
    },
    link: function (scope: any, element, attrs) {
        scope.isOnCancelDefined = angular.isUndefined(attrs["onCancel"]) === false;
        scope.isOnLoginCompleteDefined = angular.isUndefined(attrs["onLoginComplete"]) === false;
    }
}));

function loginForm(
    $scope,
    $log: ILogService,
    $route,
    $location: ILocationService,
    localStorageService: ILocalStorageService,
    deviceService: IDeviceService,
    preferencesService: IPreferencesService,
    csrfTokenService: ICsrfTokenService,
    $q: IQService,
    recaptchaService: recaptchaService,
    loginService: ILoginService,
    urlResolver: IUrlResolver
) {
    "ngInject";
    let vm = this;

    vm.mfaMedium = MfaMedium;
    vm.mfaMediumDescription = mfaMediumDescription;
    vm.mfaSessionId = null;
    vm.busy = false;
    vm.login = {
        email: localStorageService.get(REMEMBER_EMAIL_KEY),
        isNewDevice: true,
        mfaMedium: null
    };
    vm.showLoginFailed = false;
    vm.showToken = false;
    vm.message = null;
    vm.invalidToken = false;

    vm.doLogin = doLogin;
    vm.cancel = cancel;
    vm.retryMfa = retryMfa;

    initialise();

    function initialise() {
        setFocus("email");

        autoLoginAttempt();
    }

    function retryMfa(mfaMedium) {
        vm.login.mfaMedium = mfaMedium;
        vm.login.token = null;
        vm.mfaSessionId = null;
        doLogin(vm.login);
    }

    function cancel() {
        if ($scope.isOnCancelDefined) {
            $scope.onCancel();
        }
    }

    function doLogin(login) {
        if ($scope.form.$invalid && login.mfaMedium === null) return;
        if (vm.busy) return;

        var deferred = $q.defer();

        loginAttempt(login).finally(() => {
            deferred.resolve();
        });

        return deferred.promise;
    }

    function handleSuccessfulLogin() {
        if ($scope.isOnLoginCompleteDefined) {
            $scope.onLoginComplete();
        } else {
            $location.search("logout", null);
            $location.path("/");
            $route.reload();
        }
    }

    function autoLoginAttempt() {
        var queryParams = $location.search();

        if (queryParams.logout === "true") {
            // Do not attempt to auto login from log out
            return;
        }

        var deferred = $q.defer();

        if (deviceService.isMobileApp) {
            loginService
                .loadBiometricCredential()
                .then(userInfo => {
                    if (userInfo) {
                        vm.login.email = userInfo.username;
                        vm.login.password = userInfo.password;

                        loginAttempt(vm.login).finally(() => {
                            deferred.resolve();
                        });
                    } else {
                        deferred.resolve();
                    }
                })
                .catch(() => {
                    vm.showLoginFailed = true;
                    vm.message = "Biometrics login failed - please enter your email and password";
                    deferred.reject();
                });
        } else {
            deferred.resolve();
        }

        return deferred.promise;
    }

    function loginAttempt(login) {
        login.mfaSessionId = vm.mfaSessionId;

        var deferred = $q.defer();

        vm.busy = true;

        vm.showLoginFailed = false;
        vm.message = null;
        vm.invalidToken = false;

        // redirect to TRAIN for iOS test account (App Store approval account)
        handleMobileLoginAccount(login)
            .then(() =>
                loginService
                    .login(login)
                    .then(() => {
                        vm.mfaSessionId = null;

                        saveBiometricCredential({
                            username: login.email,
                            password: login.password
                        }).finally(() => {
                            vm.busy = false;
                            deferred.resolve();

                            handleSuccessfulLogin();
                        });
                    })
                    .catch(response => {
                        $log.error("Login failed", angular.toJson(response));
                        loginFailed(response);

                        vm.busy = false;
                        deferred.reject();
                    })
            )
            .catch(() => {
                vm.busy = false;
                vm.login.mfaMedium = null;
                deferred.reject();
            });

        return deferred.promise;
    }

    function loginFailed(response: any) {
        const data = response.data as LoginResponse;
        if (response.status === 401) {
            vm.showLoginFailed = true;
            vm.message = data?.message;
        } else if (response.status === 420 && data?.invalidCode) {
            vm.invalidToken = true;
            setFocus("token");
            return;
        } else if (response.status === 420) {
            vm.mfaSessionId = data?.mfaSessionId;
            vm.login.isNewDevice = data?.isNewDevice;
            vm.showToken = true;
            vm.message = data?.message;
            vm.login.mfaMedium = data?.mfaMedium;

            recaptchaService.initialize().then(() => {
                setFocus("token");
            }); // initialise the reCaptcha service when the login risky
            return;
        } else {
            vm.showLoginFailed = true;
            vm.message = "Something went wrong";
        }

        // Refresh the NCSRF
        csrfTokenService.ensureCsrfToken();
    }

    function handleMobileLoginAccount(login) {
        var deferred = $q.defer();

        if (deviceService.isMobileApp) {
            if (isMobileTestAccount(login)) {
                urlResolver.setBaseUrl("https://train.qparents.qld.edu.au");
            }

            csrfTokenService
                .ensureCsrfToken()
                .then(function () {
                    deferred.resolve();
                })
                .catch(() => {
                    deferred.reject();
                });
        } else {
            deferred.resolve();
        }
        return deferred.promise;
    }

    function isMobileTestAccount(login) {
        return login !== undefined && login.email === "qparentsapp@gmail.com";
    }

    function setFocus(field) {
        // HACK: We hide the feedback widget on input focus for devices that
        // match the tablet media query (see TABLET BREAKPOINT in site.less)
        // however this will only work if we dont set the initial focus for these
        // devices. To detect these devices the media query css wil set the visibilty
        // of the #js-media-query-test div from 'hidden' to 'visible'
        // Sorry!
        var areWeOnATabletDeviceOrSmaller = $("#js-media-query-test").css("visibility") === "visible";

        if (areWeOnATabletDeviceOrSmaller === false) {
            $("#" + field).trigger("focus");
        }
    }

    function saveBiometricCredential(userLogin: UserLogin) {
        const deferred = $q.defer();
        loginService
            .checkIfBiometricAvailable()
            .then(biometricResult => {
                if (!biometricResult.isAvailable) {
                    deferred.resolve();
                    return;
                }
                preferencesService
                    .getIsCredentialPrompted()
                    .then(isCredentialPrompted => {
                        if (!isCredentialPrompted) {
                            promptToSaveCredential(userLogin, biometricResult.description)
                                .then(() => {
                                    deferred.resolve();
                                })
                                .catch(response => {
                                    deferred.reject(response);
                                });
                        } else {
                            promptToUpdateCredential(userLogin, biometricResult.description)
                                .then(() => {
                                    deferred.resolve();
                                })
                                .catch(response => {
                                    deferred.reject(response);
                                });
                        }
                    })
                    .catch(response => {
                        deferred.reject(response);
                    });
            })
            .catch(response => {
                deferred.reject(response);
            });

        return deferred.promise;
    }

    function promptToSaveCredential(userLogin: UserLogin, availableBiometryTypeDescription: string) {
        $log.debug("Prompting user if the user intend to save the biometrics login.");
        const deferred = $q.defer();

        $q.when(
            Dialog.confirm({
                title: "Register for Biometrics Login",
                message: `Would you like to store your login details for accessing QParents and set up ${availableBiometryTypeDescription} login?`,
                okButtonTitle: "Yes",
                cancelButtonTitle: "No"
            })
        )
            .then(confirmResult => {
                if (confirmResult.value) {
                    $log.debug("User intend to register for biometrics login.");

                    // Save Credentials
                    saveCredential(userLogin, deferred);
                } else {
                    preferencesService.setIsCredentialPrompted(true);
                    deferred.resolve();
                }
            })
            .catch(response => {
                deferred.reject(response);
            });

        return deferred.promise;
    }

    function promptToUpdateCredential(userLogin: UserLogin, availableBiometryTypeDescription: string) {
        const deferred = $q.defer();

        preferencesService
            .getIsCredentialSaved()
            .then(isCredentialSaved => {
                if (!isCredentialSaved) {
                    deferred.resolve();
                    return;
                }

                loginService
                    .checkIfMatchingCredentialExist(userLogin)
                    .then(checkMatchResult => {
                        if (checkMatchResult?.passwordMatch && checkMatchResult?.usernameMatch) {
                            deferred.resolve();
                            return;
                        }
                        $log.debug("Prompting user if the user intend to update the login details.");
                        $q.when(
                            Dialog.confirm({
                                title: "Have your login details changed?",
                                message: `It looks like your login details have changed since last time. Please update them to continue using ${availableBiometryTypeDescription}`,
                                okButtonTitle: "Yes",
                                cancelButtonTitle: "No"
                            })
                        )
                            .then(confirmResult => {
                                if (confirmResult.value) {
                                    updateCredential(userLogin, deferred);
                                }
                            })
                            .catch(response => {
                                deferred.reject(response);
                            });
                    })
                    .catch(response => {
                        deferred.reject(response);
                    });
            })
            .catch(response => {
                deferred.reject(response);
            });

        return deferred.promise;
    }

    function updateCredential(userLogin: UserLogin, deferred: angular.IDeferred<unknown>) {
        $log.debug("User intend to update the login details.");
        loginService
            .deleteBiometricCredential()
            .then(() => {
                saveCredential(userLogin, deferred);
            })
            .catch(response => {
                deferred.reject(response);
            });
    }

    function saveCredential(userLogin: UserLogin, deferred: angular.IDeferred<unknown>) {
        loginService
            .saveBiometricCredential(userLogin)
            .finally(() => {
                preferencesService.setIsCredentialPrompted(true);
                deferred.resolve();
            })
            .catch(response => {
                deferred.reject(response);
            });
    }
}

import {app} from '../../app.module';
import { SESSION_DV_CODE_KEY } from '../../configuration';

const controllerId = 'delegatedViewerVerify';
const templateUrl = require('./verify.html');

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: delegatedViewerVerify,
    controllerAs: 'vm',
    restrict: 'E',
    replace: true
}));

function delegatedViewerVerify($location, localStorageService) {
    "ngInject";
    var vm = this;

    vm.fullname = null;
    vm.isThisYou = null;
    vm.busy = false;

    vm.cancel = cancel;
    vm.navigateToRegister = navigateToRegister;

    initialise();

    function initialise() {

        var sessionToken = getSessionToken();

        if (!sessionToken) {
            $location.path('/');
            return;
        }

        vm.fullname = sessionToken.name;
    }

    function getSessionToken() {
        return localStorageService.get(SESSION_DV_CODE_KEY);
    }

    function cancel() {
        localStorageService.remove(SESSION_DV_CODE_KEY);
        $location.path('/');
    }

    function navigateToRegister() {
        if (vm.busy) return;
        vm.busy = true;

        $location.path('/registration/delegated-viewer/register');
    }
}
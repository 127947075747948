import { app } from "../../app.module";
import { isWeekend } from "../../services/dayHelper";
import { StudentContext, IContextLoader } from "../../services/contextLoader";
import { User } from "../../models/user";
import { Student } from "../../models/student";
import { Enrolment } from "../../models/enrolment";
import { DayViewModel } from "@/app/models/api";
import templateUrl from "./timetableWidget.html";
require("./timetableWidget.less");

var controllerId = "timetableWidget";
app.directive(controllerId, () => ({
    templateUrl,
    controller: TimetableWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

class TimetableWidget implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    todaysTimetable: DayViewModel;
    studentFirstname: string;
    canShowTimetable: boolean;
    isWeekend: boolean;

    constructor($routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader) {
        "ngInject";
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            this.todaysTimetable = this.enrolment.getTodaysTimetableOrMondaysIfWeekend();
            this.studentFirstname = this.student.firstName();
            const timetableIsNoLongerThanOneWeek = this.enrolment.timetable && this.enrolment.timetable.days && this.enrolment.timetable.days.length <= 5;
            this.canShowTimetable = this.todaysTimetable && timetableIsNoLongerThanOneWeek;
            this.isWeekend = isWeekend();
        });
    }
}

import { ILocationService, ILogService, IPromise, IQService } from "angular";
import { IApiService } from "../services/apiServiceProvider";
import { IDeviceService } from "../services/deviceServiceProvider";
import { ILocalStorageService } from "angular-local-storage";

export interface ICheckerService {
    check(modules: string[], type: "outage" | "alert"): IPromise<any>;
    getOutage(id: string): IPromise<any>;
}

export function check($location: ILocationService, $log: ILogService, $q: IQService, $routeParams: angular.route.IRouteParamsService, apiService: IApiService, deviceService: IDeviceService,
    localStorageService: ILocalStorageService) : ICheckerService {
    const platform = deviceService.getPlatform();
    const outages = {};
    const checkerStatus = { checking: false };

    return {
        check: (modules: string[], type: "outage" | "alert") => {
            const locationUrl: string = $location.absUrl();
            if (checkerStatus.checking) {
                $log.info(`Outage location url: ${locationUrl}, not further redirect required.`);
                return;
            }
            const hasModulesToCheck = modules && modules.length > 0;
            checkerStatus.checking = hasModulesToCheck;
            const future = $q.defer();

            if (hasModulesToCheck) {
                const promises = modules.map(
                    module => type === "outage"
                    ? apiService.getOutage(platform, module)
                    : apiService.getAlert(platform, module));

                $q.all(promises).then(
                    responses => {
                        var outage = modules
                            .map(module =>
                                responses
                                    .map(response => response.data)
                                    .filter(data => data)
                                    .find(data => data.moduleName === module)
                            )
                            .filter(data => data)[0];
                        if (outage) {
                            if (type === "alert" && localStorageService.get(outage.id)) {
                                $log.info(`User has seen system alert ${outage.id} already`);
                            } else {
                                outages[outage.id] = outage;
                                let search: { id; studentId } | { id };
                                if ($routeParams.studentId) {
                                    search = { id: outage.id, studentId: $routeParams.studentId };
                                } else {
                                    search = { id: outage.id };
                                }

                                checkerStatus.checking = false;
                                if(type === "outage") {
                                    $location.path("/outage").search(search);
                                } else {
                                    $location.path("/notice").search(search);
                                }
                            }
                        }
                        checkerStatus.checking = false;
                        future.resolve({});
                    },
                    () => {
                        checkerStatus.checking = false;
                        future.resolve({});
                    }
                );
            } else {
                future.resolve({});
            }

            return future.promise;
        },
        getOutage: id => {
            var future = $q.defer();

            if (outages[id]) {
                future.resolve(outages[id]);
            } else {
                apiService.getOutageById(id).then(response => {
                    outages[id] = response.data;
                    future.resolve(response.data);
                });
            }

            return future.promise;
        }
    };
}

import { app } from "../app.module";
import templateUrl from "./enrolmentStatus.html";

require("./enrolmentStatus.less");

const controllerId = "enrolmentStatus";

app.component(`${controllerId}Component`, {
    templateUrl: templateUrl,
    controller: enolmentStatusController,
    bindings: {
        enrolment: "<"
    },
    controllerAs: "vm"
});

function enolmentStatusController() {}

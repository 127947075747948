import { app } from "../app.module";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { IDataContext } from "./dataContext";
import { User } from "../models/user";
import { ICommonService } from "./common";

export interface StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
}
export interface IContextLoader {
    load(vm: StudentContext, routeParams: angular.route.IRouteParamsService, controllerId: string): ng.IPromise<void>;
}

export class ContextLoader implements IContextLoader {
    constructor(private dataContext: IDataContext, private $location: ng.ILocationService, private common: ICommonService) {
        "ngInject";
    }

    load(vm: StudentContext, routeParams: angular.route.IRouteParamsService, controllerId: string) {
        vm.user = null;
        vm.student = null;
        vm.enrolment = null;

        var self = this;

        return this.common.activateController([getStudentEnrolment()], controllerId);

        function getStudentEnrolment() {
            var studentId = routeParams.studentId;
            var centreCode = routeParams.centreCode;

            return self.dataContext.getData().then(user => {
                var studentEnrolment = user.getEnrolment(studentId, centreCode);

                if (!studentEnrolment?.student) {
                    self.$location.path("/studentNotFound");
                    return;
                }

                vm.user = user;
                vm.student = studentEnrolment.student;
                vm.enrolment = studentEnrolment.enrolment;
                vm.enrolment.contactSchoolUrl = self.dataContext.isAdminView() ? "" : "mailto:" + studentEnrolment.enrolment.schoolEmail;
            });
        }
    }
}

const serviceId = "contextLoader";
app.service(serviceId, ContextLoader);

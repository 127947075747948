import { app as services } from "../app.module";
import angular from "angular";

export interface IUrlResolver {
    resolve(url: string): string;
    setBaseUrl(newBaseUrl: string): void;
}

class urlResolverProvider implements angular.IServiceProvider {
    private baseUrl: string;

    resolve(url: string): string {
        if (!this.baseUrl || this.baseUrl.length == 0 || !url || url.length == 0) return url;
        if (/^(https?:)?\/\//.test(url)) {
            return url;
        }
        if (/^data:/.test(url)) {
            //data url; return as it is
            return url;
        }
        return this.baseUrl + (url[0] !== "/" ? "/" : "") + url;
    }

    setBaseUrl(newBaseUrl: string): void {
        this.baseUrl = newBaseUrl;
    }

    getBaseUrl(): string {
        return this.baseUrl;
    }

    $get(): IUrlResolver {
        return {
            resolve: this.resolve.bind(this),
            setBaseUrl: this.setBaseUrl.bind(this)
        };
    }
}
services.provider("urlResolver", urlResolverProvider);

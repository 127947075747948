import { IDataContext } from "../../../services/dataContext";
import { app } from "../../../app.module";
import { FEATURES } from "../../../configuration";
import { PaymentsSummaryViewModel } from "@/app/models/api";

require("../parentCentricSummary.less");
require("./paymentsSummaryWidget.less");
import templateUrl from "./paymentsSummaryWidget.html";
app.directive("paymentsSummaryWidget", () => ({
    templateUrl,
    controller: paymentsSummaryWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

class paymentsSummaryWidget {
    paymentsSummary: PaymentsSummaryViewModel;
    paymentsFeatureTurnedOffForAllStudents: boolean;
    constructor(private dataContext: IDataContext) {
        "ngInject";
        this.dataContext.getData().then(user => {
            this.paymentsSummary = user.paymentsSummary;
            this.paymentsFeatureTurnedOffForAllStudents = !user.students.some(student => student.features.some(feature => feature.toString() === FEATURES.FINANCE.toString()));
        });
    }
}

import { app as services } from "../app.module";
import { ILogService, IPromise, IQService } from "angular";
import { IDeviceService } from "./deviceServiceProvider";
import { CapacitorCookies } from "@capacitor/core";

var serviceId = "cookieService";
services.factory(serviceId, cookieService);

export interface ICookieService {
    deleteCookie(key: string): IPromise<void>;
}

function cookieService($log: ILogService, $q: IQService, deviceService: IDeviceService): ICookieService {
    "ngInject";
    return {
        deleteCookie: deleteCookie
    };

    function deleteCookie(key: string) {
        var deferred = $q.defer<void>();

        $log.debug(`cookieService - Delete ${key} Cookie`);

        if (deviceService.isMobileApp) {
            $q.when(CapacitorCookies.deleteCookie({ key: key })).finally(() => {
                deferred.resolve();
            });
        } else {
            document.cookie = `${key}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
            deferred.resolve();
        }
        return deferred.promise;
    }
}

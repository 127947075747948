import { ILocationService } from "angular";
import { app } from "../app.module";
import templateUrl from "./studentSidebar.html";
import { IContextLoader } from "../services/contextLoader";

const controllerId = "studentSidebar";

app.directive(`${controllerId}Component`, () => ({
    restrict: "A",
    replace: false,
    templateUrl,
    controller: studentSidebarLoad,
    controllerAs: "vm",
    scope: true
}));

function studentSidebarLoad($routeParams: angular.route.IRouteParamsService, $location: ILocationService, contextLoader: IContextLoader) {
    "ngInject";
    const vm = this;
    vm.user = null;
    vm.student = null;
    vm.enrolment = null;

    contextLoader.load(vm, $routeParams, controllerId);

    vm.isActive = function (path) {
        return $location.path().startsWith(path);
    };
}

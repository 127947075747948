import { app } from "../app.module";
import { SPINNER_URL, AVATAR_NOPHOTO_URL } from "../configuration";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { PhotoService } from "../services/photoService";
import { IDataContext } from "../services/dataContext";

const controllerId = "schoolPhoto";
import templateUrl from "./schoolPhoto.html";
import { ILogService } from "angular";
import { IDeviceService } from "../services/deviceServiceProvider";
import { IUrlResolver } from "../services/urlResolver";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: SchoolPhotoComponent,
    controllerAs: "vm",
    restrict: "E",
    replace: false
}));

class SchoolPhotoComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    schoolPhotoUrl: string;
    photoRetrieved: boolean;
    busy: boolean;
    SPINNER_URL: string;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        private $log: ILogService,
        private $location: ng.ILocationService,
        private urlResolver: IUrlResolver,
        private photoService: PhotoService,
        private deviceService: IDeviceService,
        private dataContext: IDataContext
    ) {
        "ngInject";
        this.student = null;
        this.photoRetrieved = false;
        this.busy = null;
        this.schoolPhotoUrl = null;

        this.SPINNER_URL = SPINNER_URL;

        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (this.isDelegatedViewer()) return;
            if (this.schoolDoesntHavePhoto()) return;
            if (this.dataContext.isAdminView()) {
                this.photoRetrieved = true;
                this.schoolPhotoUrl = AVATAR_NOPHOTO_URL;
                return;
            }

            this.retrieveSchoolPhoto();
        });
    }

    isDelegatedViewer() {
        if (!this.student.isUserPPAOForStudent) {
            var newPath = "/";
            this.$location.path(newPath);
            return true;
        }
        return false;
    }

    schoolDoesntHavePhoto() {
        if (!this.student.hasSchoolPhoto) {
            this.$log.debug("schoolPhoto - School has no photo");
            var newPath = `/studentPhoto/${this.student.oneSchoolId}/${this.enrolment.schoolCode}`;
            this.$location.path(newPath);
            return true;
        }

        return false;
    }

    retrieveSchoolPhoto() {
        this.$log.debug("schoolPhoto - Retrieving School Photo");
        this.photoService
            .retrieveSchoolPhoto(this.student.oneSchoolId)
            .then(schoolPhotoUrl => {
                if (this.deviceService.isMobileApp) {
                    this.photoService.downloadPhoto(this.urlResolver.resolve(schoolPhotoUrl)).then(result => {
                        this.schoolPhotoUrl = result;
                    });
                } else {
                    this.schoolPhotoUrl = schoolPhotoUrl;
                }
                this.$log.debug(`schoolPhoto - School Photo Retrieved at ${schoolPhotoUrl}`);
            })
            .finally(() => {
                this.photoRetrieved = true;
            });
    }

    accept() {
        this.busy = true;

        this.photoService
            .promotePhoto(this.student.oneSchoolId, "school")
            .then(photoUrl => {
                this.student.photoUrl = photoUrl;
                this.student.photoContentUrl = this.schoolPhotoUrl;

                this.$log.debug("schoolPhoto - School Photo has been promoted");
            })
            .finally(() => {
                this.busy = false;
                this.$location.path(`/student/${this.student.oneSchoolId}/${this.enrolment.schoolCode}`);
            });
    }

    navigateBack() {
        window.history.back();
    }
}

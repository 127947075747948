import angular, { IPromise, IQService } from "angular";
import { App, AppInfo } from "@capacitor/app";
import { app as services } from "../app.module";
import { Capacitor } from "@capacitor/core";
import { Device, DeviceId, DeviceInfo } from "@capacitor/device";

const serviceId = "deviceService";

export interface IDeviceService {
    getDeviceId: () => IPromise<string>;
    getDeviceInfo: () => IPromise<DeviceInfo>;
    getAppInfo: () => IPromise<AppInfo>;
    getPlatform: () => "android" | "ios" | "web" | null;
    isIOs: () => boolean;
    isAndroid: () => boolean;
    isMobileApp: boolean;
}

class DeviceServiceProvider implements angular.IServiceProvider {
    public $get = [
        "$q",
        ($q: angular.IQService): IDeviceService => {
            return new DeviceService($q);
        }
    ];
}

class DeviceService implements IDeviceService {
    _deviceId: DeviceId;
    _deviceInfo: DeviceInfo;
    _appInfo: AppInfo;

    constructor(private $q: IQService) {}

    public getDeviceId() {
        const deferred = this.$q.defer<string>();

        if (!this._deviceId) {
            this.$q.when(Device.getId()).then(device => {
                this._deviceId = device;
                deferred.resolve(device.identifier);
            });
        } else {
            deferred.resolve(this._deviceId.identifier);
        }

        return deferred.promise;
    }

    public getDeviceInfo() {
        const deferred = this.$q.defer<DeviceInfo>();

        if (!this._deviceInfo) {
            this.$q.when(Device.getInfo()).then(deviceInfo => {
                this._deviceInfo = deviceInfo;
                deferred.resolve(deviceInfo);
            });
        } else {
            deferred.resolve(this._deviceInfo);
        }
        return deferred.promise;
    }

    public getAppInfo() {
        const deferred = this.$q.defer<AppInfo>();

        if (this.isMobileApp) {
            this.$q.when(App.getInfo()).then(appInfo => {
                this._appInfo = appInfo;
                deferred.resolve(appInfo);
            });
        } else {
            deferred.resolve({
                name: "QParents",
                id: "",
                build: "",
                version: "Web"
            });
        }

        return deferred.promise;
    }

    public getPlatform(): "android" | "ios" | "web" {
        const platform = Capacitor.getPlatform();
        if (platform === "android" || platform === "ios") {
            return platform;
        }

        return "web";
    }

    public isIOs(): boolean {
        return this.getPlatform() == "ios";
    }

    public isAndroid(): boolean {
        return this.getPlatform() == "android";
    }

    public get isMobileApp() {
        return this.isIOs() || this.isAndroid();
    }
}

services.provider(serviceId, DeviceServiceProvider);

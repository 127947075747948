import { app } from "../app.module";
import { FEATURES } from "../configuration";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import { DelegateViewerService } from "../services/delegateViewer";
import { distinctFilter } from "../tools/array";
import { NewDelegateViewerWriteModel } from "../models/api";
import templateUrl from "./addViewer.html";
const controllerId = "addViewer";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: AddViewerComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

class AddViewerComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    busy: boolean;
    error: boolean;

    // There's major differences between this VM and the what the client
    // is using.  Let's see if this works?
    viewer: NewDelegateViewerWriteModel & {
        allFeatures: string[];
    };
    delegateViewerAdded: boolean;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        authorisationService: AuthorisationService,
        private delegateViewer: DelegateViewerService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private $log: ng.ILogService
    ) {
        "ngInject";
        this.busy = false;
        this.error = false;
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasStudentFeatureAccess(this, FEATURES.STUDENT_DETAILS)) return;
            if (this.isDelegatedViewer()) return;

            let combinedFeatures = (<string[]>this.student.features).concat(this.enrolment.features);
            combinedFeatures = combinedFeatures.filter(distinctFilter);
            let availableViewerFeatures = combinedFeatures.filter(x => x !== FEATURES.CONSENT_REQUESTS.toString() && x !== FEATURES.STUDENT_DETAILS.toString());

            this.viewer = {
                studentId: this.student.oneSchoolId,
                features: [],
                allFeatures: availableViewerFeatures,
                parentQId: null,
                firstName: null,
                lastName: null,
                email: null
            };
        });
    }

    isDelegatedViewer() {
        if (!this.student.isUserPPAOForStudent) {
            var newPath = "/featureUnavailable";
            this.$location.path(newPath);
            return true;
        }
        return false;
    }

    save() {
        if (this.busy) return;

        const deferred = this.$q.defer();
        this.busy = true;
        this.error = false;

        this.delegateViewer
            .sendInvite(this.viewer)
            .then(newViewer => {
                this.student.delegateViewers.push(newViewer);
                this.delegateViewerAdded = true;
            })
            .catch(response => {
                this.error = true;
                this.$log.error("Error sending invitation", response);
            })
            .finally(() => {
                this.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    anyFeaturesSelected() {
        return this.viewer.features.length != 0;
    }
}

import { app } from "../../../app.module";
import templateUrl from "./overview-section.html";

app.component("overviewSectionComponent", {
    templateUrl: templateUrl,
    controller: overviewSection,
    controllerAs: "vm"
});

function overviewSection() {
    var vm = this;

    vm.loadImage = function(imagePath) {
        return require('../../../../content/img/' + imagePath);
    };
}
import { app } from "../../app.module";
import { IUrlResolver } from "@/app/services/urlResolver";
import { ADMIN_VIEW_COOKIES } from "../../configuration";
import { StudentContext, IContextLoader } from "../../services/contextLoader";
import { User } from "../../models/user";
import { Student } from "../../models/student";
import { Enrolment } from "../../models/enrolment";
import { IDataContext } from "../../services/dataContext";
import { FileDownloadOptions } from "@/app/models/api";
import templateUrl from "./downloadWidget.html";
import { IDeviceService } from "@/app/services/deviceServiceProvider";
import { IAssetService } from "@/app/services/assetService";
require("./downloadWidget.less");

var controllerId = "downloadWidget";
app.directive(controllerId, () => ({
    templateUrl,
    controller: DownloadWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

class DownloadWidget implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    downloads: { name: string; href: string }[];
    isMobileApp: boolean;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        private assetService: IAssetService,
        private dataContext: IDataContext,
        private urlResolver: IUrlResolver,
        deviceService: IDeviceService
    ) {
        "ngInject";
        this.isMobileApp = deviceService.isMobileApp;
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            this.refreshDownloads();
        });
    }

    refreshDownloads() {
        this.downloads = this.mapModels(this.enrolment.examTimetables, "exam-timetable")
            .concat(this.mapModels(this.enrolment.assessmentPlanners, "assessment-planner"))
            .sort((a, b) => {
                return a.name < b.name ? -1 : a.name === b.name ? 0 : 1;
            });
    }

    mapModels(models, type) {
        return models.map(d => {
            return this.buildDownloadModel(d, type);
        });
    }

    paramsToQueryString(params) {
        return Object.keys(params)
            .map(key => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
            })
            .join("&");
    }

    buildDownloadModel(downloadInfo, type) {
        var params: FileDownloadOptions = {
            fileId: downloadInfo.id,
            fileName: downloadInfo.name,
            pPAO: this.user.oneSchoolParentId,
            studentId: this.student.oneSchoolId,
            schoolCode: this.enrolment.schoolCode,
            schoolUserId: ""
        };

        if (this.dataContext.isAdminView()) {
            params.schoolUserId = sessionStorage.getItem(ADMIN_VIEW_COOKIES.SCHOOL_USER_ID);
        }

        const url = this.urlResolver.resolve(`/api/file-download/${type}?${this.paramsToQueryString(params)}`);
        return {
            name: downloadInfo.name,
            href: url
        };
    }

    openFile(file) {
        if (!this.isMobileApp) {
            this.assetService.openFile(file.href);
        } else {
            this.assetService.mobileOpenFile(file.href, true);
        }
    }
}

import {app} from '../app.module';
import INgModelController = angular.INgModelController;
app.directive('digitsOnly', function() {
            return {
                require: 'ngModel',
                link: function (scope, elem, attr, ngModel:INgModelController) {
                    ngModel.$parsers.unshift(checkForDigits);
                    ngModel.$formatters.unshift(checkForDigits);

                    function checkForDigits(input) {
                        if (input == undefined) return '';

                        // allow entering numbers. prevent anything else
                        var inputWithoutDigits = input.replace(/[^0-9]/g, '');
                        if (inputWithoutDigits != input) {
                            ngModel.$setViewValue(inputWithoutDigits);
                            ngModel.$render();

                            return inputWithoutDigits;
                        } else {
                            return input;
                        }
                    }
                }
            };
        });
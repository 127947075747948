import { app } from "../../../app.module";
import { ILandingPageContentWithImage } from "../../landing-page";
import templateUrl from "./process-guide-section.html";
import "./process-guide-section.less";

const processGuide: ILandingPageContentWithImage[] = [
    {
        title: 'Get your invitation',
        description: 'Your school will provide your invitation code to start the process.',
        iconSrc: 'birthday-card.svg',
    },
    {
        title: 'Register as a parent',
        description: 'Only parents and caregivers can be account owners.',
        iconSrc: 'customer.svg',
    },
    {
        title: 'Connect to your Student',
        description: 'Locate your children and request access from the School.',
        iconSrc: 'student.svg',
    },
    {
        title: 'View your child\'s information',
        description: 'Your children\'s school information available in the one place, even if they attend different state schools.',
        iconSrc: 'connectstudent.svg',
    }
]

app.component("processGuideSectionComponent", {
    templateUrl: templateUrl,
    controller: processGuideSection,
    controllerAs: "vm"
});

function processGuideSection() {
    var vm = this;
    vm.processGuide = processGuide;

    vm.loadIcon = function(iconPath) {
        return require('../../../../content/icons/' + iconPath);
    };
}
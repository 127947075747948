import { app } from "../../app.module";

import templateUrl from "./qLearnAppMessage.html";
import "./qLearnAppMessage.less";
import { QLearnService } from "../../services/qLearnService";
import { QLearnLinkModel } from "@/app/models/api";

app.directive("qLearnAppMessage", function () {
    return {
        restrict: "E",
        templateUrl,
        scope: true,
        controller: QLearnAppMessage,
        controllerAs: "vm"
    };
});

class QLearnAppMessage {
    public link: QLearnLinkModel;

    constructor(qLearnService: QLearnService) {
        "ngInject";
        qLearnService.getQLearnLink().then(response => (this.link = response));
    }
}

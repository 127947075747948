import { app as services } from "../app.module";
import { IDeviceService } from "./deviceServiceProvider";
import { IIntervalService, ILocationService, ILogService } from "angular";
import { App } from "@capacitor/app";
import { IDataContext } from "./dataContext";
import { IExceptionHandler } from "./exceptionHandler";
import { ILocalStorageService } from "angular-local-storage";

services.provider("errorReporting", function ErrorReporting(): any {
    var cacheConditions = [],
        CACHED_EXCEPTIONS_KEY = "error-cache",
        EXCEPTION_CACHE_SIZE = 10;
    this.$get = function (
        $window,
        $location: ILocationService,
        $interval: IIntervalService,
        $log: ILogService,
        localStorageService: ILocalStorageService,
        dataContext: IDataContext,
        deviceService: IDeviceService,
        exceptionHandler: IExceptionHandler
    ) {
        "ngInject";
        var userName = null,
            userEmail = null,
            userProfileId = null,
            intervalPromiseCachedErrors = null;
        (function activate() {
            exceptionHandler.addHandler(reportError);

            dataContext.onUserSignedIn(function () {
                dataContext.getData().then(function (parent) {
                    if (!parent) return;

                    userName = parent.firstName + " " + parent.lastName;
                    userEmail = parent.emailAddress;
                    userProfileId = parent.oneSchoolParentId;
                });
            });

            addCacheCondition(function (status) {
                return status === 408;
            });

            $window.onerror = function (message, filename, line, column, error) {
                reportError({
                    message: message,
                    fileName: filename,
                    line: line,
                    column: column,
                    details: error
                });
                return true;
            };

            if (intervalPromiseCachedErrors) {
                $interval.cancel(intervalPromiseCachedErrors);
            }

            // Send the error into the api every 30 seconds
            intervalPromiseCachedErrors = $interval(sendCachedErrors, 30000);
        })();

        return {
            reportError: reportError
        };

        function reportError(exception) {
            buildError(exception).then(error => {
                addErrorToCache(error);
            });
        }

        function shouldCacheForLater(status, headers, config) {
            const localStorageServiceSupported = localStorageService !== undefined && localStorageService.isSupported;
            return (
                localStorageServiceSupported &&
                cacheConditions.some(function (cc) {
                    return cc(status, headers, config);
                })
            );
        }

        function replaceErrors(key, value) {
            if (value instanceof Error) {
                var error = {};

                Object.getOwnPropertyNames(value).forEach(function (key) {
                    error[key] = value[key];
                });

                return error;
            }

            return value;
        }

        function send(error, isFromCache?) {
            this.apiService.recordError(error).then(
                function successCallback(response) {
                    if (!isFromCache) {
                        return;
                    }

                    var cache = localStorageService.get(CACHED_EXCEPTIONS_KEY) || [];
                    cache = cache.filter(e => e !== error);
                    localStorageService.set(CACHED_EXCEPTIONS_KEY, cache);
                    // awesome. We logged it! Nothing to do here I think, perhaps log to console...
                },
                function errorCallback(response) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
                    if (shouldCacheForLater(response.status, response.headers, response.config)) {
                        addErrorToCache(error);
                    }
                    $log.error("Sending Error failed", response);
                }
            );
        }

        function sendCachedErrors() {
            var cache = localStorageService.get(CACHED_EXCEPTIONS_KEY);
            if (!cache) {
                return;
            }
            cache.forEach(function (cachedError) {
                send(cachedError, true);
            });
        }

        async function buildError(exception) {
            const device = await getDeviceData();
            const appInfo = deviceService.isMobileApp ? await App.getInfo() : null;

            var exceptionRelatedInformation = {
                userName: userName || "unknown",
                userEmail: userEmail,
                userProfileId: userProfileId,
                timestamp: new Date(),
                error: JSON.stringify(exception, replaceErrors),
                device: device,
                page: $location.url(),
                language: window.navigator.language,
                userAgent: window.navigator.userAgent,
                appVersion: appInfo?.version,
                appName: appInfo?.name,
                appInfo: appInfo?.version
            };

            return Promise.resolve(exceptionRelatedInformation);
        }

        async function getDeviceData() {
            var deviceInfo = await deviceService.getDeviceInfo();
            var deviceId = await deviceService.getDeviceId();

            return {
                model: deviceInfo.model,
                platform: deviceInfo.platform,
                osVersion: deviceInfo.osVersion,
                operatingSystem: deviceInfo.operatingSystem,
                manufacture: deviceInfo.manufacturer,
                isVirtual: deviceInfo.isVirtual,
                uuid: deviceId
            };
        }

        function addErrorToCache(error: any) {
            var cache = localStorageService.get(CACHED_EXCEPTIONS_KEY) || [];
            cache.push(error);
            if (cache.length > EXCEPTION_CACHE_SIZE) {
                cache.splice(0, 1);
            }
            localStorageService.set(CACHED_EXCEPTIONS_KEY, cache);
        }
    };
    this.addCacheCondition = addCacheCondition;
    function addCacheCondition(cacheCondition) {
        cacheConditions.push(cacheCondition);
    }
});

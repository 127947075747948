import angular from "angular";
import moment from "moment";
import { FEATURES, AVATAR_NOPHOTO_URL, StudentFeature } from "../configuration";
import { Enrolment } from "./enrolment";
import {
    StudentViewModel,
    DelegateViewerViewModel,
    AddressViewModel,
    UpdatedStudentDetailsViewModel,
    UpdatedStudentMedicalConditionsViewModel,
    EnrolmentHistoryViewModel,
    MedicalConditionViewModel,
    AcademicYearArchiveViewModel
} from "./api";

type StudentCommon = Omit<StudentViewModel, "enrolments">;

export class Student implements StudentCommon {
    isUserPPAOForStudent: boolean;
    features: StudentFeature[];
    oneSchoolId: string;
    givenNames: string;
    lastName: string;
    dateOfBirth: string;
    hasFinishedSchool: boolean;
    finisedSchoolDate: string;
    gender: string;
    delegateViewers: DelegateViewerViewModel[];
    postalAddress: AddressViewModel;
    residentialAddress: AddressViewModel;
    updatedDetails: UpdatedStudentDetailsViewModel;
    updatedMedicalConditions: UpdatedStudentMedicalConditionsViewModel;
    photoUrl: string;
    fullName: string;
    hasSchoolPhoto: boolean;
    residesWithParent: boolean;

    enrolments: Enrolment[];
    enrolmentHistory: EnrolmentHistoryViewModel[];
    medicalConditions: MedicalConditionViewModel[];
    academicYearArchives: AcademicYearArchiveViewModel[];

    photoContentUrl: string; // need to load images via XHR and store here for display

    constructor(
        data: StudentViewModel,
        public isDelegated: boolean,
        isMobileApp: boolean
    ) {
        angular.extend(this, data);

        this.dateOfBirth = stripTime(this.dateOfBirth, isMobileApp);
        this.enrolments = data.enrolments.map(e => new Enrolment(e));
    }

    displayName() {
        return this.givenNames + " " + this.lastName;
    }

    firstName() {
        return this.givenNames;
    }

    studentDetailsAvailable() {
        return this.isFeatureAvailable(FEATURES.STUDENT_DETAILS);
    }

    enrolmentHistoryAvailable() {
        return this.isFeatureAvailable(FEATURES.ENROLMENT_HISTORY);
    }

    consentRequestsAvailable() {
        return this.isFeatureAvailable(FEATURES.CONSENT_REQUESTS);
    }

    isFeatureAvailable(featureName: StudentFeature) {
        return this.features.some(feature => feature === featureName);
    }

    photoUrlOrPlaceholder() {
        return this.photoUrl || AVATAR_NOPHOTO_URL;
    }

    hasPhoto() {
        return !!this.photoUrl;
    }

    public get isPhotoFeatureEnabled() {
        return this.features.some(f => f === "Student Photo");
    }
}

// 2001-01-01T00:00:00 -> 2001-01-01
function stripTime(dateStringWithTime, isMobileApp: boolean) {
    if (isMobileApp) {
        return moment(dateStringWithTime).toDate();
    }
    return dateStringWithTime.substring(0, 10);
}

import { app } from "../app.module";
import { FEATURES } from "../configuration";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import { IDataContext } from "../services/dataContext";
import { AcademicYearArchiveViewModel } from "../models/api";
import templateUrl from "./archive.html";

const controllerId = "archive";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: ArchiveComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

class ArchiveComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    hasArchiveReports: boolean;
    archiveReports: AcademicYearArchiveViewModel[];
    busy: boolean;
    submitted: boolean;
    studentNotFound: boolean;

    constructor($routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader, authorisationService: AuthorisationService, private dataContext: IDataContext) {
        "ngInject";
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasFeatureAccess(this, FEATURES.ACADEMIC_REPORTS)) return;

            this.hasArchiveReports = !!this.student.academicYearArchives.length;
            this.archiveReports = this.student.academicYearArchives.sort((a, b) => parseInt(a.academicYear) - parseInt(b.academicYear));
        });
    }

    emailAcademicReportArchive(model: AcademicYearArchiveViewModel) {
        this.busy = true;

        this.dataContext
            .emailAcademicReportArchive(this.student.oneSchoolId, model.academicYear)
            .then(r => {
                if (r && r === "StudentNotFound") {
                    this.studentNotFound = true;
                } else {
                    this.submitted = true;
                }
            })
            .finally(() => {
                this.busy = false;
            });
    }
}

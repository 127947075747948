import { app } from "../app.module";
import templateUrl from "./landing-page.html";

export interface ILandingPageContentWithImage {
    title: string
    description: string
    iconSrc: string
}

app.component("landingPageComponent", {
    templateUrl: templateUrl,
    controller: landingPage,
    controllerAs: "vm"
});

function landingPage() { }
import { v4 as uuidv4 } from "uuid";
import { ILogService, IQService } from "angular";
import { app as services } from "../app.module";
import { IDeviceService } from "./deviceServiceProvider";
import { IAssetService } from "./assetService";
import { formatDateTimeMomentToIcsDate, formatDateTimeToIcsDate } from "./dateHelper";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import moment from "moment";

var serviceId = "calendarService";

export interface ICalendarData {
    id?: string;
    startDate: Date;
    startTime: string;
    endDate: Date;
    endTime: string;
    summary: string;
    description?: string;
}

export interface ICalendarService {
    createIcsFile(data: ICalendarData[]): Blob;
    openIcsFile(icsBlob: Blob): void;
}

export class CalendarService implements ICalendarService {
    constructor(
        private deviceService: IDeviceService,
        private assetService: IAssetService,
        private $q: IQService,
        private $log: ILogService
    ) {
        "ngInject";
    }

    public createIcsFile(data: ICalendarData[]): Blob {
        this.$log.debug(`CalendarService - Creating Ics Blob for ${data.length} event/s`);

        const icsHeader = `BEGIN:VCALENDAR
VERSION:2.0
METHOD:PUBLISH
CALSCALE:GREGORIAN
PRODID:-//QParents//EN`;

        const icsFooter = "END:VCALENDAR";

        const icsEvents = data.map(calendarData => {
            const uid = calendarData.id || uuidv4();
            const now = formatDateTimeMomentToIcsDate(moment());
            const startDate = formatDateTimeToIcsDate(calendarData.startDate, calendarData.startTime);
            const endDate = formatDateTimeToIcsDate(calendarData.endDate, calendarData.endTime);
            const summary = this.escapeICalValue(calendarData.summary) || "";
            const description = this.escapeICalValue(calendarData.description) || "";
            const truncatedSummary = summary.length > 75 ? summary.slice(0, 75) : summary;
            const truncatedDescription = summary.length > 75 ? `${summary}\n${description}` : description;

            return `BEGIN:VEVENT
UID:${uid}
DTSTART:${startDate}
DTEND:${endDate}
SUMMARY:${truncatedSummary}
DESCRIPTION:${truncatedDescription}
DTSTAMP:${now}
END:VEVENT`;
        });

        const icsContent = [icsHeader, ...icsEvents, icsFooter].join("\n");

        this.$log.debug(`CalendarService - Created Ics Blob for ${icsEvents.length} event/s`);

        return new Blob([icsContent], { type: "text/calendar" });
    }

    public openIcsFile(icsBlob: Blob) {
        let icsFile: string;

        if (this.deviceService.isMobileApp) {
            this.$log.debug("CalendarService - Open Ics File for Mobile App");
            this.openIcsFileForMobile(icsBlob);
        } else {
            this.$log.debug("CalendarService - Open Ics File for Web App");

            icsFile = window.URL.createObjectURL(icsBlob);
            this.assetService.openFile(icsFile);
        }
    }

    private openIcsFileForMobile(icsBlob: Blob) {
        const reader = new FileReader();
        const savedIcsFilePath = `calendar/${uuidv4()}.ics`;

        reader.readAsText(icsBlob);
        reader.onloadend = () => {
            let result: string;

            if (typeof reader.result === "string") {
                result = reader.result;
            } else if (reader.result instanceof ArrayBuffer) {
                const uint8Array = new Uint8Array(reader.result);
                result = String.fromCharCode.apply(null, uint8Array);
            }

            this.$log.debug("CalendarService - Writing Ics File to Data Directory");

            this.$q
                .when(
                    Filesystem.writeFile({
                        path: savedIcsFilePath,
                        data: result,
                        directory: Directory.Data,
                        encoding: Encoding.UTF8,
                        recursive: true
                    })
                )
                .then(result => {
                    this.$log.debug("CalendarService - Opening Ics File ");
                    FileOpener.open({
                        filePath: result.uri,
                        contentType: icsBlob.type,
                        openWithDefault: true
                    });
                });
        };
    }

    private escapeICalValue(value: string) {
        return value?.replace(/,/g, "\\,").replace(/;/g, "\\;").replace(/\\/g, "\\\\").replace(/\n/g, "\\n");
    }
}

services.service(serviceId, CalendarService);

import { app } from "../app.module";
import { FEATURES, ADMIN_VIEW_COOKIES } from "../configuration";
import { AuthorisationService } from "../services/authorisationService";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { FinanceRecord } from "../models/financeRecord";
import { IUrlResolver } from "../services/urlResolver";
import { IDataContext } from "../services/dataContext";
import { InvoiceModel, FinanceRecordViewModel } from "../models/api";
import templateUrl from "./payment.html";
const controllerId = "payment";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: PaymentComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

class PaymentComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        authorisationService: AuthorisationService,
        private dataContext: IDataContext,
        private urlResolver: IUrlResolver
    ) {
        "ngInject";
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasFeatureAccess(this, FEATURES.FINANCE)) return;
            if (Array.isArray(this.enrolment.financeRecordsDue) && this.enrolment.financeRecordsDue.length) {
                this.enrolment.financeRecordsDue.forEach(f => {
                    if (f.paymentAmount > f.amountOwing) {
                        f.paymentAmount = f.amountOwing;
                        f.isSelectedForPayment = false;
                    }
                });
            }
        });
    }

    paymentUrl(payment: FinanceRecord) {
        const params: InvoiceModel = {
            oneSchoolPpaoId: this.user.oneSchoolParentId,
            studentEqId: this.student.oneSchoolId,
            schoolId: this.enrolment.schoolCode,
            invoiceNumber: payment.invoiceNumber,
            schoolUserId: ""
        };

        if (this.dataContext.isAdminView()) {
            params.schoolUserId = sessionStorage.getItem(ADMIN_VIEW_COOKIES.SCHOOL_USER_ID);
        }
        const url = this.urlResolver.resolve(`/api/invoice?${this.paramsToQueryString(params)}`);
        return url;
    }

    toggle(financeRecord: FinanceRecordViewModel & { expanded: boolean }) {
        financeRecord.expanded = financeRecord.expanded ? false : true;
    }

    paramsToQueryString(params) {
        return Object.keys(params)
            .map(key => {
                return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
            })
            .join("&");
    }

    showPaymentButton() {
        if (this.enrolment != null)
            return (
                this.enrolment.getFinanceRecordsSelectedForPayment().length > 0 &&
                this.enrolment.getFinanceRecordsSelectedForPaymentTotal() > 0 &&
                !this.enrolment.getFinanceRecordsWithPaymentAmountGreaterThanAmountOwing().length
            );
        return false;
    }

    selectedForPaymentToggled(payment: FinanceRecord) {
        if (!payment.isSelectedForPayment && payment.paymentAmount > payment.amountOwing) {
            payment.paymentAmount = payment.amountOwing;
        }
    }
}

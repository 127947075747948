import { IQService, extend } from "angular";
import { app as services } from "../app.module";
import { IApiService } from "./apiServiceProvider";
import { VerifyAccountOwnerInvitationCodeResponse } from "../models/api";

var serviceId = "invitationService";
services.factory(serviceId, invitationService);

export interface IValidateInvitationCodeResponse extends VerifyAccountOwnerInvitationCodeResponse {
    valid: boolean;
    invalid: boolean;
    codeAlreadyUsed: boolean;
    userAlreadyAccountOwner: boolean;
    looksLikeAStudentEqId: boolean;
}

function invitationService(apiService: IApiService, $q: IQService) {
    "ngInject";
    var VALID = 0,
        INVALID = 1,
        ALREADY_USED = 2,
        ALREADY_REGISTERED = 3,
        LOOKS_LIKE_A_STUDENT_EQID = 4;

    return {
        validateInvitationCode: validateInvitationCode
    };

    function codesLooksLikeAStudentEqId(code) {
        if (!code) return false;
        if (code.length !== 11) return false;

        // 10 digits followed by a character
        var studentEqIdRegEx = /\d{10}\w{1}/g;
        return studentEqIdRegEx.test(code);
    }

    function validateInvitationCode(code) {
        const deferred = $q.defer();

        if (codesLooksLikeAStudentEqId(code)) {
            deferred.resolve(
                buildResponse({
                    resultCode: LOOKS_LIKE_A_STUDENT_EQID
                })
            );
        } else {
            apiService
                .accountOwnerInvitation({
                    code: code
                })
                .then(function (response) {
                    deferred.resolve(buildResponse(response.data));
                });
        }

        return deferred.promise;
    }

    function buildResponse(data): IValidateInvitationCodeResponse {
        const response: IValidateInvitationCodeResponse = { ...data };

        response.valid = response.resultCode === VALID;
        response.invalid = response.resultCode === INVALID;
        response.codeAlreadyUsed = response.resultCode === ALREADY_USED;
        response.userAlreadyAccountOwner = response.resultCode === ALREADY_REGISTERED;
        response.looksLikeAStudentEqId = response.resultCode === LOOKS_LIKE_A_STUDENT_EQID;

        return response;
    }
}

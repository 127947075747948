import { app } from "../app.module";
import { IDirective } from "angular";
import { IDataContext } from "../services/dataContext";
import ng from "angular";

interface IParentOnlyAttributes extends ng.IAttributes {
    ngDisabled?: string;
}

class ParentOnlyDirective implements IDirective {
    restrict = "A";

    constructor(private dataContext: IDataContext) {}

    link(scope: ng.IScope, elem: any, attr: IParentOnlyAttributes) {
        if (this.dataContext.isAdminView()) {
            elem[0].disabled = true;
            if (attr.ngDisabled) {
                attr.ngDisabled = "true";
            }
        }
    }
}

const parentOnlyDirective: ng.IDirectiveFactory = (dataContext: IDataContext) => new ParentOnlyDirective(dataContext);
app.directive("parentOnly", parentOnlyDirective);

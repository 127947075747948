import { app } from "../app.module";
import { ILogService, IScope } from "angular";
import { IDeviceService } from "../services/deviceServiceProvider";
import { IToastrService } from "angular-toastr";
import { IAssetService } from "../services/assetService";
interface IExternalFileDownloadLinkScope extends IScope {
    href?: string;
}

app.directive("externalFileDownloadLink", function ($log: ILogService, toastr: IToastrService, deviceService: IDeviceService, assetService: IAssetService) {
    "ngInject";
    return {
        restrict: "A",
        scope: {
            href: "@externalFileDownloadLink"
        },
        link: function (scope: IExternalFileDownloadLinkScope, element) {
            $(element).on("click", function (e) {
                e.preventDefault();

                const url = scope.href;

                $log.debug(`externalFileDownloadLink - ${url}`);

                if (!deviceService.isMobileApp) {
                    assetService.openFile(url);
                    return;
                }
                assetService.mobileOpenFile(url, true).catch(() => {
                    toastr.error("Error downloading the file", "Error");
                });
            });
        }
    };
});

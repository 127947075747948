import { app } from "../../../app.module";
import templateUrl from "./lives-easier-section.html";
import "./lives-easier-section.less";

app.component("livesEasierSectionComponent", {
    templateUrl: templateUrl,
    controller: livesEasierSection,
    controllerAs: "vm"
});

function livesEasierSection() {
    var vm = this;

    vm.loadImage = function(imagePath) {
        return require('../../../../content/img/' + imagePath);
    };
}
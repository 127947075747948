import moment from "moment";

const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function isWeekend() {
    var dayOfWeek = getDayOfTheWeek();
    return dayOfWeek === "Saturday" || dayOfWeek === "Sunday";
}

export function getToday() {
    var d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
}

export function isToday(date) {
    var today = getToday();
    return date.getTime() === today.getTime();
}

export function isTomorrow(date) {
    var today = getToday();
    var tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    return date.getTime() === tomorrow.getTime();
}

export function getDayOfTheWeek(date?: Date) {
    var todaysDayIndex = getDayOfTheWeekIndex(date);
    return DAYS[todaysDayIndex];
}

export function getDayOfTheWeekIndex(date?: Date) {
    var todaysDayIndex = (date || new Date()).getDay();
    return todaysDayIndex;
}

export function getDayOfWeekLabel(day) {
    var date = new Date(day.date);
    if (isToday(date)) return "Today";
    if (isTomorrow(date)) return "Tomorrow";
    return getDayOfTheWeek(date);
}

export function getTimeOptions(intervalInMinutes: number = 15) {
    const startDate = moment().startOf("day").hour(7);
    const endDate = moment().startOf("day").hour(17);
    const options: Date[] = [];

    let currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
        options.push(currentDate.toDate());
        currentDate.add(intervalInMinutes, "minutes");
    }
    return options;
}

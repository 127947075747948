import { app } from "../app.module";
import templateUrl from "./student.html";
import "./studentSidebar";
import "./studentHeader";
import "./studentFooter";
import "./widgets";
import "./student.less";
import { IContextLoader } from "../services/contextLoader";

const controllerId = "student";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: studentLoad,
    controllerAs: "vm",
    restrict: "E",
    replace: true,
    scope: true
}));

function studentLoad($routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader) {
    "ngInject";
    const vm = this;
    vm.user = null;
    vm.student = null;
    vm.enrolment = null;

    contextLoader.load(vm, $routeParams, controllerId);
}

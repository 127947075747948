import { ILogService, IQService } from "angular";
import { app } from "../app.module";
import { ICommonService } from "../services/common";
import { IDataContext } from "../services/dataContext";

const controllerId = "mobile";
import templateUrl from "./mobile.html";
import { IApiService } from "../services/apiServiceProvider";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: mobile,
    controllerAs: "vm",
    restrict: "E",
    replace: true
}));

function mobile(common: ICommonService, dataContext: IDataContext, $q: IQService, apiService: IApiService, $log: ILogService, $scope) {
    "ngInject";
    var vm = this;
    var userToUpdate = null;

    vm.mobile = null;
    vm.save = save;
    vm.submitted = false;
    vm.busy = false;
    vm.error = false;

    common.activateController([getProfile()], controllerId).then(function () {});

    function getProfile() {
        return dataContext.getData().then(function (user) {
            userToUpdate = user;
            vm.mobile = dataContext.isAdminView() ? "Unavailable in QParents Administration View" : user.mobileNumber;
        });
    }

    function save() {
        if ($scope.editMobile.$invalid) return;

        vm.busy = true;
        vm.error = false;

        var deferred = $q.defer();
        var newMobile = vm.mobile;

        apiService
            .changeMobile({
                mobile: newMobile
            })
            .then(() => {
                userToUpdate.mobileNumber = newMobile;
                vm.submitted = true;
            })
            .catch(response => {
                vm.error = true;
                $log.error("Error changing the mobile", response);
            })
            .finally(() => {
                vm.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }
}

import {app as services} from '../app.module';

var serviceId = 'creditCardService';
services.factory(serviceId, [creditCardService]);

function creditCardService() {
    return {
        isAValidCreditCardNumber: isAValidCreditCardNumber
    };

    function isNumeric(character) {
        return /\d/.test(character);
    }

    function isValidLuhn(number) {
        var digit, n, sum, j, len1;
        sum = 0;
        var ref2 = number.split('').reverse();
        for (n = j = 0, len1 = ref2.length; j < len1; n = ++j) {
            digit = ref2[n];
            digit = +digit;
            if (n % 2) {
                digit *= 2;
                if (digit < 10) {
                    sum += digit;
                } else {
                    sum += digit - 9;
                }
            } else {
                sum += digit;
            }
        }
        return sum % 10 === 0;
    };

    function isValidLength(creditCardNumber) {
        return creditCardNumber.length >= 13 && creditCardNumber.length <= 16;
    }

    function canonicalise(creditCardNumber) {
        var canonicalCreditCardNumber = creditCardNumber.replace(/\s/g, "");
        canonicalCreditCardNumber = canonicalCreditCardNumber.replace(/-/g, "");
        return canonicalCreditCardNumber;
    }

    function isAValidCreditCardNumber(creditCardNumber) {

        // allow blank (required will take care of validation)
        if (creditCardNumber === null || creditCardNumber === undefined) return true;
        if (creditCardNumber === '') return true;

        var canonicalNumber = canonicalise(creditCardNumber);

        return {
            isValid: isValidLength(canonicalNumber) && isValidLuhn(canonicalNumber),
            canonicalNumber: canonicalNumber
        };
    }
}
import { IPromise } from "angular";
import { app as services } from "../app.module";
import { QLearnLinkModel } from "../models/api";
import { IApiService } from "./apiServiceProvider";

const serviceId = "qLearnService";

export class QLearnService {
    public qLearnLink: IPromise<QLearnLinkModel>;
    public initialized: boolean = false;

    constructor(private apiService: IApiService, $q: ng.IQService) {
        "ngInject";
        this.qLearnLink = $q.defer<QLearnLinkModel>().promise;
        this.initialized = false;
    }

    getQLearnLink() {
        if (this.initialized) return this.qLearnLink;

        this.qLearnLink = this.apiService
            .getQLearnLink<QLearnLinkModel>()
            .then(function (response) {
                return response.data;
            })
            .finally(() => (this.initialized = true));

        return this.qLearnLink;
    }
}

services.service(serviceId, QLearnService);

import { app } from "../app.module";
import { FEATURES, ADMIN_VIEW_COOKIES } from "../configuration";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import { IDataContext } from "../services/dataContext";
import { DayViewModel } from "../models/api";
import templateUrl from "./timetable.html";

const controllerId = "timetable";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: TimetableComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

const HEIGHT_MULTIPLIER = 2.4;

class TimetableComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    firstWeekDays: DayViewModel[];
    secondWeekDays: DayViewModel[];
    anyTimetableInformation: boolean;

    constructor($routeParams: angular.route.IRouteParamsService, contextLoader: IContextLoader, authorisationService: AuthorisationService, private dataContext: IDataContext) {
        "ngInject";
        this.anyTimetableInformation = false;

        this.firstWeekDays = [];
        this.secondWeekDays = [];

        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasFeatureAccess(this, FEATURES.TIMETABLE)) {
                return;
            }

            this.firstWeekDays = this.enrolment.timetable.days.filter(function (day) {
                return day.dayOfWeekOrder <= 5;
            });
            this.secondWeekDays = this.enrolment.timetable.days.filter(function (day) {
                return day.dayOfWeekOrder > 5 && day.dayOfWeekOrder <= 10;
            });
            this.anyTimetableInformation = this.enrolment.timetable.days.length > 0;
        });
    }

    toggle(timetableDay) {
        timetableDay.expanded = timetableDay.expanded ? false : true;
    }

    toggleToolTip(period) {
        var showTooltip = true;
        if (period.showTooltip && period.showTooltip == true) {
            showTooltip = false;
        }

        this.hideAllTooltips();

        period.showTooltip = showTooltip;
    }

    hideAllTooltips() {
        this.enrolment.timetable.days.forEach(function (day: any) {
            day.periods.forEach(function (periodItem: any) {
                if (periodItem.showTooltip) periodItem.showTooltip = false;
            });
        });
    }

    dayHeight(day) {
        return day.range * HEIGHT_MULTIPLIER + "px";
    }

    periodTop(period) {
        return period.startTimeMinuteOffset * HEIGHT_MULTIPLIER + "px";
    }

    periodHeight(period) {
        return period.lengthInMinutes * HEIGHT_MULTIPLIER + "px";
    }

    periodRollCall(period) {
        if (period.lengthInMinutes > 20) {
            return false;
        } else {
            return true;
        }
    }

    getTimetableLink() {
        let url = `/api/timetable/${this.student.oneSchoolId}/${this.enrolment.schoolCode}`;

        if (this.dataContext.isAdminView()) {
            let schoolUserId = sessionStorage.getItem(ADMIN_VIEW_COOKIES.SCHOOL_USER_ID);
            let ppaoId = sessionStorage.getItem(ADMIN_VIEW_COOKIES.PARENT_ID);
            url += `?AdminPPAOId=${ppaoId}&AdminSchoolUserId=${schoolUserId}`;
        }

        return url;
    }

    loadImage(imagePath: string) {
        return require("../../content/img/" + imagePath);
    }
}

import { formatDateToShortMonthDay } from "./dateHelper";

interface DateTimeInfo {
    date: Date;
    time: string;
}

export function getFormattedTimeRange(start: DateTimeInfo, end: DateTimeInfo) {
    const startDate = new Date(start.date);
    const endDate = new Date(end.date);
    var startDateInfo = "";
    var endDateInfo = "";
    if (startDate.toDateString() !== endDate.toDateString()) {
        startDateInfo = " (" + formatDateToShortMonthDay(startDate) + ")";
        endDateInfo = " (" + formatDateToShortMonthDay(endDate) + ")";
    }

    return stripSeconds(start.time) + startDateInfo + " - " + stripSeconds(end.time) + endDateInfo;
}

function stripSeconds(time: string) {
    if (!time) return time;
    // Show only hh:mm tt, with tt optionally.
    return time.replace(/(\d{1,2}:\d{2})(:[:\d]{0,})(\W{0,}[^\d]{0,}){0,1}/, "$1$3");
}

import { app } from "../app.module";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { PhotoService } from "../services/photoService";
import angular from "angular";
import { IDeviceService } from "../services/deviceServiceProvider";

const controllerId = "upload";
import templateUrl from "./upload.html";
import "./upload.less";

interface InputFileEvent extends Event {
    target: HTMLInputElement;
}

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: UploadComponent,
    controllerAs: "vm",
    restrict: "E",
    replace: false
}));

/* const DefaultImageOptions: ImageOptions = {
    quality: 50,
    height: 150,
    width: 150,
    saveToGallery: true,
    correctOrientation: true,
    resultType: CameraResultType.DataUrl
}; */

class UploadComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    busy: boolean;
    newPhoto: { dataUrl?: string; format?: string }; // from input-image
    isMobileApp: boolean;
    uploaded: boolean;
    error: boolean;
    capture: "environment" | null;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        private $q: angular.IQService,
        private $location: angular.ILocationService,
        private photoService: PhotoService,
        private $anchorScroll: angular.IAnchorScrollService,
        private $log: angular.ILogService,
        deviceService: IDeviceService
    ) {
        "ngInject";
        this.isMobileApp = deviceService.isMobileApp;
        this.newPhoto = {};
        contextLoader
            .load(this, $routeParams, controllerId)
            .then(() => {
                if (this.isDelegatedViewer()) return;
            })
            .then(() => {
                this.$anchorScroll("upload-a-photo-heading");
            });
    }

    isDelegatedViewer() {
        if (!this.student.isUserPPAOForStudent) {
            var newPath = "/";
            this.$location.path(newPath);
            return true;
        }
        return false;
    }

    takePhoto() {
        return this.handleFileInputEvent("#inputTakePhoto");
    }

    fromGallery() {
        return this.handleFileInputEvent("#inputUploadFile");
    }

    handleFileInputEvent(id: string) {
        const deferred = this.$q.defer<any>();

        this.error = false;

        $(id).trigger("click");

        $(id).on("change", event => {
            this.busy = true;
            this.uploadAttachment(event.originalEvent)
                .then(result => {
                    this.newPhoto.dataUrl = result.dataUrl;
                    this.newPhoto.format = result.mimeType;
                    this.uploaded = true;
                })
                .catch(response => {
                    this.error = true;
                    this.$log.error("Error uploading attachment", response);
                })
                .finally(() => {
                    deferred.resolve();
                    this.busy = false;
                });
        });

        $(id).on("blur", event => {
            deferred.resolve();
        });

        return deferred.promise;
    }

    uploadAttachment(e: Event) {
        const deferred = this.$q.defer<{ dataUrl: string; mimeType: string }>();

        const fileInput = (e as InputFileEvent).target;
        const fileInputFiles = fileInput.files ?? [];

        // upload attachments
        if (fileInputFiles.length > 0) {
            const file = fileInputFiles[0];
            const reader = new FileReader();

            reader.onload = e => {
                const img = document.createElement("img");
                const dataUrl = e.target.result as string;
                const mimeType = dataUrl.split(";")[0].split(":")[1];

                img.onload = e => {
                    const resizedDataUrl = this.resizeImage(img, mimeType);

                    // Show resized image in preview element
                    deferred.resolve({
                        dataUrl: resizedDataUrl,
                        mimeType: mimeType
                    });

                    img.onload = null;
                };
                img.src = dataUrl;
            };

            reader.readAsDataURL(file);
        }
        // reset input
        fileInput.value = fileInput.defaultValue;

        return deferred.promise;
    }

    resizeImage(img: HTMLImageElement, mimeType: string) {
        const maxWidth = 300;
        const maxHeight = 300;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let width = img.width;
        let height = img.height;

        // Calculate proportional dimensions
        if (width > maxWidth || height > maxHeight) {
            const ratio = Math.min(maxWidth / width, maxHeight / height);
            width = Math.floor(width * ratio);
            height = Math.floor(height * ratio);
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        const resizedDataUrl = canvas.toDataURL(mimeType);
        return resizedDataUrl;
    }

    accept() {
        if (this.busy) return;
        const deferred = this.$q.defer();

        this.busy = true;
        this.error = false;

        var resizedImage = this.newPhoto.dataUrl;

        this.photoService
            .uploadPhoto(this.student.oneSchoolId, resizedImage)
            .then(photoUrl => {
                this.student.photoUrl = photoUrl;
                this.student.photoContentUrl = resizedImage;
                this.$location.path(`/student/${this.student.oneSchoolId}/${this.enrolment.schoolCode}`);
            })
            .catch(response => {
                this.error = true;
                this.$log.error("Error uploading photo", response);
            })
            .finally(() => {
                this.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    navigateBack() {
        window.history.back();
    }

    /* Enable this for Native Camera Functionality 
    Current Issue: 
    - Limited Access Photo Library Require Manual Implementation https://github.com/ionic-team/capacitor/issues/3841
   private takePhotoUsingNative() {
        const deferred = this.$q.defer<any>();
        this.$q.when(Camera.checkPermissions()).then(permissionStatus => {
            if (permissionStatus.camera === "prompt" || permissionStatus.camera === "prompt-with-rationale") {
                Camera.requestPermissions({ permissions: ["camera"] }).then(requestPermissionResult => {
                    this.getPhotoFromNativeCamera(deferred, requestPermissionResult);
                });
                return;
            }
            this.getPhotoFromNativeCamera(deferred, permissionStatus);
        });

        return deferred.promise;
    }

    private getPhotoFromNativeCamera(deferred: IDeferred<any>, permissionStatus: PermissionStatus) {
        if (permissionStatus.camera === "granted" || permissionStatus.camera === "limited") {
            Camera.getPhoto({ ...DefaultImageOptions, source: CameraSource.Camera, direction: CameraDirection.Rear })
                .then(photo => {
                    this.getNativeCameraSuccesHandler(photo);
                    deferred.resolve();
                })
                .catch(error => {
                    this.$log.warn(`Error taking picture with camera: ${error}`);
                    deferred.reject(error);
                })
                .finally(() => {
                    this.busy = false;
                });
        } else {
            deferred.reject("Permission to access camera not granted");
            this.busy = false;
        }
    }

    private fromGalleryUsingNative() {
        const deferred = this.$q.defer<any>();

        this.$q.when(Camera.checkPermissions()).then(permissionStatus => {
            if (permissionStatus.photos === "prompt" || permissionStatus.photos === "prompt-with-rationale") {
                Camera.requestPermissions({ permissions: ["photos"] }).then(requestPermissionResult => {
                    this.getPhotoFromNativeGallery(deferred, requestPermissionResult);
                });
                return;
            }
            this.getPhotoFromNativeGallery(deferred, permissionStatus);
        });

        return deferred.promise;
    }

    private getPhotoFromNativeGallery(deferred: IDeferred<any>, permissionStatus: PermissionStatus) {
        if (permissionStatus.photos === "granted" || permissionStatus.photos === "limited") {
            Camera.getPhoto({ ...DefaultImageOptions, source: CameraSource.Photos })
                .then(photo => {
                    this.getNativeCameraSuccesHandler(photo);
                    deferred.resolve();
                })
                .catch(error => {
                    this.$log.warn(`Error getting picture from gallery: ${error}`);
                    deferred.reject(error);
                })
                .finally(() => {
                    this.busy = false;
                });
        } else {
            deferred.reject("Permission to access library not granted");
            this.busy = false;
        }
    }

    getNativeCameraSuccesHandler(photo: Photo) {
        this.newPhoto.dataUrl = photo.dataUrl;
        this.newPhoto.format = photo.format;
    }*/
}

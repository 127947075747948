import { app } from "../../app.module";
import { IUrlResolver } from "@/app/services/urlResolver";
import { SESSION_AO_CODE_KEY, TERMS_AND_CONDITION_LABELS } from "../../configuration";
import { platformService } from "../../services/platformService";
import "./invitation.less";

const controllerId = "accountOwnerInvitation";
import templateUrl from "./invitation.html";
import { IDataContext } from "@/app/services/dataContext";
import { ILocalStorageService } from "angular-local-storage";
import { ILocationService, ISCEService } from "angular";
import { IValidateInvitationCodeResponse } from "@/app/services/invitationService";

app.component(`${controllerId}Component`, {
    templateUrl: templateUrl,
    controller: accountOwnerInvitation,
    controllerAs: "vm",
    bindings: {} // Add any bindings here
});

function accountOwnerInvitation(
    $routeParams: angular.route.IRouteParamsService,
    $sce: ISCEService,
    $location: ILocationService,
    localStorageService: ILocalStorageService,
    invitationService,
    dataContext: IDataContext,
    authorisationService,
    platformService: platformService,
    urlResolver: IUrlResolver
) {
    "ngInject";
    var vm = this;
    var sessionId = null;

    vm.busy = false;

    vm.code = null;
    vm.validationResponse = null;

    vm.disableCodeInput = false;
    vm.showNext = true;
    vm.showTryAgain = false;
    vm.showAccept = false;

    vm.pendingTermsAndConditions = [];
    vm.acceptedAllTermsAndConditions = false;
    vm.hasTermsAndConditions = false;

    vm.validateInvitationCode = validateInvitationCode;
    vm.checkIfAllTermsAndConditionsAccepted = checkIfAllTermsAndConditionsAccepted;
    vm.navigateToNameVerificationIfNotBusy = navigateToNameVerificationIfNotBusy;
    vm.hideErrorMessage = hideErrorMessage;

    initialise();

    function initialise() {
        clearSessionInvitationCode();

        if (dataContext.isAuthenticated()) {
            authorisationService.logout($location.url());
            return;
        }
        vm.IsOutDatedBrowser = platformService.isSafariBrowserAndVersionIsLessThanOrEqualTo12();
        vm.code = $routeParams.code;
    }

    function validateInvitationCode(code) {
        if (vm.busy) return;
        if (vm.form.$invalid) return;
        vm.busy = true;

        return invitationService
            .validateInvitationCode(code)
            .then(refreshPage)
            .finally(function () {
                vm.busy = false;
            });
    }

    function refreshPage(validationResponse: IValidateInvitationCodeResponse) {
        vm.validationResponse = validationResponse;
        vm.showNext = false;
        vm.showTryAgain = !validationResponse.valid;
        vm.showAccept = validationResponse.valid;

        sessionId = validationResponse.sessionId;

        if (validationResponse.valid) {
            vm.disableCodeInput = true;
            showAnyPendingTermsAndConditions(validationResponse.pendingTermsAndConditionsKeys);
        }
    }

    function navigateToNameVerificationIfNotBusy() {
        if (vm.busy) return;
        vm.busy = true;

        navigateToNameVerification();
    }

    function navigateToNameVerification() {
        localStorageService.set(SESSION_AO_CODE_KEY, sessionId);
        $location.path("/registration/account-owner/verify");
    }

    function showAnyPendingTermsAndConditions(pendingTermsAndConditionsKeys: string[]) {
        vm.hasTermsAndConditions = pendingTermsAndConditionsKeys.length > 0;
        vm.acceptedAllTermsAndConditions = !vm.hasTermsAndConditions;

        if (vm.acceptedAllTermsAndConditions) {
            navigateToNameVerification();
            return;
        }

        vm.pendingTermsAndConditions = pendingTermsAndConditionsKeys.map(function (key) {
            return {
                url: $sce.trustAsResourceUrl(urlResolver.resolve("/api/termsandconditions/" + key)),
                checked: false,
                key: key,
                label: getCheckboxLabelForTermsAndCondition(key)
            };
        });
    }

    function getCheckboxLabelForTermsAndCondition(key: string) {
        var type = key.split("-")[0].toUpperCase();
        return TERMS_AND_CONDITION_LABELS[type];
    }

    function checkIfAllTermsAndConditionsAccepted(pendingTermsAndConditions) {
        vm.acceptedAllTermsAndConditions = pendingTermsAndConditions.every(function (tc) {
            return tc.checked;
        });
    }

    function hideErrorMessage() {
        vm.validationResponse = null;
    }

    function clearSessionInvitationCode() {
        localStorageService.remove(SESSION_AO_CODE_KEY);
    }
}

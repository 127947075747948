import { IApiService } from "@/app/services/apiServiceProvider";
import { app } from "../../app.module";
import { SESSION_DV_CODE_KEY } from "../../configuration";
import { ILocationService, ILogService, IQService } from "angular";
import { ILocalStorageService } from "angular-local-storage";
import { IDataContext } from "@/app/services/dataContext";

const controllerId = "delegatedViewerRegister";
const templateUrl = require("./register.html");

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: delegatedViewerRegister,
    controllerAs: "vm",
    restrict: "E",
    replace: true
}));

function delegatedViewerRegister(
    $window,
    $location: ILocationService,
    localStorageService: ILocalStorageService,
    apiService: IApiService,
    dataContext: IDataContext,
    $log: ILogService,
    $q: IQService
) {
    "ngInject";
    var vm = this;
    var sessionId = null;

    vm.loginBusy = false;
    vm.login = {};
    vm.showLoginFailed = false;
    vm.showLoginSucceeded = false;
    vm.showAcceptingOwnInvitationMessageError = false;
    vm.showStudentHasAlreadyBeenDelegatedToYouError = false;

    vm.registerBusy = false;
    vm.registration = {};
    vm.showConfirmationMessage = false;
    vm.showEmailAlreadyRegistered = false;
    vm.submitButtonText = "Submit";

    vm.showRegister = false;
    vm.showLogin = false;
    vm.showAlreadyLoggedInMessage = false;
    vm.showAcceptingOwnInvitationMessage = false;

    vm.heading = null;

    vm.cancel = cancel;
    vm.submit = submit;
    vm.doLogin = doLogin;
    vm.navigateHome = navigateHome;

    initialise();

    function initialise() {
        var sessionToken = getSessionToken();

        if (!sessionToken) {
            $location.path("/");
            return;
        }

        sessionId = sessionToken.sessionId;

        vm.showRegister = true;
        vm.showLogin = true;
        vm.heading = "Create an account or login";
    }

    function submit(registration) {
        if (vm.registerBusy) return;
        var deferred = $q.defer();

        vm.registerBusy = true;
        vm.showEmailAlreadyRegistered = false;
        registration.sessionId = sessionId;

        apiService
            .delegatedViewerRegister(registration)
            .then(function successCallback(response) {
                const data = response.data;
                if (data.emailAlreadyRegistered) {
                    vm.showEmailAlreadyRegistered = true;
                    vm.submitButtonText = "Retry";
                } else {
                    removeSessionToken();

                    vm.showConfirmationMessage = true;
                    vm.showLogin = false;
                }
            })
            .finally(function () {
                vm.registerBusy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    function doLogin(login) {
        if (vm.loginBusy) return;
        var deferred = $q.defer();

        vm.loginBusy = true;
        login.sessionId = sessionId;

        dataContext.preSignIn();
        apiService
            .delegatedViewerLogin(login)
            .then(response => {
                const data = response.data;
                $log.debug("Login success. Retrieving user");
                dataContext.flagSignedIn(login.email);
                dataContext.verifyAuthenticated().then(function () {
                    if (data.acceptingOwnInvitation) {
                        vm.showAcceptingOwnInvitationMessageError = true;
                        vm.showLogin = false;
                    } else if (data.alreadyHasDelegatedStudent) {
                        vm.showStudentHasAlreadyBeenDelegatedToYouError = true;
                        vm.showLogin = false;
                    } else {
                        vm.showLoginSucceeded = true;
                    }

                    vm.showRegister = false;
                    removeSessionToken();
                });
            })
            .catch(response => {
                if (response.status === 401) {
                    vm.showLoginFailed = true;
                    vm.message = response.data?.message;
                }
            })
            .finally(function () {
                vm.loginBusy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    function getSessionToken() {
        return localStorageService.get(SESSION_DV_CODE_KEY);
    }

    function cancel() {
        removeSessionToken();
        $location.path("/");
    }

    function navigateHome() {
        removeSessionToken();
        $window.location = "/";
    }

    function removeSessionToken() {
        localStorageService.remove(SESSION_DV_CODE_KEY);
    }
}

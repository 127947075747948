import { IDataContext } from "../../../services/dataContext";
import { app } from "../../../app.module";
import { FEATURES } from "../../../configuration";
import { UpcomingEventsSummaryViewModel } from "@/app/models/api";

require("../parentCentricSummary.less");
require("./upcomingEventsSummaryWidget.less");
import templateUrl from "./upcomingEventsSummaryWidget.html";
app.directive("upcomingEventsSummaryWidget", () => ({
    templateUrl,
    controller: UpcomingEventsSummaryWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

class UpcomingEventsSummaryWidget {
    upcomingEventsSummary: UpcomingEventsSummaryViewModel;
    upcomingEventsFeatureTurnedOffForAllStudents: boolean;

    constructor(private dataContext: IDataContext) {
        "ngInject";

        this.dataContext.getData().then(user => {
            this.upcomingEventsFeatureTurnedOffForAllStudents = !user.students.some(student => student.features.some(feature => feature.toString() === FEATURES.UPCOMING_EVENTS.toString()));
            this.upcomingEventsSummary = user.upcomingEventsSummary;
        });
    }
}

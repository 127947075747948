import { app } from "../app.module";
import angular from "angular";
import { FEATURES } from "../configuration";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import { ProcessPaymentService, ProcessPaymentResult } from "../services/processPaymentService";
import "./processPayment.less";
import { BPointVerifyBatchResultViewModel, BPointClientPaymentResponse } from "../models/api";

import templateUrl from "./processPayment.html";
const controllerId = "processPayment";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: ProcessPaymentComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

class ProcessPaymentComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    totalPaymentValue: number;
    busy: boolean;
    paymentRequestEnded: boolean;
    paymentCompletelyFailed: boolean;

    // Warning: Not sure why there's payment and payments here, but I'm
    // doing a big refactor and don't want to risk changing it.
    paymentResult: string;
    paymentsResult: string;
    creditCardNumber: string;
    creditCardExpiry: string;
    creditCardCvv: string;
    verifiedPaymentResponses: BPointVerifyBatchResultViewModel;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        authorisationService: AuthorisationService,
        private $location: ng.ILocationService,
        private $rootScope: any,
        private $q: ng.IQService,
        private processPaymentService: ProcessPaymentService,
        private $document: ng.IDocumentService
    ) {
        "ngInject";
        this.busy = false;
        this.verifiedPaymentResponses = null;
        this.paymentRequestEnded = false;
        this.paymentCompletelyFailed = false;

        contextLoader.load(this, $routeParams, controllerId).then(() => {
            this.totalPaymentValue = this.enrolment.getFinanceRecordsSelectedForPaymentTotal();

            if (!authorisationService.hasFeatureAccess(this, FEATURES.FINANCE)) return;
            if (!this.student.isUserPPAOForStudent || this.enrolment.getFinanceRecordsSelectedForPayment().length === 0) {
                $location.path(`/payment/${this.student.oneSchoolId}/${this.enrolment.schoolCode}`);
            }
        });
    }

    submitPayment(form) {
        if (!form.$valid) return;

        var deferred = this.$q.defer();

        this.$rootScope.showFullScreenLoadingPane("Please wait while BPoint processes your payment");

        this.getPrePaymentAuthenticationTokens()
            .then(
                p => this.sendPayment(),
                f => this.getPrePaymentAuthTokensFailure()
            )
            .then(
                p => this.verifyPayments(p),
                f => this.sendPaymentFailure()
            )
            .then(
                p => this.showOutcome(p),
                f => this.showErrorOutcome()
            )
            .finally(() => {
                this.$rootScope.hideFullScreenLoadingPane();

                var successDiv = angular.element(document.getElementById("main-content"));
                (<any>this.$document).scrollToElement(successDiv, 0, 0);

                deferred.resolve();
            });

        return deferred.promise;
    }

    getPrePaymentAuthTokensFailure() {
        this.paymentCompletelyFailed = true;
        return this.$q.reject();
    }

    sendPaymentFailure() {
        return this.$q.reject();
    }

    verifyPayments(paymentResponses: BPointClientPaymentResponse[]) {
        return this.processPaymentService.verifyPaymentResponse(paymentResponses);
    }

    showOutcome(data: BPointVerifyBatchResultViewModel) {
        this.verifiedPaymentResponses = data;

        var hasSuccessfulPayments = this.verifiedPaymentResponses.successfulPayments.length > 0;
        var hasFailedPayments = this.verifiedPaymentResponses.failedPayments.length > 0;
        var hasUnknownOutcomePayments = this.verifiedPaymentResponses.unknownOutcomePayments && this.verifiedPaymentResponses.unknownOutcomePayments.length > 0;

        if (hasUnknownOutcomePayments) this.paymentResult = "Unknown";
        else if (hasSuccessfulPayments && !hasFailedPayments) this.paymentsResult = "Success";
        else if (hasSuccessfulPayments && hasFailedPayments) this.paymentsResult = "Partial Success";
        else if (!hasSuccessfulPayments && hasFailedPayments) this.paymentsResult = "Failure";

        this.markSuccessfulPaymentsAsInProgress(this.verifiedPaymentResponses);

        this.paymentRequestEnded = true;
    }

    showErrorOutcome() {
        this.paymentRequestEnded = true;
    }

    sendPayment() {
        var deferred = this.$q.defer<ProcessPaymentResult[]>();

        if (this.paymentCompletelyFailed) {
            deferred.reject();
            return deferred.promise;
        }

        var processPaymentPromises: ng.IPromise<ProcessPaymentResult>[] = [];

        this.enrolment.getFinanceRecordsSelectedForPayment().forEach((financeRecord, index) => {
            var promise = this.processPaymentService.sendPayment(financeRecord, this.creditCardNumber, this.creditCardExpiry, this.creditCardCvv, index);
            processPaymentPromises.push(promise);
        });

        this.$q.all(processPaymentPromises).then(values => {
            deferred.resolve(values);
            this.clearSelectedPayments();
        });

        return deferred.promise;
    }

    getPrePaymentAuthenticationTokens() {
        return this.processPaymentService.getPaymentAuthenticationTokens(this.enrolment.financeRecordsDue).then(data => {
            this.enrolment.financeRecordsDue = data;
        });
    }

    back() {
        this.cancel();
    }

    cancel() {
        this.clearSelectedPayments();
        this.$location.path(`/payment/${this.student.oneSchoolId}/${this.enrolment.schoolCode}`);
    }

    clearSelectedPayments() {
        this.enrolment.getFinanceRecordsSelectedForPayment().forEach(financeRecord => {
            financeRecord.isSelectedForPayment = false;
            financeRecord.paymentAmount = financeRecord.amountOwing;
        });
    }

    getTotalReceiptValue() {
        if (!this.verifiedPaymentResponses || !this.verifiedPaymentResponses.successfulPayments) return 0;

        var amount = this.verifiedPaymentResponses.successfulPayments.reduce((sum, payment) => {
            return sum + payment.amount;
        }, 0);

        return amount;
    }

    markSuccessfulPaymentsAsInProgress(response) {
        this.enrolment.recordCurrentPayments(response.paymentDateTime, response.successfulPayments);
    }
}

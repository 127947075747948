import { app } from "../../app.module";
import { recaptchaService } from "../../services/recaptchaService";

const templateUrl = require("./confirmResetPassword.html");
const controllerId = "confirmResetPassword";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: confirmResetPassword,
    controllerAs: "vm",
    restrict: "E",
    replace: true,
    scope: true
}));

function confirmResetPassword($routeParams: angular.route.IRouteParamsService, recaptchaService: recaptchaService) {
    "ngInject";
    var vm = this;
    vm.busy = true;
    vm.confirmationToken = $routeParams.ct;
    recaptchaService
        .execute({ action: "login" })
        .then(() => {
            window.location.href = `api/forgot-password/confirm-reset-password/${vm.confirmationToken}`;
        })
        .catch(err => {
            vm.busy = false;
        });
}

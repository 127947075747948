export let ENABLE_CONSOLE_LOGGING = false;
let SHOW_TOASTS_ON_ERROR = false;
export const STATES = ["QLD", "ACT", "NSW", "NT", "SA", "TAS", "VIC", "WA"];
export const FEATURES = {
    ENROLMENT_HISTORY: "Enrolment History" as StudentFeature,
    STUDENT_DETAILS: "Student Details" as StudentFeature,
    ATTENDANCE: "Attendance" as EnrolmentFeature,
    BEHAVIOUR: "Behaviour" as EnrolmentFeature,
    FINANCE: "Finance" as EnrolmentFeature,
    TIMETABLE: "Timetable" as EnrolmentFeature,
    ACADEMIC_REPORTS: "Academic Reports" as EnrolmentFeature,
    UPCOMING_EVENTS: "Upcoming Events" as EnrolmentFeature,
    EXAM_TIMETABLE: "Exam Timetable" as EnrolmentFeature,
    ASSESSMENT_PLANNER: "Assessment Planner" as EnrolmentFeature,
    CONSENT_REQUESTS: "Consent Requests" as StudentFeature
};
export type StudentFeature = "Enrolment History" | "Student Details" | "Student Photo" | "ConsentRequests";
export type EnrolmentFeature = "Behaviour" | "Attendance" | "Timetable" | "Finance" | "Academic Reports" | "Assessment Planner" | "ExamTimetable" | "Upcoming Events";

export const SESSION_AO_CODE_KEY = "account-owner-registration-session";
export const SESSION_DV_CODE_KEY = "delegated-viewer-registration-session";
export const SESSION_PROCESSED_PAYMENTS_KEY = "processed-payments-session";
export const TERMS_AND_CONDITION_LABELS = {
    PRIVACYSTATEMENT: "Read and understood",
    QPAOTERMSANDCONDITIONS: "Accept Terms and Conditions",
    QPDVTERMSANDCONDITIONS: "Accept Terms and Conditions",
    STUDENTPHOTOSTERMSANDCONDITIONS: "Accept Terms and Conditions"
};
export const AVATAR_URL = require("../content/img/avatar.png");
export const AVATAR_NOPHOTO_URL = require("../content/img/avatarNoPhoto.png");
export const SPINNER_URL = require("../content/img/spinner.gif");
export const AUTH_COOKIE_NAME = "ppIsAuthenticated";
export const ADMIN_VIEW_COOKIES = {
    IS_ADMIN_VIEW: "ppAdminView.IsAdminView",
    CENTRE_CODE: "ppAdminView.CentreCode",
    SCHOOL_USER_ID: "ppAdminView.SchoolUserId",
    PARENT_ID: "ppAdminView.ppParentId"
};
export const REMEMBER_EMAIL_KEY = "rememberEmail";
export const REDIRECT_PHOTO_COOKIE_NAME = "redirectToPhotoPreview";
export const YEAR_LEVELS = [
    { description: "Year 1", value: "01" },
    { description: "Year 2", value: "02" },
    { description: "Year 3", value: "03" },
    { description: "Year 4", value: "04" },
    { description: "Year 5", value: "05" },
    { description: "Year 6", value: "06" },
    { description: "Year 7", value: "07" },
    { description: "Year 8", value: "08" },
    { description: "Year 9", value: "09" },
    { description: "Year 10", value: "10" },
    { description: "Year 11", value: "11" },
    { description: "Year 12", value: "12" },
    { description: "Continuing Junior", value: "CJ" },
    { description: "Continuing Senior", value: "CS" },
    { description: "Early Childhood", value: "0P" },
    { description: "Prep Year", value: "PY" },
    { description: "Primary Ungraded", value: "PU" },
    { description: "Secondary Ungraded", value: "SU" },
    { description: "Special", value: "PS" }
];

export const CSRF_HEADER_NAME = "NCSRF";

export const GOOGLE_PLAY_APP_PAGE = "https://play.google.com/store/apps/details?id=au.gov.qld.deta.qparents&hl=en_AU";
export const APPLE_STORE_APP_PAGE = "https://apps.apple.com/au/app/qparents/id923225089";

export function enableConsoleLogging() {
    ENABLE_CONSOLE_LOGGING = true;
}

export function enableToastsOnError() {
    SHOW_TOASTS_ON_ERROR = true;
}

export function showToastsOnError() {
    return SHOW_TOASTS_ON_ERROR;
}

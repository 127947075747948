import { app } from "../app.module";
import { IContextLoader, StudentContext } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { AuthorisationService } from "../services/authorisationService";
import { FEATURES } from "../configuration";
import { ConsentRequest } from "./consentRequestSummary/consentRequestSummary";
import { ConsentService } from "../services/consentService";
import { ConsentRequestSummaryViewModel } from "../models/api";

import templateUrl from "./responseSubmitted.html";

const controllerId = "responseSubmitted";
app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: ResponseSubmittedComponent,
    controllerAs: "vm",
    restrict: "E",
    scope: true,
    replace: false
}));

class ResponseSubmittedComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    request: ConsentRequest;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        authorisationService: AuthorisationService,
        consentService: ConsentService,
        private $location: ng.ILocationService
    ) {
        "ngInject";
        contextLoader.load(this, $routeParams, controllerId).then(() => {
            if (!authorisationService.hasStudentFeatureAccess(this, FEATURES.CONSENT_REQUESTS)) {
                return;
            }

            consentService.getConsentRequestSummary(this.user).then(summary => {
                const request = summary.consentRequests.find((c: ConsentRequestSummaryViewModel) => c.id === $routeParams.requestId && c.eqId === this.student.oneSchoolId);
                this.request = {
                    id: request.id,
                    studentFullName: this.student.fullName,
                    title: request.title,
                    withdrawnDate: request.withdrawnDate,
                    dueDate: request.dueDate,
                    responseDate: new Date().toDateString(),
                    state: request.state,
                    studentId: this.student.oneSchoolId,
                    schoolCode: request.schoolCode,
                    schoolName: this.enrolment.school
                };
            });
        });
    }

    viewConsentRequest(requestId: string, schoolCode: string) {
        const path = `/consent-request/${this.student.oneSchoolId}/${requestId}/${schoolCode}`;
        this.$location.path(path).replace();
    }
}

import moment from "moment";
import { app } from "../app.module";
import INgModelController = angular.INgModelController;
import { parseDate } from "../services/dateHelper";
app.directive("minDate", function () {
    return {
        require: "ngModel",
        link: function (scope, elem, attr, ngModel: INgModelController) {
            var minDate,
                format = attr["format"];

            attr.$observe("minDate", function (value) {
                minDate = parseDate(value) ?? moment(value, format);
                // Revalidate after the min date changes
                var currentDate = moment(ngModel.$modelValue, format);
                ngModel.$setValidity("minDate", minDate <= currentDate);
            });

            //For DOM -> model validation
            ngModel.$parsers.unshift(function (value) {
                var date = moment(value, format);

                ngModel.$setValidity("minDate", minDate <= date);

                return value;
            });

            //For model -> DOM validation
            ngModel.$formatters.unshift(function (value) {
                var date = moment(value, format);

                ngModel.$setValidity("minDate", minDate <= date);

                return value;
            });
        }
    };
});

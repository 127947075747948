import { app as services } from "../app.module";
import { FEATURES } from "../configuration";
import { User } from "../models/user";
import { IPromise } from "angular";
import { IDataContext } from "./dataContext";
import { ConsentSummaryViewModel, ConsentRequestViewModel, ConsentResponseWriteModel } from "../models/api";
import { IApiService } from "./apiServiceProvider";

const serviceId = "consentService";

export class ConsentService {
    public summary: IPromise<ConsentSummaryViewModel>;
    public initialized: boolean = false;

    constructor(private apiService: IApiService, private $q: ng.IQService, dataContext: IDataContext) {
        "ngInject";
        this.summary = $q.defer<ConsentSummaryViewModel>().promise;
        this.initialized = false;
        dataContext.onUserSignedOut(() => this.clear());
    }

    clear() {
        this.summary = this.$q.defer<ConsentSummaryViewModel>().promise;
        this.initialized = false;
    }

    getConsentRequest(studentEqId: string, requestId: string, schoolCode: string) {
        return this.apiService.getConsentRequest<ConsentRequestViewModel>(studentEqId, requestId, schoolCode).then(function (response) {
            return response.data;
        });
    }

    sendConsentResponse(submission: any, requestId: string, studentEqId: string, schoolCode: string) {
        return this.apiService
            .sendConsentResponse<ConsentResponseWriteModel>(studentEqId, requestId, schoolCode, {
                studentEqId,
                requestId,
                data: {
                    ...submission.data
                }
            })
            .then(function (response) {
                return response.data;
            });
    }

    getConsentRequestSummary(user: User) {
        if (!user.consentRequestsEnabled) return this.summary;

        if (this.initialized) return this.summary;

        const students = user.students.filter(student => student.features.some(feature => feature === FEATURES.CONSENT_REQUESTS));

        if (!students.length) return this.summary;

        const query = students
            .map(s => s.enrolments.map(e => `students=${s.oneSchoolId}.${e.schoolCode}`))
            .reduce((acc, curr) => acc.concat(curr))
            .join("&");

        this.summary = this.apiService
            .getConsentRequestSummary<ConsentSummaryViewModel>(query)
            .then(response => {
                return response.data;
            })
            .finally(() => (this.initialized = true));

        return this.summary;
    }
}

services.service(serviceId, ConsentService);

import { app } from "../app.module";
import { Enrolment } from "../models/enrolment";
import templateUrl from "./studentList.html";
import { IDataContext } from "../services/dataContext";
import "../student/enrolmentStatus";

const controllerId = "studentList";
app.component(`${controllerId}Component`, {
    templateUrl: templateUrl,
    bindings: {
        students: "<"
    },
    controller: StudentsListComponent,
    controllerAs: "vm"
});

function StudentsListComponent(dataContext: IDataContext) {
    "ngInject";
    var vm = this;
    vm.adminViewData = null;

    // Use $onInit lifecycle hook instead of constructor
    vm.$onInit = function () {
        dataContext.getData().then(function (d) {
            vm.adminViewData = d.adminViewDetails;
        });
    };

    vm.canAccess = function (enrolment: Enrolment) {
        return vm.adminViewData == null || vm.adminViewData.centreCode.toString() === enrolment.schoolCode;
    };
}

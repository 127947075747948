import { IApiService } from "@/app/services/apiServiceProvider";
import { app } from "../../app.module";

const templateUrl = require("./changeForgottenPassword.html");
const controllerId = "changeForgottenPassword";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    controller: changeForgottenPassword,
    controllerAs: "vm",
    restrict: "E",
    replace: true,
    scope: true
}));

function changeForgottenPassword($routeParams: angular.route.IRouteParamsService, apiService: IApiService) {
    "ngInject";
    var vm = this;
    vm.busy = false;
    vm.email = null;
    vm.save = save;
    vm.confirmationToken = $routeParams.ct;
    vm.confirmationTokenValid = $routeParams.resetPasswordConfirmed === "true";
    vm.newPassword = null;
    vm.newPasswordConfirmation = null;
    vm.success = null;
    vm.reason = "";

    function save() {
        if (vm.busy) return;
        vm.busy = true;

        apiService
            .forgotPasswordSubmit({
                email: vm.email,
                confirmationToken: vm.confirmationToken,
                password: vm.newPassword
            })
            .then(function successCallback(response) {
                const data: PasswordResetResponse = response.data;
                vm.success = data.success;
                vm.reason = data.reason;
            })
            .finally(function () {
                vm.busy = false;
            });
    }
}

interface PasswordResetResponse {
    success: boolean;
    reason?: string;
}

import {app} from "../../app.module";
import {ConsentRequest} from "../consentRequestSummary/consentRequestSummary";
import "../consentRequestSummary/consentRequestSummary"
import templateUrl from "./consentRequestList.html";
import ng from "angular"

export class ConsentRequestList {
  requests: ConsentRequest[]
  type: 'Pending' | 'History'

  constructor(private $location: ng.ILocationService) {
    "ngInject";
  }

  viewConsentRequest(request: ConsentRequest) {
    const path = `/consent-request/${request.studentId}/${request.id}/${request.schoolCode}`;
    this.$location.path(path);
  }
}

const controllerId = "consentRequestList"
app.component(`${controllerId}Component`, {
  templateUrl,
  controller: ConsentRequestList,
  controllerAs: "vm",
  bindings: {
    requests: '<',
    type: '@'
  }
});

import { app as services } from "../app.module";
import angular, { IPromise } from "angular";
import { v4 as uuidv4 } from "uuid";

// Must configure the common service and set its
// events via the commonConfigProvider
class CommonConfigProvider {
    config: any;

    constructor() {
        this.config = {
            // These are the properties we need to set
            //controllerActivatedEvent: '',
            //spinnerToggleEvent: ''
        };
    }

    $get() {
        return {
            config: this.config
        };
    }
}

export function guid() {
    return uuidv4();
}

services.provider("commonConfig", CommonConfigProvider);

services.factory("common", common);

export interface ICommonService {
    activateController(promises: IPromise<any>[], controllerId: string): IPromise<void>;
    broadcast(name: string, ...args: any[]): void;
}

export function common($q: angular.IQService, $rootScope: angular.IRootScopeService, commonConfig: CommonConfigProvider): ICommonService {
    "ngInject";
    return {
        activateController: activateController,
        broadcast: $broadcast
    };

    function activateController(promises, controllerId) {
        var data = { controllerId: controllerId };
        $broadcast(commonConfig.config.controllerActivatingEvent, data);
        return $q.all(promises).then(function (eventArgs) {
            $broadcast(commonConfig.config.controllerActivatedEvent, data);
        });
    }

    function $broadcast(name: string, ...args: any[]) {
        return $rootScope.$broadcast.apply($rootScope, [name, ...args]);
    }
}

import angular, { ILogService } from "angular";
import { app } from "./app.module";
import { CSRF_HEADER_NAME } from "./configuration";
import { IUrlResolver } from "./services/urlResolver";
import { IDeviceService } from "./services/deviceServiceProvider";

app.config(httpConfigurator);

function httpConfigurator($httpProvider: angular.IHttpProvider) {
    "ngInject";
    if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
    }

    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.xsrfCookieName = CSRF_HEADER_NAME;
    $httpProvider.defaults.xsrfHeaderName = CSRF_HEADER_NAME;

    $httpProvider.interceptors.push(clientTypeInterceptor);
}

function clientTypeInterceptor($rootScope, csrfTokenProvider, $log: ILogService, deviceService: IDeviceService, urlResolver: IUrlResolver) {
    "ngInject";
    return {
        request: async function (config) {
            const isApiCall = config.url.indexOf("api/") === 0 || config.url.indexOf("/api/") === 0;
            if (!isApiCall) {
                return config;
            }

            $log.debug("Configure Client Type Interceptor.");

            const crsfToken = csrfTokenProvider.getCsrfToken();
            const headers = await getHttpHeaders($rootScope.userSessionId, deviceService, crsfToken);

            for (const [key, value] of headers) {
                if (!config.headers[key]) {
                    config.headers[key] = value;
                }
            }

            if (deviceService.isMobileApp) {
                if (isApiCall) {
                    config.url = urlResolver.resolve(config.url);
                }
            }

            $log.debug(`Client Type Interceptor Url : ${config.url}`);

            return config;
        }
    };
}

export async function getHttpHeaders(userSessionId: any, deviceService: IDeviceService, crsfToken?: string) {
    const device = await deviceService.getDeviceInfo();
    const uuid = await deviceService.getDeviceId();
    const appInfo = await deviceService.getAppInfo();
    const headers = [
        ["Accept", "application/json, text/plain, */*"],
        ["If-Modified-Since", "0"],
        ["Client-SessionId", userSessionId],
        ["Content-Type", "application/json"]
    ];

    if (crsfToken) {
        headers.push([CSRF_HEADER_NAME, crsfToken]);
    }

    if (device) {
        headers.push(["Client-Device", device.model]);
        headers.push(["Client-Platform", device.platform]);
        headers.push(["Client-Uuid", uuid]);
        headers.push(["Client-OS-Version", device.osVersion]);
        headers.push(["Client-App-Version", appInfo.version]);
    } else {
        headers.push(["Client-Platform", "Web"]);
    }

    return headers;
}

import { IDataContext } from "../../../services/dataContext";
import { app } from "../../../app.module";
import { FEATURES } from "../../../configuration";
import { AbsenceSummaryViewModel } from "@/app/models/api";

require("../parentCentricSummary.less");
require("./absenceSummaryWidget.less");
import templateUrl from "./absenceSummaryWidget.html";
import { EnrolmentStatus } from "../../../models/enums";

app.directive("absenceSummaryWidget", () => ({
    templateUrl,
    controller: AbsenceSummaryWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

class AbsenceSummaryWidget {
    absenceSummary: AbsenceSummaryViewModel;
    absenceFeatureTurnedOffForAllStudents: boolean;
    constructor(private dataContext: IDataContext) {
        "ngInject";
        this.dataContext.getData().then(user => {
            this.absenceSummary = user.absenceSummary;
            this.absenceFeatureTurnedOffForAllStudents = !user.students.some(
                student =>
                    student.features.some(feature => feature.toString() === FEATURES.ATTENDANCE.toString()) &&
                    // FutureStudentNoAbsence - Currently, Absence cannot be recorded for Future Student
                    student.enrolments.some(enrolment => enrolment?.enrolmentStatus === EnrolmentStatus.Active)
            );
        });
    }
}

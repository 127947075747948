import { app } from "../../../app.module";
import templateUrl from "./connect-share-section.html";
import "./connect-share-section.less";

app.component("connectShareSectionComponent", {
    templateUrl: templateUrl,
    controller: connectShareSection,
    controllerAs: "vm"
});

function connectShareSection() {
    var vm = this;

    vm.loadImage = function(imagePath) {
        return require('../../../../content/img/' + imagePath);
    };
}
import angular, { ILogService, IQService } from "angular";
import { app } from "../app.module";
import { STATES, FEATURES } from "../configuration";
import { StudentContext, IContextLoader } from "../services/contextLoader";
import { User } from "../models/user";
import { Student } from "../models/student";
import { Enrolment } from "../models/enrolment";
import { IDataContext } from "../services/dataContext";
import { AuthorisationService } from "../services/authorisationService";
import { DelegateViewerViewModel, MedicalConditionType } from "../models/api";

const controllerId = "studentDetails";
import templateUrl from "./studentDetails.html";
import { IDeviceService } from "@/app/services/deviceServiceProvider";
import { IApiService } from "../services/apiServiceProvider";

app.directive(`${controllerId}Component`, () => ({
    templateUrl,
    scope: true,
    controller: StudentDetailsComponent,
    controllerAs: "vm",
    restrict: "E",
    replace: false
}));

class StudentDetailsComponent implements StudentContext {
    user: User;
    student: Student;
    enrolment: Enrolment;
    delegatedViewers: DelegateViewerViewModel[];
    editStudentDetails: boolean;
    showSuccessHeading: boolean;
    originalStudent: Student;
    postalAddressIsSameAsResidential: boolean;
    medicalConditionTypes: any;
    STATES: string[];
    busy: boolean;
    error: boolean;
    isMobileApp: boolean;

    constructor(
        $routeParams: angular.route.IRouteParamsService,
        contextLoader: IContextLoader,
        deviceService: IDeviceService,
        apiService: IApiService,
        private $location: ng.ILocationService,
        private $timeout: ng.ITimeoutService,
        private dataContext: IDataContext,
        private $q: IQService,
        private $log: ILogService,
        authorisationService: AuthorisationService
    ) {
        "ngInject";
        this.busy = false;
        this.error = false;

        this.STATES = STATES;
        this.student = null;
        this.editStudentDetails = false;
        this.postalAddressIsSameAsResidential = false;
        this.showSuccessHeading = false;
        this.delegatedViewers = null;
        this.isMobileApp = deviceService.isMobileApp;

        contextLoader
            .load(this, $routeParams, controllerId)
            .then(() => {
                this.originalStudent = this.student;

                if (!authorisationService.hasStudentFeatureAccess(this, FEATURES.STUDENT_DETAILS)) return;

                this.student = angular.copy(this.originalStudent);
                this.setIfPostalAddressIsSameAsResidential();

                this.delegatedViewers = this.student.delegateViewers;
            })
            .then(() => {
                return apiService.getStudentMedicalConditionTypes<MedicalConditionType[]>().then(response => {
                    this.medicalConditionTypes = response.data;
                });
            });
    }

    clearPostalAddress(shouldClear) {
        if (shouldClear) {
            this.student.postalAddress.line1 = "";
            this.student.postalAddress.line2 = "";
            this.student.postalAddress.suburb = "";
            this.student.postalAddress.state = "";
            this.student.postalAddress.postcode = "";
        }
    }

    edit() {
        this.editStudentDetails = true;
        this.showSuccessHeading = false;

        this.$timeout(() => {
            if (this.student.residesWithParent) document.getElementById("residentialLine1").focus();
        }, 100);
    }

    resetScreen() {
        this.student = angular.copy(this.originalStudent);
        this.editStudentDetails = false;
        this.setIfPostalAddressIsSameAsResidential();
    }

    cancel() {
        this.resetScreen();
    }

    setIfPostalAddressIsSameAsResidential() {
        if (this.student.residesWithParent === false) return;
        this.postalAddressIsSameAsResidential = this.student.postalAddress.line1 === "";
    }

    save() {
        if (this.busy) return;

        const deferred = this.$q.defer();

        this.busy = true;
        this.error = false;

        this.dataContext
            .saveStudentDetails(this.student)
            .then(() => {
                this.populateUpdatedDetails(this.originalStudent);
                this.resetScreen();

                this.showSuccessHeading = true;
            })
            .catch(response => {
                this.error = true;
                this.$log.error("Error changing the student details", response);
            })
            .finally(() => {
                this.busy = false;
                deferred.resolve();
            });

        return deferred.promise;
    }

    populateUpdatedDetails(student: Student) {
        student.updatedDetails = {
            timestamp: new Date().toString()
        };

        // clear student addresses for privacy reasons
        student.residentialAddress = { line1: "", line2: "", suburb: "", postcode: "", state: "" };
        student.postalAddress = { line1: "", line2: "", suburb: "", postcode: "", state: "" };
    }

    manageMedicalConditions() {
        this.$location.path(`/studentMedicalConditions/${this.student.oneSchoolId}/${this.enrolment.schoolCode}`);
    }
}

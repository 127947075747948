import { ILocationService, ILogService, IQService, route } from "angular";
import { app } from "../app.module";
import { ICheckerService, check } from "../tools/outageChecker";
import { IDeviceService } from "../services/deviceServiceProvider";
import { IApiService } from "../services/apiServiceProvider";
import templateUrl from "./alert.html";
import { ILocalStorageService } from "angular-local-storage";

app.factory("systemAlertChecker", systemAlertCheckerFactory);

function systemAlertCheckerFactory($q: IQService, $location: ILocationService, $log: ILogService, $routeParams: route.IRouteParamsService, deviceService: IDeviceService, apiService: IApiService, localStorageService: ILocalStorageService) {
    "ngInject";
    return check($location, $log, $q, $routeParams, apiService, deviceService, localStorageService);
}

app.directive("systemAlertComponent", () => ({
    templateUrl,
    controller: systemAlert,
    controllerAs: "vm",
    restrict: "E",
    replace: true,
    scope: true
}));

function systemAlert($routeParams: angular.route.IRouteParamsService, systemAlertChecker: ICheckerService, $location: ILocationService, localStorageService: ILocalStorageService) {
    const id = $routeParams.id;

    this.studentId = $routeParams.studentId;

    systemAlertChecker.getOutage(id).then(data => {
        this.data = data;
    });

    this.next = () => {
        localStorageService.set(id, true);
        $location.url($routeParams.next || "/");
    };
}

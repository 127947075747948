import { app } from "../../../app.module";
import { IDataContext } from "../../../services/dataContext";
import { FEATURES } from "../../../configuration";
import { ConsentService } from "../../../services/consentService";
import { ConsentSummaryViewModel } from "@/app/models/api";

require("../parentCentricSummary.less");
require("./consentSummaryWidget.less");
import templateUrl from "./consentSummaryWidget.html";
app.directive("consentSummaryWidget", () => ({
    templateUrl,
    controller: ConsentSummaryWidget,
    controllerAs: "vm",
    scope: true,
    restrict: "A"
}));

class ConsentSummaryWidget {
    consentSummary: ConsentSummaryViewModel;
    consentFeatureTurnedOffForAllStudents: boolean;
    consentLoadedSuccessfully: boolean;
    consentLoading: boolean;
    consentRequestsEnabled: boolean;

    constructor(private dataContext: IDataContext, consentService: ConsentService) {
        "ngInject";
        this.dataContext.getData().then(user => {
            this.consentFeatureTurnedOffForAllStudents = !user.students.some(student => student.isFeatureAvailable(FEATURES.CONSENT_REQUESTS));
            this.consentLoading = true;
            this.consentRequestsEnabled = user.consentRequestsEnabled;

            consentService
                .getConsentRequestSummary(user)
                .then(summary => {
                    this.consentSummary = summary;
                    this.consentLoadedSuccessfully = summary.loadedSuccessfully;
                })
                .catch(e => {
                    this.consentLoadedSuccessfully = false;
                })
                .finally(() => {
                    this.consentLoading = false;
                });
        });
    }
}

import angular from "angular";
import { FinanceRecord } from "./financeRecord";
import { isWeekend, getDayOfTheWeekIndex } from "../services/dayHelper";
import { FEATURES, EnrolmentFeature } from "../configuration";
import {
    AbsenceDayTotalViewModel,
    AbsenceRecordViewModel,
    AcademicResultViewModel,
    BehaviourSummaryViewModel,
    EventViewModel,
    FileDownloadViewModel,
    PayableFinanceRecordViewModel,
    StudentEnrolmentViewModel,
    SuccessfulPaymentViewModel,
    TimetableSummaryViewModel
} from "./api";
import { EnrolmentStatus, getEnrolmentStatusDescriptor } from "./enums";

type EnrolmentCommon = Omit<StudentEnrolmentViewModel, "financeRecordsPaid" | "financeRecordsDue">;

export class Enrolment implements EnrolmentCommon {
    features: string[];
    isUserPPAOForStudent: boolean;
    school: string;
    schoolLogoUri: string;
    schoolCode: string;
    schoolEmail: string;
    schoolUrl: string;
    enrolled: string;
    rollClass: string;
    year: string;
    daysAbsentThisCalendarYear: number;
    attendancePercentageThisCalendarYear: number;
    timetable: TimetableSummaryViewModel;
    assessmentPlanners: FileDownloadViewModel[];
    upcomingEvents: EventViewModel[];
    examTimetables: FileDownloadViewModel[];
    behaviours: BehaviourSummaryViewModel[];
    absenceRecords: AbsenceRecordViewModel[];
    absenceTotals: AbsenceDayTotalViewModel[];
    academicResults: AcademicResultViewModel[];
    absenceRecordsWithoutReason: number;
    enrolmentStatus: EnrolmentStatus;
    enrolmentStatusDescription: string;
    isActiveEnrolment: boolean;

    financeRecordsDue: FinanceRecord[];
    financeRecordsPaid: FinanceRecord[];
    anyFinanceRecords: boolean;
    contactSchoolUrl: string;

    constructor(data: StudentEnrolmentViewModel) {
        angular.extend(this, data);

        this.financeRecordsDue = data.financeRecordsDue.map(f => new FinanceRecord(f));

        this.financeRecordsPaid = data.financeRecordsPaid.map(f => new FinanceRecord(f));

        this.anyFinanceRecords = this.financeRecordsDue.length + this.financeRecordsPaid.length > 0;

        this.enrolmentStatusDescription = getEnrolmentStatusDescriptor(data.enrolmentStatus).name;
        this.isActiveEnrolment = data.enrolmentStatus === EnrolmentStatus.Active;
    }

    getTodaysTimetableOrMondaysIfWeekend() {
        if (!this.timetable) return;

        var dayNumberToShow;

        if (isWeekend()) {
            dayNumberToShow = 1;
        } else {
            dayNumberToShow = getDayOfTheWeekIndex();
        }

        return this.timetable.days.find(function (day) {
            return day.dayOfWeekOrder === dayNumberToShow;
        });
    }

    getAbsenceRecordForId(absenceId) {
        return this.absenceRecords.find(absence => absence.studentAbsenceId === parseInt(absenceId, 10));
    }

    getNumberOfOutstandingInvoices() {
        return this.getInvoicesWithAmountsOwing().length;
    }

    getOutstandingInvoicesTotal() {
        return this.getInvoicesWithAmountsOwing().reduce((acc, r) => acc + r.amountOwing, 0);
    }

    private getInvoicesWithAmountsOwing() {
        return this.financeRecordsDue.filter(r => r.amountOwing > 0);
    }

    getFinanceRecordsSelectedForPayment(): PayableFinanceRecordViewModel[] {
        return this.financeRecordsDue.filter(financeRecord => {
            return financeRecord.isSelectedForPayment;
        });
    }

    getFinanceRecordsWithPaymentAmountGreaterThanAmountOwing() {
        return this.getFinanceRecordsSelectedForPayment().filter(r => r.paymentAmount > r.amountOwing);
    }

    getFinanceRecordsSelectedForPaymentTotal() {
        return this.getFinanceRecordsSelectedForPayment().reduce(function (accumulator, financeRecord) {
            return accumulator + financeRecord.paymentAmount;
        }, 0);
    }

    recordCurrentPayments(paymentDate, successfulPayments: SuccessfulPaymentViewModel[]) {
        var self = this;
        successfulPayments
            .map(function (successfulPayment) {
                return {
                    paymentAmount: successfulPayment.amount,
                    paymentDate: paymentDate,
                    invoiceNumber: successfulPayment.invoiceNumber,
                    paymentReceipt: successfulPayment.receiptNumber
                };
            })
            .forEach(function (successfulPayment) {
                var recordToAddPaymentTo = self.getFinancialRecordDueForInvoiceNumber(successfulPayment.invoiceNumber);
                recordToAddPaymentTo.pendingPayments.push(successfulPayment);

                // set payment amount to 0 if overpaid
                recordToAddPaymentTo.paymentAmount = Math.max(recordToAddPaymentTo.paymentAmount - successfulPayment.paymentAmount, 0);
                recordToAddPaymentTo.amountOwing = recordToAddPaymentTo.paymentAmount;
            });
    }

    getFinancialRecordDueForInvoiceNumber(invoiceNumber) {
        var resultList = this.financeRecordsDue.filter(function (financeRecord) {
            return financeRecord.invoiceNumber === invoiceNumber;
        });
        return resultList[0];
    }

    attendanceAvailable() {
        return this.isFeatureAvailable(FEATURES.ATTENDANCE);
    }

    behaviourAvailable() {
        return this.isFeatureAvailable(FEATURES.BEHAVIOUR);
    }

    financeAvailable() {
        return this.isFeatureAvailable(FEATURES.FINANCE);
    }

    upcomingEventsAvailable() {
        return this.isFeatureAvailable(FEATURES.UPCOMING_EVENTS);
    }

    examTimetableAvailable() {
        return this.isFeatureAvailable(FEATURES.EXAM_TIMETABLE);
    }

    assessmentPlannerAvailable() {
        return this.isFeatureAvailable(FEATURES.ASSESSMENT_PLANNER);
    }

    downloadsAvailable() {
        return this.examTimetableAvailable() || this.assessmentPlannerAvailable();
    }

    timetableAvailable() {
        return this.isFeatureAvailable(FEATURES.TIMETABLE);
    }

    reportAvailable() {
        return this.isFeatureAvailable(FEATURES.ACADEMIC_REPORTS);
    }

    isFeatureAvailable(featureName: EnrolmentFeature) {
        return this.features.some(feature => feature === featureName.toString());
    }

    timetableOrReportOrDownloadsVisible() {
        return this.timetableAvailable() || this.reportAvailable() || this.downloadsAvailable();
    }
}

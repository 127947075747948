import { ILogService, IPromise, IQService } from "angular";
import { app as services } from "../app.module";
import { CSRF_HEADER_NAME } from "../configuration";
import { IApiService } from "./apiServiceProvider";
import { ICookieService } from "./cookieService";

services.factory("csrfTokenService", csrfTokenService);
services.factory("csrfTokenProvider", csrfTokenProvider);
export interface ICsrfTokenService {
    loadCsrfToken(): IPromise<any>;
    ensureCsrfToken(): IPromise<any>;
}

export function csrfTokenService($q: IQService, csrfTokenProvider, cookieService: ICookieService, $log: ILogService, apiService: IApiService): ICsrfTokenService {
    "ngInject";
    return {
        loadCsrfToken: loadCsrfToken,
        ensureCsrfToken: ensureCsrfToken
    };

    function loadCsrfToken() {
        const defer = $q.defer();
        apiService.getCrsfToken().then(
            response => {
                if (response.status == 200) {
                    $log.debug(`loadCrsfToken result - ${response.data}`);
                    let csrfToken = response.data;
                    defer.resolve(csrfToken);
                } else {
                    $log.error("loadCrsfToken Error Status", response.status);
                    defer.resolve("");
                }
            },
            err => {
                $log.error("loadCrsfToken Error", err);
                defer.reject(err);
            }
        );
        return defer.promise;
    }

    function ensureCsrfToken() {
        var defer = $q.defer();

        $log.debug("ensureCrsfToken - Delete NCSRF");
        csrfTokenProvider.setCsrfToken("");
        cookieService.deleteCookie(CSRF_HEADER_NAME).finally(() => {
            $log.debug("ensureCrsfToken - Loading");
            loadCsrfToken()
                .then(token => {
                    if (!token) {
                        $log.debug("ensureCsrfToken - Could not load CSRF Token");
                        defer.reject();
                    } else {
                        $log.debug(`ensureCsrfToken - token=${token}, setting it`);
                        csrfTokenProvider.setCsrfToken(token);
                        defer.resolve(token);
                    }
                })
                .catch(err => {
                    $log.error("ensureCrsfToken - Error", err);
                    defer.reject();
                });
        });

        return defer.promise;
    }
}

export function csrfTokenProvider($log: ILogService) {
    "ngInject";
    return {
        getCsrfToken: getCsrfToken,
        setCsrfToken: setCsrfToken
    };

    function getCsrfToken() {
        return sessionStorage.getItem(CSRF_HEADER_NAME);
    }

    function setCsrfToken(tokenToSet) {
        if (tokenToSet) {
            const existingToken = sessionStorage.getItem(CSRF_HEADER_NAME);
            $log.debug(`csrfTokenProvider.setCsrfToken from old value: '${existingToken}' to new value: '${tokenToSet}'`);
            sessionStorage.setItem(CSRF_HEADER_NAME, tokenToSet);
        } else {
            $log.debug(`csrfTokenProvider.setCsrfToken removed`);
            sessionStorage.removeItem(CSRF_HEADER_NAME);
        }
    }
}
